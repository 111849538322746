import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  Breadcrumb,
  Table,
  OverlayTrigger,
  Form,
} from "react-bootstrap";
import { NEW_API_URL } from "../../common/contrant";

import { useLazyQuery } from "@apollo/react-hooks";
import { FACULTIES } from "../preregistrationStudent/apollo/query";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { CustomButton, CustomContainer } from "../../common";
import consts from "../../consts";
import { formatDateTime } from "../../common/super";

function Docnotice() {
  const [docnotices, setDocnotices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAllModal, setShowAllModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all"); // New state to track the selected tab
  const [title, setTitle] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    type: "NOTICE",
    for: "STUDENT",
    faculty: "",
    showDate: "",
    endDate: "",
    image: null,
    file: null,
  });

  const [validated, setValidated] = useState(false);

  const [imagePreview, setImagePreview] = useState("");
  const [selectedRole, setSelectedRole] = useState("ALL");
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // Show 20 items per page

  const [loadFaculty, { data: facultyData }] = useLazyQuery(FACULTIES, {
    fetchPolicy: "network-only",
  });
  // State for checkboxes
  const [checkboxState, setCheckboxState] = useState({
    isCheckedTrue: false,
    isCheckedFalse: false,
  });

  // Checkbox change handlers
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (name === "checkboxtrue") {
      setCheckboxState({ isCheckedTrue: checked, isCheckedFalse: false }); // Uncheck the false checkbox
    } else if (name === "checkboxfalse") {
      setCheckboxState({ isCheckedTrue: false, isCheckedFalse: checked }); // Uncheck the true checkbox
    }
  };

  useEffect(() => {
    setTitle("ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ");
    loadFaculty({
      variables: {
        where: { isDeleted: false },
      },
    });
  }, []);

  const handleChangeFaculty = (e) => {
    const selectedFaculty = e.target.value.split("|+|");
    setFormData({ ...formData, faculty: selectedFaculty[1] }); // Assuming the second part is the faculty ID
  };

  useEffect(() => {
    axios
      .get(NEW_API_URL + "docnotice")
      .then((response) => {
        setDocnotices(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    setFormData({ ...formData, image: file });

    if (file) {
      const previewUrl = URL.createObjectURL(file); // Generate preview URL
      setImagePreview(previewUrl);
    } else {
      setImagePreview(""); // Clear preview if no file is selected
    }
  };

  // Function to handle file selection (PDF, DOC, etc.)
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setFormData({ ...formData, file });
  };

  // Handle form submission for both adding and updating
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.file ||
      !formData.image ||
      !formData.title ||
      !formData.description ||
      !formData.endDate
      // !Form.faculty
    ) {
      setValidated(true);
      return;
    }

    // Create form data
    const data = new FormData();
    data.append("file", formData.file || selectedDoc.file);
    data.append("image", formData.image || selectedDoc.image);
    data.append("description", formData.description);
    data.append("type", formData.type);
    data.append("for", formData.for);
    data.append("showDate", formData.showDate);
    data.append("endDate", formData.endDate);
    data.append("title", formData.title);
    if (formData.faculty !== "") {
      data.append("faculty", formData.faculty);
    }

    // Check if it's an update or new submission
    if (selectedDoc) {
      // Update existing document
      handleUpdateSubmit(data);
    } else {
      // Add new document
      handleAddSubmit(data);
    }
  };

  const handleAddSubmit = async (data) => {
    // Check if the checkbox is selected
    if (!checkboxState.isCheckedTrue && !checkboxState.isCheckedFalse) {
      setValidated(true);
      return;
    }

    // Attempt to submit the form data
    try {
      const response = await axios.post(NEW_API_URL + "docnotice", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // alert("ເພີ່ມຂໍ້ມູນສຳເລັດແລ້ວ!");
      setDocnotices((prev) => [...prev, response.data]); // Update state with new data

      if (checkboxState.isCheckedTrue) {
        await sendNotification();
      }

      // Reset form fields
      setImagePreview("");
      setCheckboxState({ isCheckedTrue: false, isCheckedFalse: false });
      resetForm();
      setShowAddModal(false); // Close the modal
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

  // Submit form for updating existing document
  const handleUpdateSubmit = (data) => {
    axios
      .put(`${NEW_API_URL}docnotice/${selectedDoc._id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const updatedDocnotices = docnotices.map((doc) =>
          doc._id === selectedDoc._id ? response.data : doc
        );
        setDocnotices(updatedDocnotices); // Update the state
        setShowAddModal(false); // Close the modal
      })
      .catch((error) => {
        console.error("Error updating document:", error);
      });
  };

  // Function to open the update modal with selected document data
  const handleUpdate = (docnotice) => {
    setIsEditMode(true); // Set to edit mode
    setFormData({
      title: docnotice.title,
      description: docnotice.description,
      type: docnotice.type,
      for: docnotice.for,
      faculty: docnotice.faculty,
      showDate: docnotice.showDate,
      endDate: docnotice.endDate,
      image: docnotice.image, // Keep the existing image
      file: docnotice.file, // Keep the existing file
    });
    setSelectedDoc(docnotice);
    setShowAddModal(true); // Reuse the add modal for updates
  };

  // Function to reset form fields after submission
  const resetForm = () => {
    setFormData({
      title: "",
      description: "",
      type: "NOTICE",
      for: "STUDENT",
      faculty: "",
      showDate: "",
      endDate: "",
      image: null,
      file: null,
    });
    setSelectedDoc(null); // Reset selected document
  };

  // Function to delete a document
  const handleDelete = (docnotice) => {
    if (window.confirm(`ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບ "${docnotice.title}"?`)) {
      axios
        .delete(`${NEW_API_URL}docnotice/${docnotice._id}`)
        .then(() => {
          setDocnotices(docnotices.filter((doc) => doc._id !== docnotice._id));
        })
        .catch((error) => {
          console.error("Error deleting document:", error);
        });
    }
  };

  // Function to filter docnotices based on selected tab and role
  const filteredNotices = docnotices.filter((docnotice) => {
    const isTabMatch = selectedTab === "all" || docnotice.type === selectedTab;
    const isRoleMatch =
      selectedRole === "ALL" || docnotice.for === selectedRole; // Assuming 'for' indicates the target audience

    return isTabMatch && isRoleMatch;
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedNotices = filteredNotices.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const sendNotification = async () => {
    await axios.post(NEW_API_URL + "notification/send", {
      title:
        formData.type === "docnotice"
          ? "ແຈ້ງການອອກໃຫມ່"
          : formData.type === "CONFIRM"
          ? "ຂໍ້ຕົກລົງອອກໃຫມ່"
          : formData.type === "RULE"
          ? "ລະບຽບອອກໃຫມ່"
          : "ໜັງສືພິມອອກໃຫມ່",
      body: "",
      path:
        formData.type === "docnotice"
          ? "NOTICE"
          : formData.type === "CONFIRM"
          ? "CONFIRM"
          : formData.type === "RULE"
          ? "RULE"
          : "NEWS",
    });
  };

  const totalPages = Math.ceil(docnotices.length / itemsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleViewAll = (docnotice) => {
    setSelectedDoc(docnotice);
    setShowAllModal(true);
  };

  const _onSearch = (e) => {
    // setTitleKeyword(e?.target?.value);
    // setShowSearchView(false);
    // setKeywordSearch("ຜົນການຄົ້ນຫາ");
  };

  const typeMapping = {
    NOTICE: "ແຈ້ງການ",
    CONFIRM: "ຂໍ້ຕົກລົງ",
    RULE: "ລະບຽບ",
    NEWS: "ໜັງສືພິມ",
  };

  return (
    // <div className=" p-3 ">
    <div>
      <Breadcrumb>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <label
          className="fs-4"
          style={{ color: consts.PRIMARY_COLOR, fontWeight: "bold" }}
        >
          ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ
        </label>
        <div style={{ textAlign: "right" }}>
          <CustomButton
            confirm
            addIcon
            width={200}
            title="ແຈ້ງການ"
            onClick={() => setShowAddModal(true)}
          />
        </div>
        {/* custom search button */}
        {/* <div className="form-group row">
          <label
            className="col-sm-1 col-form-label"
            style={{ color: consts.PRIMARY_COLOR, fontWeight: "bold" }}
          >
            ຄົ້ນຫາ
          </label>
          <div className="col-sm-11">
            <input
              type="text"
              className="form-control boxs"
              id="searchText"
              placeholder="ຫົວຂໍ້"
              onChange={(e) => _onSearch(e)}
            />
          </div>
        </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 30,
          }}
        >
          <label
            className="col-sm-1 col-form-label"
            style={{ color: consts.PRIMARY_COLOR, fontWeight: "bold" }}
          >
            ຫມວດ
          </label>
          <button
            style={{
              borderBottom: selectedTab === "all" ? "3px solid green" : "none",
            }}
            className={`nav-link ${selectedTab === "all" ? "active" : ""}`}
            onClick={() => setSelectedTab("all")}
          >
            ທາງໝົດ
          </button>
          <button
            style={{
              borderBottom:
                selectedTab === "NOTICE" ? "3px solid green" : "none",
            }}
            className={`nav-link ${selectedTab === "NOTICE" ? "active" : ""}`}
            onClick={() => setSelectedTab("NOTICE")}
          >
            ແຈ້ງການ
          </button>
          <button
            style={{
              borderBottom:
                selectedTab === "CONFIRM" ? "3px solid green" : "none",
            }}
            className={`nav-link ${selectedTab === "CONFIRM" ? "active" : ""}`}
            onClick={() => setSelectedTab("CONFIRM")}
          >
            ຂໍ້ຕົກລົງ
          </button>
          <button
            style={{
              borderBottom: selectedTab === "RULE" ? "3px solid green" : "none",
            }}
            className={`nav-link ${selectedTab === "RULE" ? "active" : ""}`}
            onClick={() => setSelectedTab("RULE")}
          >
            ລະບຽບ
          </button>
          <button
            style={{
              borderBottom: selectedTab === "NEWS" ? "3px solid green" : "none",
            }}
            className={`nav-link ${selectedTab === "NEWS" ? "active" : ""}`}
            onClick={() => setSelectedTab("NEWS")}
          >
            ໜັງສືພິມ
          </button>
        </div>

        {/* New selection for Student, Teacher, and All */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 30,
          }}
        >
          <label
            className="col-sm-1 col-form-label"
            style={{ color: consts.PRIMARY_COLOR, fontWeight: "bold" }}
          >
            ຜູ້ທີ່ສາມາດເຫັນ
          </label>
          <div className="btn-group">
            <button
              style={{
                borderBottom:
                  selectedRole === "ALL" ? "3px solid green" : "none",
              }}
              className={`nav-link ${selectedRole === "ALL" ? "active" : ""}`}
              onClick={() => setSelectedRole("ALL")}
            >
              ທາງໝົດ
            </button>
            <button
              style={{
                borderBottom:
                  selectedRole === "STUDENT" ? "3px solid green" : "none",
              }}
              className={`nav-link ${
                selectedRole === "STUDENT" ? "active" : ""
              }`}
              onClick={() => setSelectedRole("STUDENT")}
            >
              ສະເພາະນັກຮຽນ
            </button>
            <button
              style={{
                borderBottom:
                  selectedRole === "TEACHER" ? "3px solid green" : "none",
              }}
              className={`nav-link ${
                selectedRole === "TEACHER" ? "active" : ""
              }`}
              onClick={() => setSelectedRole("TEACHER")}
            >
              ສະເພາະອາຈານ
            </button>
          </div>
        </div>

        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: consts.FONT_COLOR_SECONDARY,
          }}
        >
          ທັງໝົດ {docnotices.length}
        </div>

        {/* Render notices in a grid */}
        <Table>
          <TableHead>
            <TableCell
              style={{
                width: 70,
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              ລຳດັບ
            </TableCell>
            <TableCell
              style={{
                width: 100,
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              ຮູບພາບ
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              ຫົວຂໍ້
            </TableCell>
            <TableCell
              style={{
                width: 150,
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              ປະເພດ
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              ວັນທີເຜີຍແຜ່
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              ວັນທີປິດການເຜີຍແຜ່
            </TableCell>
            <TableCell
              style={{
                width: 200,
                textAlign: "center",
                verticalAlign: "middle",
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              ຈັດການ
            </TableCell>
          </TableHead>

          <TableBody>
            {paginatedNotices.length > 0 ? (
              paginatedNotices.map((docnotice, index) => (
                <TableRow key={docnotice._id} className="border-4 border-white">
                  <TableCell
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {indexOfFirstItem + index + 1}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {docnotice.image && (
                      <img
                        src={docnotice.image}
                        alt={docnotice.title}
                        style={{
                          height: "auto",
                          maxHeight: "100px",
                          objectFit: "cover",
                          width: "100px",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {docnotice.title}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {typeMapping[docnotice.type] || docnotice.type}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {formatDateTime(docnotice.showDate)}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    {formatDateTime(docnotice.endDate)}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: 200,
                      }}
                    >
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">ເບິ່ງລາຍລະອຽດ</Tooltip>
                        }
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#FFFFFF",
                            padding: 3,
                            width: 64,
                            borderRadius: 4,
                          }}
                          onClick={() => handleViewAll(docnotice)}
                        >
                          <i
                            className="fas fa-eye"
                            style={{ color: consts.BORDER_COLOR }}
                          />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">ແກ້ໄຂຂໍ້ມູນ</Tooltip>
                        }
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#FFFFFF",
                            padding: 3,
                            width: 64,
                            borderRadius: 4,
                          }}
                          onClick={() => handleUpdate(docnotice)}
                        >
                          <i
                            className="fa fa-pen"
                            style={{ color: consts.BORDER_COLOR }}
                          />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={<Tooltip id="tooltip-disabled">ລຶບ</Tooltip>}
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#FFFFFF",
                            padding: 3,
                            width: 64,
                            borderRadius: 4,
                          }}
                          onClick={() => handleDelete(docnotice)}
                        >
                          <i
                            className="fas fa-trash-alt"
                            style={{ color: "red" }}
                          ></i>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={8}
                  style={{ textAlign: "center", color: "red" }}
                >
                  ບໍ່ພົບເອກະສານການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="d-flex justify-content-center mt-4">
          {/* Previous Page Button */}
          <Button
            variant="white"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} // Correct event handler
            disabled={currentPage === 1} // Disable if on the first page
            className="mx-1 "
          >
            ກັບຄືນ
          </Button>

          {/* Page Number Buttons */}
          {Array.from({ length: totalPages }, (_, index) => (
            <Button
              key={index}
              variant={currentPage === index + 1 ? "primary" : "secondary"}
              onClick={() => setCurrentPage(index + 1)} // Correct event handler
              className="mx-1"
            >
              {index + 1}
            </Button>
          ))}

          {/* Next Page Button */}
          <Button
            variant="white"
            onClick={() =>
              setCurrentPage((next) => Math.min(next + 1, totalPages))
            } // Correct event handler
            disabled={currentPage === totalPages} // Disable if on the last page
            className="mx-1"
          >
            ໄປຕໍ່
          </Button>
        </div>

        <Modal
          show={showAddModal}
          onHide={() => {
            resetForm();
            setIsEditMode(false); // Reset edit mode
            setShowAddModal(false);
          }}
          dialogClassName="modal-lg"
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>{isEditMode ? "ແກ້ໄຂ" : "ເພີ່ມໃຫມ່"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="container">
            <form>
              <div className="form-group">
                <label>ຫົວຂໍ້:</label>
                <input
                  type="text"
                  name="title"
                  placeholder="ປ້ອນຫົວຂໍ້"
                  className="form-control"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  required
                />
                {validated && !formData.title && (
                  <div className="text-danger">ກະລຸນາປ້ອນຫົວຂໍ້</div>
                )}
              </div>

              <div className="form-group">
                <label>ລາຍລະອຽດ:</label>
                <textarea
                  name="description"
                  className="form-control"
                  placeholder="ລາຍລະອຽດ"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  required
                  spellCheck={true}
                  autoCapitalize="sentences"
                  style={{
                    width: "700px",
                    height: "200px",
                    resize: "none",
                    overflowY: "auto",
                    lineHeight: "1.6",
                    padding: "10px",
                    boxSizing: "border-box",
                  }}
                />
                {validated && !formData.description && (
                  <div className="text-danger">ກະລຸນາປ້ອນລາຍລະອຽດ</div>
                )}
              </div>

              <>
                <div className="form-group">
                  <label>ປະເພດ:</label>
                  <select
                    name="type"
                    className="form-control"
                    value={formData.type}
                    onChange={(e) =>
                      setFormData({ ...formData, type: e.target.value })
                    }
                  >
                    <option value="NOTICE">ແຈ້ງການ</option>
                    <option value="CONFIRM">ຂໍ້ຕົກລົງ</option>
                    <option value="RULE">ລະບຽບ</option>
                    <option value="NEWS">ໜັງສືພິມ</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>ສຳລັບ:</label>
                  <select
                    name="for"
                    className="form-control"
                    value={formData.for}
                    onChange={(e) =>
                      setFormData({ ...formData, for: e.target.value })
                    }
                  >
                    <option value="STUDENT">ສະເພາະນັກຮຽນ</option>
                    <option value="TEACHER">ສະເພາະອາຈານ</option>
                    <option value="ALL">ທາງໝົດ</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>ຄະນະ:</label>
                  <select
                    name="faculty"
                    className="form-control"
                    onChange={handleChangeFaculty}
                  >
                    <option value="">ທຸກຄະນະ</option>
                    {facultyData?.faculties?.map((item, index) => (
                      <option key={index} value={`${item.name}|+|${item.id}`}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>ຮູບພາບ:</label>
                  <input
                    type="file"
                    name="image"
                    className="form-control"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleImageChange}
                    required
                  />
                  {validated && !formData.image && (
                    <div className="text-danger">ກະລຸນາເລືອກຮູບພາບ</div>
                  )}
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="Image Preview"
                      style={{ width: "100px", marginTop: "10px" }}
                    />
                  )}
                </div>

                <div className="form-group">
                  <label>ໄຟເອກະສານ:</label>
                  <input
                    type="file"
                    name="file"
                    className="form-control"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    required
                  />
                  {validated && !formData.file && (
                    <div className="text-danger">ກະລຸນາປ້ອນເອກະສານ</div>
                  )}
                </div>

                <div className="form-group">
                  <label>ວັນທີເຜີຍແຜ່:</label>
                  <input
                    type="date"
                    name="showDate"
                    className="form-control"
                    value={formData.showDate}
                    onChange={(e) =>
                      setFormData({ ...formData, showDate: e.target.value })
                    }
                    required
                  />
                </div>
              </>

              <div className="form-group">
                <label>ວັນທີສຸດການເຜີຍແຜ່:</label>
                <input
                  type="date"
                  name="endDate"
                  className="form-control"
                  value={formData.endDate}
                  onChange={(e) =>
                    setFormData({ ...formData, endDate: e.target.value })
                  }
                  required
                />
                {validated && !formData.endDate && (
                  <div className="text-danger">
                    ກະລຸນາປ້ອນວັນທີສຸດການເຜີຍແຜ່
                  </div>
                )}
              </div>

              {!isEditMode && (
                <>
                  <Box className="p-3 border border-danger m-3">
                    <Box className="py-2">ໃຫ້ແຈ້ງເຕືອນ ຫຼື ບໍ່</Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkboxtrue"
                            checked={checkboxState.isCheckedTrue}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="ແຈ້ງເຕືອນ"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkboxfalse"
                            checked={checkboxState.isCheckedFalse}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="ບໍ່ແຈ້ງເຕືອນ"
                      />
                    </Box>
                  </Box>

                  {/* Show validation message if checkboxes are not selected */}
                  {validated &&
                    !checkboxState.isCheckedTrue &&
                    !checkboxState.isCheckedFalse && (
                      <div className="text-danger">
                        ກະລຸນາເລືອກໃຫ້ແຈ້ງເຕືອນ ຫຼື ບໍ່
                      </div>
                    )}
                </>
              )}

              <Button
                variant="secondary"
                onClick={() => setShowAddModal(false)}
                className="me-2" // Bootstrap class to add margin between buttons
              >
                ປິດ {/* Lao for 'Close' */}
              </Button>
              <Button variant="primary" onClick={handleFormSubmit}>
                {isEditMode ? "ບັນທຶກການແກ້ໄຂ" : "ບັນທຶກ"}{" "}
                {/* Lao for 'Save' or 'Save Changes' */}
              </Button>
            </form>
          </Modal.Body>
        </Modal>

        {/* Modal to display full details */}
        <Modal
          show={showAllModal}
          onHide={() => setShowAllModal(false)}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedDoc?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="from-control">
            {selectedDoc && (
              <>
                {selectedDoc.image && (
                  <img
                    src={selectedDoc.image}
                    alt={selectedDoc.title}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      marginBottom: "15px",
                    }}
                  />
                )}
                <p>
                  <strong>ຫົວຂໍ້:</strong> {selectedDoc.title}
                </p>

                <FormGroup>
                  <p>
                    <strong>ລາຍລະອຽດ:</strong>
                  </p>
                  <textarea
                    name="description"
                    className="form-control"
                    value={selectedDoc.description}
                    readOnly
                    style={{
                      width: "700px",
                      height: "200px",
                      resize: "none",
                      overflowY: "auto",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      lineHeight: "1.6",
                      padding: "10px",
                      fontSize: "1rem",
                    }}
                  />
                </FormGroup>

                <p>
                  <strong>ປະເພດ:</strong>{" "}
                  {typeMapping[selectedDoc.type] || selectedDoc.type}
                </p>
                <p>
                  <strong>ສະເພາະ:</strong> {selectedDoc.for}
                </p>
                {/* <p>
                  <strong>ຄະນະ:</strong> {selectedDoc.faculty}
                </p> */}
                <p>
                  <strong>ວັນທີເຜີຍແຜ່:</strong>{" "}
                  {new Date(selectedDoc.showDate).toLocaleDateString()}
                </p>
                <p>
                  <strong>ວັນທີປິດການເຜີຍແຜ່:</strong>{" "}
                  {new Date(selectedDoc.endDate).toLocaleDateString()}
                </p>
                {/* {selectedDoc.file && (
                <p>
                  <a
                    href={selectedDoc.file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Document
                  </a>
                </p>
              )} */}
              </>
            )}
          </Modal.Body>
        </Modal>
      </CustomContainer>
    </div>
  );
}

export default Docnotice;

import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  List,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
  Select,
  MenuItem,
  InputLabel,
  Alert,
  FormControl,
  CircularProgress,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { AddCircle, Delete } from "@mui/icons-material";
import { fetchPost } from "../../../common/fetchAPI";

const AddQuestionModal = ({ open, handleClose, onRefresh }) => {
  const lessonId = useSelector((state) => state.lesson);

  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({
    question: "",
    A: "",
    B: "",
    C: "",
    D: "",
    answer: "",
    score: "",
    lesson: lessonId.value,
  });

  const [errors, setErrors] = useState({});
  const [editIndex, setEditIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");

  const handleChange = (e) => {
    setNewQuestion({
      ...newQuestion,
      [e.target.name]: e.target.value,
      lesson: lessonId.value,
    });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!newQuestion.question.trim()) newErrors.question = "ກະລຸນາປ້ອນຄຳຖາມ";
    if (!newQuestion.A.trim()) newErrors.A = "ກະລຸນາປ້ອນຕົວເລືອກ A";
    if (!newQuestion.B.trim()) newErrors.B = "ກະລຸນາປ້ອນຕົວເລືອກ B";
    if (!newQuestion.C.trim()) newErrors.C = "ກະລຸນາປ້ອນຕົວເລືອກ C";
    if (!newQuestion.D.trim()) newErrors.D = "ກະລຸນາປ້ອນຕົວເລືອກ D";
    if (!newQuestion.answer.trim()) newErrors.answer = "ກະລຸນາເລືອກຄຳຕອບທີ່ຖືກ";
    if (!newQuestion.score.trim()) newErrors.score = "ກະລຸນາປ້ອນຄະແນນ";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddNewItem = () => {
    if (validateFields()) {
      setQuestions([...questions, newQuestion]);
      setNewQuestion({
        question: "",
        A: "",
        B: "",
        C: "",
        D: "",
        answer: "",
        score: "",
        lesson: lessonId.value,
      });
      setErrors({});
    }
  };

  const handleEditSave = () => {
    if (validateFields()) {
      const updatedQuestions = questions.map((item, index) =>
        index === editIndex ? newQuestion : item
      );
      setQuestions(updatedQuestions);
      setEditIndex(null);
      setNewQuestion({
        question: "",
        A: "",
        B: "",
        C: "",
        D: "",
        answer: "",
        score: "",
        lesson: lessonId.value,
      });
      setErrors({});
    }
  };

  const handleDelete = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setNewQuestion(questions[index]);
  };

  const handleSave = async () => {
    console.log("Saving Questions:", questions);
    setIsLoading(true);
    try {
      await fetchPost("/tquestion", questions);
      setIsError("");
      onRefresh();
    } catch (err) {
      setIsError("ມີບັນຫາໃນການເພີ່ມຄຳຖາມ");
    }
    setIsLoading(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: 600 }}>ສ້າງຫຼືແກ້ໄຂຄຳຖາມ</DialogTitle>
      <DialogContent dividers>
        <List>
          {questions.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => handleEditClick(index)}
              sx={{
                "&:hover": { backgroundColor: "#f9f9f9" },
                borderBottom: "1px solid #eee",
                mb: 1,
                borderRadius: "8px",
              }}
            >
              <ListItemText
                primary={item.question}
                secondary={`ຄຳຕອບ: ${item.answer}, ຄະແນນ: ${item.score}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(index)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>

        <TextField
          margin="dense"
          label="ຄຳຖາມ"
          name="question"
          size="medium"
          value={newQuestion.question}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.question}
          helperText={errors.question}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="dense"
          label="ຕົວເລືອກ A"
          name="A"
          size="medium"
          value={newQuestion.A}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.A}
          helperText={errors.A}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="dense"
          label="ຕົວເລືອກ B"
          name="B"
          size="medium"
          value={newQuestion.B}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.B}
          helperText={errors.B}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="dense"
          label="ຕົວເລືອກ C"
          size="medium"
          name="C"
          value={newQuestion.C}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.C}
          helperText={errors.C}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="dense"
          label="ຕົວເລືອກ D"
          size="medium"
          name="D"
          value={newQuestion.D}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.D}
          helperText={errors.D}
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth margin="dense" required error={!!errors.answer}>
          <InputLabel>ຄຳຕອບທີ່ຖືກ</InputLabel>
          <Select
            size="medium"
            name="answer"
            label="ຄຳຕອບທີ່ຖືກ"
            value={newQuestion.answer}
            onChange={handleChange}
          >
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="C">C</MenuItem>
            <MenuItem value="D">D</MenuItem>
          </Select>
          {!!errors.answer && (
            <p style={{ color: "red", fontSize: "0.75rem" }}>{errors.answer}</p>
          )}
        </FormControl>

        <TextField
          margin="dense"
          label="ຄະແນນ"
          size="medium"
          name="score"
          value={newQuestion.score}
          onChange={handleChange}
          type="number"
          fullWidth
          required
          error={!!errors.score}
          helperText={errors.score}
          sx={{ mt: 2 }}
        />

        <Button
          startIcon={<AddCircle />}
          onClick={editIndex === null ? handleAddNewItem : handleEditSave}
          color="primary"
          fullWidth
          variant="outlined"
          sx={{ mt: 3 }}
        >
          {editIndex === null ? "ເພີ່ມຄຳຖາມໃໝ່" : "ບັນທຶກການແກ້ໄຂ"}
        </Button>

        {isError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {isError}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={isLoading || questions.length === 0}
          sx={{ mr: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : "ບັນທຶກ"}
        </Button>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          ຍົກເລີກ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddQuestionModal;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { fetchGet } from "../../common/fetchAPI";
import { USER_KEY } from "../../consts";

const EvaluationDisplay = () => {
  const [data, setData] = useState({
    evaluateList: [],
    evaluatedStatus: { evaluated: 0, notEvaluated: 0 },
    averages: {},
  });

  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState("");
  const [teacherList, setTeacherList] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");

  const fetchDepartmentList = async () => {
    try {
      const user = localStorage.getItem(USER_KEY);
      const userObject = JSON.parse(user);
      const response = await fetchGet(
        "/department/" + userObject?.data?.faculty?._id
      );
      setDepartmentList(response?.data || []);
    } catch (err) {
      console.error("Error fetching department list:", err);
    }
  };

  const fetchTeacher = async () => {
    try {
      if (department) {
        const response = await fetchGet("userteacher/" + department);
        setTeacherList(response || []);
      }
    } catch (err) {
      console.error("Error fetching teacher list:", err);
    }
  };

  useEffect(() => {
    setTeacherList([]);
    fetchTeacher();
  }, [department]);

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  const fetchEvaluateList = async () => {
    try {
      const user = localStorage.getItem(USER_KEY);
      const userObject = user ? JSON.parse(user) : null;

      const response = await fetchGet(
        "/evaluation/report?faculty=" +
          userObject?.data?.faculty?._id +
          "&teacher=" +
          selectedTeacher
      );

      if (response) {
        setData({
          evaluateList: response.evaluateList || [],
          evaluatedStatus: response.evaluatedStatus || {
            evaluated: 0,
            notEvaluated: 0,
          },
          averages: response.averages || {},
        });
      } else {
        setData({
          evaluateList: [],
          evaluatedStatus: { evaluated: 0, notEvaluated: 0 },
          averages: {},
        });
      }
    } catch (error) {
      console.error("Error fetching evaluation data:", error);
      setData({
        evaluateList: [],
        evaluatedStatus: { evaluated: 0, notEvaluated: 0 },
        averages: {},
      });
    }
  };

  useEffect(() => {
    fetchEvaluateList();
  }, [selectedTeacher]);

  // Transform averages data for chart use
  const averageData = Object.entries(data.averages).map(([key, value]) => ({
    name: key,
    value: parseFloat(value),
  }));

  // Define colors for the charts
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF605A",
    "#A3A1FB",
    "#58D68D",
  ];

  return (
    <Box padding={4} bgcolor="#f5f5f5" minHeight="100vh">
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          gap: "30px",
          backgroundColor: "white",
          marginBottom: "10px"
        }}
      >
        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small" id="department-select-label">
            ເລືອກພາກວິຊາ
          </InputLabel>
          <Select
            label="ເລືອກພາກວິຊາ"
            size="small"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          >
            {departmentList &&
              departmentList.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small" id="teacher-select-label">
            ອາຈານ
          </InputLabel>
          <Select
            label="ອາຈານ"
            size="small"
            value={selectedTeacher}
            onChange={(e) => setSelectedTeacher(e.target.value)}
          >
            {teacherList &&
              teacherList.map((teacher) => (
                <MenuItem key={teacher._id} value={teacher._id}>
                  {teacher.firstname + " " + teacher.lastname}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={2}>
        {/* Evaluated Status as Infographic (Pie Chart) */}

        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                ສະຖານະການປະເມີນ
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    dataKey="value"
                    data={[
                      {
                        name: "ປະເມີນແລ້ວ",
                        value: data.evaluatedStatus.evaluated,
                      },
                      {
                        name: "ຍັງບໍ່ປະເມີນ",
                        value: data.evaluatedStatus.notEvaluated,
                      },
                    ]}
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label
                  >
                    <Cell fill="#0088FE" />
                    <Cell fill="#FF8042" />
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Average Ratings as Infographic (Bar Chart) */}
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                ຄະແນນສະເລ່ຍການປະເມີນ
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={averageData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value">
                    {averageData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Evaluation List Table */}
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                ລາຍການປະເມີນ
              </Typography>
              {data.evaluateList.length === 0 ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="200px"
                >
                  <InfoIcon fontSize="large" color="action" />
                  <Typography variant="h6" color="textSecondary">
                    ບໍ່ມີຂໍ້ມູນການປະເມີນ
                  </Typography>
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "white",
                            bgcolor: "#1976d2",
                            fontWeight: "bold",
                          }}
                        >
                          ຫົວຂໍ້ຟອມ
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "white",
                            bgcolor: "#1976d2",
                            fontWeight: "bold",
                          }}
                        >
                          ຄະນະ
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "white",
                            bgcolor: "#1976d2",
                            fontWeight: "bold",
                          }}
                        >
                          ອາຈານ
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "white",
                            bgcolor: "#1976d2",
                            fontWeight: "bold",
                          }}
                        >
                          ວິຊາຮຽນ
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.evaluateList.map((item) => (
                        <TableRow key={item._id}>
                          <TableCell align="center">
                            {item.description}
                          </TableCell>
                          <TableCell align="center">
                            {item.faculty.name}
                          </TableCell>
                          <TableCell align="center">{`${item.teacher.firstname} ${item.teacher.lastname}`}</TableCell>
                          <TableCell align="center">
                            {item.course?.course?.subject?.title}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EvaluationDisplay;

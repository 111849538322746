import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Alert,
  Menu,
  Modal,
  MenuItem,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import { fetchDelete, fetchGet } from "../../../common/fetchAPI";
import AddQuestionModal from "./AddQuestionModal";
import UpdateQuestionModal from "./UpdateQuestionModal";

const QuestionItem = ({ question, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        borderRadius: 1,
        padding: 2,
        position: "relative",
      }}
    >
      <IconButton
        aria-label="more"
        onClick={handleClick}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 80,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))",
            mt: 1.5,
            "& .MuiMenuItem-root": {
              padding: 1,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            onEdit(question);
            handleClose();
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          ເເກ້ໄຂ
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete(question._id);
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          ລົບ
        </MenuItem>
      </Menu>
      <Typography variant="h6">{question.question}</Typography>
      <Box sx={{ marginTop: 1 }}>
        <Typography variant="body2">
          <strong>A:</strong> {question.A}
        </Typography>
        <Typography variant="body2">
          <strong>B:</strong> {question.B}
        </Typography>
        <Typography variant="body2">
          <strong>C:</strong> {question.C}
        </Typography>
        <Typography variant="body2">
          <strong>D:</strong> {question.D}
        </Typography>
        <Typography marginTop={1} variant="body2">
          <strong>ຄຳຕອບ:</strong>
          <span
            style={{
              marginLeft: "5px",
              fontSize: "18px",
              color: "green",
              fontWeight: "bold",
            }}
          >
            {question.answer}
          </span>
          <strong> ຄະເເນນ:</strong>
          <span
            style={{
              marginLeft: "5px",
              fontSize: "18px",
              color: "green",
              fontWeight: "bold",
            }}
          >
            {question.score}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

const QuestionList = () => {
  const [showAddQuestion, setShowQuestion] = useState(false);

  const [questionList, setQuestionList] = useState([]);
  const lessonId = useSelector((state) => state.lesson);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");

  const [showEdit, setShowEdit] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const fetchQuestionList = async () => {
    try {
      setIsLoading(true);
      const _questions = await fetchGet("/tquestion/" + lessonId.value);
      setQuestionList(_questions);
      setIsLoading(false);
      setIsError("");
    } catch (err) {
      setIsLoading(false);
      setIsError("ມີບັນຫາໃນການສະເເດງລາຍການຄຳຖາມ");
    }
  };

  useEffect(() => {
    fetchQuestionList();
  }, [lessonId.value]);

  const handleEdit = (question) => {
    // Handle edit functionality
    setSelectedItem(question);
    setShowEdit(true);
  };

  const [selectedId, setSelectedId] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const handleDelete = (id) => {
    // Handle delete functionality
    setSelectedId(id);
    setOpenDelete(true);
  };

  const onSubmitDelete = async () => {
    try {
      setIsLoading(true);
      await fetchDelete("/tquestion/" + selectedId);
      setOpenDelete(false);
      setIsError("");
      setIsLoading(false);
      await fetchQuestionList();
    } catch (err) {
      setIsError("ເກີດບັນຫາໃນການລົບ!");
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <UpdateQuestionModal
          onRefresh={() => fetchQuestionList()}
          handleClose={() => setShowEdit(false)}
          open={showEdit}
          question={selectedItem}
        />
        <AddQuestionModal
          onRefresh={() => fetchQuestionList()}
          handleClose={() => setShowQuestion(false)}
          open={showAddQuestion}
        />
        <Typography variant="h5">ລາຍການຄຳຖາມ</Typography>
      </Box>

      <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            paddingBottom: 2,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ທ່ານຕ້ອງການລົບຄຳຖາມນີ້ເເທ້ບໍ່?
          </Typography>
          {isError && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Alert severity="error" sx={{ marginTop: 2 }}>
                {isError}
              </Alert>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button onClick={onSubmitDelete} variant="contained" color="error">
              {isLoading ? <CircularProgress /> : "ລົບ"}
            </Button>
            <Button
              onClick={() => setOpenDelete(false)}
              variant="outlined"
              color="info"
              sx={{ ml: 1 }}
            >
              ຍົກເລີກ
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginRight: 2,
        }}
      >
        <Button
          onClick={() => setShowQuestion(true)}
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
        >
          ສ້າງຄຳຖານໃຫມ່
        </Button>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            marginTop: 2,
            gap: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: 2,
            gap: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {questionList.map((question) => (
            <QuestionItem
              key={question._id}
              question={question}
              onEdit={(question) => handleEdit(question)}
              onDelete={handleDelete}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default QuestionList;

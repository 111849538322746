import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Chip,
  Box,
  Fade,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Add as AddIcon, Cancel as CancelIcon } from "@mui/icons-material"; // Importing icons
import FilterModal from "./filterModal"; // Import your existing FilterModal component
import RiskAnalysisReport from "./analyzeRepoprt";
import { fetchGet } from "../../../common/fetchAPI";

const colorPalette = [
  "#1abc9c", // Turquoise
  "#2ecc71", // Emerald
  "#3498db", // Peter River
  "#9b59b6", // Amethyst
  "#e74c3c", // Alizarin
  "#f1c40f", // Sunflower
  "#e67e22", // Carrot
  "#34495e", // Wet Asphalt
  "#16a085", // Green Sea
  "#c0392b", // Pomegranate
];

const ElearningReport = () => {
  const [open, setOpen] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState([]);

  const [reports, setReports] = useState([]); // Store reports as they are loaded
  const [loadingClassId, setLoadingClassId] = useState(null); // Track which class is currently loading

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const fetchReports = async () => {
    for (const _class of selectedClasses) {
      const classId = _class?.classItem?._id;

      // Set the currently loading class
      setLoadingClassId(classId);

      try {
        const response = await fetchGet(
          `elearning/risk/report?classId=${classId}`
        );
        setReports((prevReports) => [
          ...prevReports,
          { classId, data: response },
        ]);
      } catch (error) {
        console.error(`Error fetching data for classId ${classId}:`, error);
      } finally {
        setLoadingClassId(null); // Reset loading state for this class
      }
    }
  };

  useEffect(() => {
    if (selectedClasses.length > 0) {
      fetchReports();
    }
  }, [selectedClasses]);

  const handleSubmit = (data) => {
    setSelectedClasses((prevClasses) => {
      const newClasses = data.class
        .filter(
          (newClass) =>
            !prevClasses.some(
              (existingClass) => existingClass.classItem._id === newClass._id
            )
        )
        .map((newClass, index) => ({
          classItem: newClass,
          color:
            colorPalette[(prevClasses.length + index) % colorPalette.length], // Assign color based on index
        }));
      return [...prevClasses, ...newClasses];
    });
    setOpen(false);
  };

  const handleDeleteClass = (deletedClass) => {
    setSelectedClasses((prevClasses) =>
      prevClasses.filter(
        (classObj) => classObj.classItem._id !== deletedClass.classItem._id
      )
    );
    setReports((prevReports) =>
      prevReports.filter(
        (report) => report.classId !== deletedClass.classItem._id
      )
    );
  };

  return (
    <div className="p-2 pt-0 mt-0">
      <Box
        sx={{
          padding: 3,
          // backgroundColor: "white",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          startIcon={<AddIcon />} // Add icon to the button
          sx={{ marginBottom: 2 }}
        >
          ເລືອກຫ້ອງຮຽນ
        </Button>
        <div>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">ລານການຫ້ອງທີເລືອກ:</Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {selectedClasses.map((classObj, index) => (
                <Fade in timeout={300} key={index}>
                  <Chip
                    label={classObj.classItem.name || classObj.classItem}
                    onDelete={() => handleDeleteClass(classObj)}
                    deleteIcon={
                      <CancelIcon
                        sx={{ color: "white !important", marginLeft: "8px" }}
                      />
                    } // Space between name and icon
                    sx={{
                      backgroundColor: classObj.color, // Use stored color
                      color: "white",
                      fontSize: "1.1rem",
                      padding: "0.5rem",
                      height: "40px", // Larger chip size
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Modern shadow
                      borderRadius: "8px", // Rounded corners for a modern look
                      marginBottom: 1,
                    }}
                  />
                </Fade>
              ))}
            </Box>
          </Box>
        </div>
      </Box>

      <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="md">
        <FilterModal
          open={open}
          close={handleCloseModal}
          onSubmit={handleSubmit}
        />
      </Dialog>

      <div className="mt-2">
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)" // 2 columns layout
          gap={2} // Adjust the spacing between items as needed
        >
          {selectedClasses.map((classObj, index) => {
            const report = reports.find(
              (rep) => rep.classId === classObj.classItem._id
            );
            const isLoading = loadingClassId === classObj.classItem._id;

            return (
              <Box
                key={index} // Add a unique key for each item in the map
                sx={{
                  backgroundColor: "white",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  minHeight: 600,
                }}
              >
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    <CircularProgress />
                  </Box>
                ) : report ? (
                  <RiskAnalysisReport
                    classObj={classObj}
                    reportData={report.data}
                  />
                ) : null}
              </Box>
            );
          })}
        </Box>
      </div>
    </div>
  );
};

export default ElearningReport;

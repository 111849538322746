import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Box,
  Grid,
} from "@mui/material";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../../common/contrant";
import { FilterList } from "@mui/icons-material"; // Icon added for title enhancement

const FilterModal = ({ open, close, onSubmit }) => {
  const [facultyList, setFacultyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [majorList, setMajorList] = useState([]);
  const [classList, setClassList] = useState([]);

  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedMajor, setSelectedMajor] = useState("");
  const [selectedClass, setSelectedClass] = useState([]);
  const [year, setYear] = useState("");

  useEffect(() => {
    fetchFaculty();
  }, []);

  useEffect(() => {
    if (selectedFaculty) {
      setSelectedDepartment("");
      setSelectedMajor("");
      setSelectedClass([]);
      fetchDepartment();
    }
  }, [selectedFaculty]);

  useEffect(() => {
    if (selectedDepartment) {
      setSelectedMajor("");
      setSelectedClass([]);
      fetchMajor();
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedMajor && year) {
      setSelectedClass([]);
      fetchClass();
    }
  }, [selectedMajor, year]);

  const fetchFaculty = async () => {
    try {
      const response = await axios.get(`${NEW_API_URL}/faculty`, {
        headers: { api_key: API_KEY },
      });
      if (response.data.success) setFacultyList(response.data.data);
    } catch (error) {
      console.error("Error fetching faculty:", error);
    }
  };

  const fetchDepartment = async () => {
    if (selectedFaculty) {
      try {
        const response = await axios.get(
          `${NEW_API_URL}/department/${selectedFaculty}`,
          {
            headers: { api_key: API_KEY },
          }
        );
        if (response.data.success) setDepartmentList(response.data.data);
      } catch (error) {
        console.error("Error fetching department:", error);
      }
    }
  };

  const fetchMajor = async () => {
    if (selectedDepartment) {
      try {
        const response = await axios.get(
          `${NEW_API_URL}/getmajo/${selectedDepartment}`,
          {
            headers: { api_key: API_KEY },
          }
        );
        if (response.data.success) setMajorList(response.data.data);
      } catch (error) {
        console.error("Error fetching major:", error);
      }
    }
  };

  const fetchClass = async () => {
    if (selectedMajor && year) {
      try {
        const response = await axios.get(
          `${NEW_API_URL}/class/${selectedMajor}/${year}`,
          {
            headers: { api_key: API_KEY },
          }
        );
        if (response.data.success) setClassList(response.data.data);
      } catch (error) {
        console.error("Error fetching class:", error);
      }
    }
  };

  const handleSubmit = () => {
    onSubmit({
      faculty: selectedFaculty,
      department: selectedDepartment,
      major: selectedMajor,
      class: selectedClass,
      year: year,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="md"
      sx={{ borderRadius: "10px", boxShadow: 3 }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <FilterList sx={{ mr: 1 }} />
          ເລືອກຕົວກອງການລາຍງານ
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>ຄະນະ</InputLabel>
              <Select
                value={selectedFaculty}
                onChange={(e) => setSelectedFaculty(e.target.value)}
                label="ຄະນະ"
              >
                {facultyList.map((faculty) => (
                  <MenuItem key={faculty._id} value={faculty._id}>
                    {faculty.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              disabled={!selectedFaculty}
            >
              <InputLabel>ພາກວິຊາ</InputLabel>
              <Select
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                label="ພາກວິຊາ"
              >
                {departmentList.map((department) => (
                  <MenuItem key={department._id} value={department._id}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              disabled={!selectedDepartment}
            >
              <InputLabel>ສາຂາ</InputLabel>
              <Select
                value={selectedMajor}
                onChange={(e) => setSelectedMajor(e.target.value)}
                label="ສາຂາ"
              >
                {majorList.map((major) => (
                  <MenuItem key={major._id} value={major._id}>
                    {major.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              disabled={!selectedMajor}
            >
              <InputLabel>ປີການສຶກສາ</InputLabel>
              <Select
                value={year}
                onChange={(e) => setYear(e.target.value)}
                label="ປີການສຶກສາ"
              >
                {[1, 2, 3, 4, 5].map((yr) => (
                  <MenuItem key={yr} value={yr}>
                    ປີ {yr}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={classList}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => setSelectedClass(newValue)}
              value={selectedClass}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="ຫ້ອງຮຽນ"
                  placeholder="ເລືອກຫ້ອງຮຽນ"
                  variant="outlined"
                />
              )}
              disabled={!year}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={close}
          color="secondary"
          variant="outlined"
          sx={{ borderRadius: "10px" }}
        >
          ຍົກເລີກ
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          sx={{ borderRadius: "10px" }}
        >
          ຕົກລົງ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;

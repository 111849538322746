import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Card,
  CardContent,
  Box,
  Avatar,
  Paper,
  Divider,
  Tooltip,
  Stack,
  IconButton,
  Modal,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import EmptyComponent from "../../../../common/EmptyScreen";

const StudentList = ({ students = [] }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const renderStudentList = () => (
    <Box
      sx={{
        maxHeight: openModal ? 800 : 358,
        overflowY: "auto",
        backgroundColor: "white",
        padding: 2,
        boxShadow: "none",
      }}
    >
      {students.map((studentData, index) => (
        <Accordion
          key={index}
          sx={{
            marginBottom: 1,
            borderRadius: 2,
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid
              container
              sx={{
                boxShadow: "none",
              }}
              alignItems="center"
            >
              <Grid item xs={12} sm={8} display="flex" alignItems="center">
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {studentData.student.firstNameL}{" "}
                    {studentData.student.lastNameL}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {studentData.student.firstNameE}{" "}
                    {studentData.student.lastNameE}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} textAlign={{ xs: "left", sm: "right" }}>
                <Chip
                  label={`ຄະແນນທັງໝົດ: ${studentData.totalScore}`}
                  sx={{
                    backgroundColor: "#2196f3",
                    color: "white",
                    fontWeight: "bold",
                  }}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "#ffffff" }}>
            <Stack spacing={2}>
              {studentData.sections.map((section, secIndex) => (
                <Box
                  key={secIndex}
                  sx={{
                    padding: 2,
                    borderRadius: 1,
                    backgroundColor: "#fafafa",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 1,
                      color: "#2196f3",
                      fontWeight: "bold",
                    }}
                  >
                    <AssignmentIcon sx={{ marginRight: 1 }} />
                    ພາກທີ {section.index}: {section.sectionTitle}
                  </Typography>
                  <Divider sx={{ marginBottom: 2 }} />
                  <List>
                    {section.questions.map((question, qIndex) => (
                      <ListItem
                        key={qIndex}
                        sx={{
                          alignItems: "flex-start",
                          paddingLeft: 0,
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold", color: "#424242" }}
                            >
                              {question.index}. {question.text}
                            </Typography>
                          }
                          secondary={
                            <Box>
                              {question.type === "multiple-choice" ? (
                                <Typography variant="body2">
                                  ຄໍາຕອບຂອງນັກສຶກສາ: {question.answer}{" "}
                                  {question.answer ===
                                  question.correctOption ? (
                                    <Tooltip title="ຖືກຕ້ອງ">
                                      <CheckCircleIcon
                                        sx={{
                                          color: "#4caf50",
                                          verticalAlign: "middle",
                                          marginLeft: 1,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="ຜິດພາດ">
                                      <CancelIcon
                                        sx={{
                                          color: "#f44336",
                                          verticalAlign: "middle",
                                          marginLeft: 1,
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Typography>
                              ) : (
                                <Typography variant="body2">
                                  ຄໍາຕອບ: {question.answer}
                                </Typography>
                              )}
                              <Typography
                                variant="body2"
                                sx={{ color: "#757575" }}
                              >
                                ຄໍາຕອບທີ່ຖືກ: {question.correctOption}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold", color: "#2196f3" }}
                              >
                                ຄະແນນ: {question.score}/{question.maxScore}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );

  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 5, ml: 3 }}>
          ເວລາທີ່ໃຊ້ vs. ຄະແນນທັງໝົດ
        </Typography>
        <IconButton sx={{ mt: 5 }} onClick={handleOpenModal}>
          <FullscreenIcon />
        </IconButton>
      </Box>
      {students.length > 0 ? (
        renderStudentList()
      ) : (
        <EmptyComponent height={358} />
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper
          sx={{
            width: "80%",
            maxHeight: openModal ? 800 : 365,
            overflowY: "auto",
            backgroundColor: "white",
            padding: 2,
            borderRadius: 2,
          }}
        >
          {students.length > 0 ? (
            renderStudentList()
          ) : (
            <EmptyComponent height={358} />
          )}
        </Paper>
      </Modal>
    </Box>
  );
};

export default StudentList;

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Box,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import axios from "axios";
import { fetchGet } from "../../../common/fetchAPI";
import StudentReportAdditional from "./studentAnswrReportAdditonal";
import EmptyCmomponent from "../../../common/EmptyScreen";

const StudentAnswerQuestionList = ({ classRoom, courseId }) => {
  const [students, setStudents] = useState([]);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [incorrectAnswersCount, setIncorrectAnswersCount] = useState(0);
  const [stackedBarData, setStackedBarData] = useState([]);
  const [data, setData] = useState();

  const fetchAnswersData = async () => {
    try {
      if (classRoom && courseId) {
        const response = await fetchGet(
          `tanswer/classroom/${classRoom}/${courseId}`
        );

        // Check if response is defined and contains the required properties
        if (response && typeof response === "object") {
          const {
            students = [],
            correctAnswersCount = 0,
            incorrectAnswersCount = 0,
            stackedBarData = [],
          } = response;

          // Set the state with the pre-processed data from backend
          setData(response);
          setStudents(students);
          setCorrectAnswersCount(correctAnswersCount);
          setIncorrectAnswersCount(incorrectAnswersCount);
          setStackedBarData(stackedBarData);
        } else {
          console.warn("Unexpected response format: ", response);
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // Fetch data from backend API
  useEffect(() => {
    fetchAnswersData();
  }, [classRoom, courseId]);

  // Pie chart data
  const pieData = [
    { name: "Correct", value: correctAnswersCount },
    { name: "Incorrect", value: incorrectAnswersCount },
  ];

  return (
    <Box
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
      }}
      spacing={4}
    >
      <Grid sx={{ width: "40%", maxWidth: "40%" }}>
        <Box
          sx={{
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
            overflowX: "auto",
            backgroundColor: "white",
            width: "100%",
            paddingLeft: 2,
          }}
        >
          {/* Pie Chart - Correct vs Incorrect */}
          <Typography
            sx={{ fontWeight: "bold", ml: 3, mt: 1, mb: 2 }}
            variant="h6"
          >
            ລາຍການນັກສຶກສາຕອບຄຳຖາມ
          </Typography>

          {students.length > 0 ? (
            students.map((studentData) => (
              <Accordion key={studentData.studentId}>
                <AccordionSummary
                  sx={{
                    backgroundColor: "#f9f9f9", // Light background for a softer look
                    borderRadius: "8px", // Rounded corners for a modern feel
                    padding: "0 16px", // Padding for better spacing
                  }}
                  expandIcon={<ExpandMoreIcon sx={{ color: "#4caf50" }} />} // Green expand icon for a fresh feel
                  aria-controls={`panel${studentData.studentId}-content`}
                  id={`panel${studentData.studentId}-header`}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "500", color: "#333" }}
                  >
                    {studentData.firstNameL} {studentData.lastNameL} -
                    ລວມຄະເເນນທັງຫມົດ:
                    <Box
                      component="span"
                      sx={{
                        fontWeight: "600",
                        color: "#388e3c",
                        backgroundColor: "rgba(76, 175, 80, 0.1)",
                        padding: "4px 8px",
                        borderRadius: "8px",
                        marginLeft: "8px", // Space between text and score
                      }}
                    >
                      {studentData.totalScore}
                    </Box>
                  </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails
                  sx={{
                    backgroundColor: "#ffffff", // White background for contrast
                    borderRadius: "0 0 8px 8px", // Rounded corners only at the bottom
                    padding: "16px",
                  }}
                >
                  <List sx={{ padding: 0 }}>
                    {studentData.answers &&
                      studentData.answers.map((answer, index) => (
                        <div key={index}>
                          <ListItem
                            sx={{
                              padding: "12px 16px",
                              backgroundColor:
                                index % 2 === 0 ? "#fafafa" : "#f5f5f5", // Alternating background colors for clarity
                              borderRadius: "8px",
                              mb: 1, // Space between items
                              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.05)",
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: "500" }}
                                >
                                  ຄຳຖາມ: {answer.question}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#666" }}
                                >
                                  ນັກສຶກສາຕອບ: {answer.answer} | ຄະເເນນຄຳຖາມ:{" "}
                                  <Box
                                    component="span"
                                    sx={{ fontWeight: "500", color: "#ff9800" }}
                                  >
                                    {answer.questionScore}
                                  </Box>
                                </Typography>
                              }
                            />
                            <IconButton edge="end" aria-label="result">
                              {answer.correct ? (
                                <CheckCircleIcon sx={{ color: "#4caf50" }} />
                              ) : (
                                <CancelIcon sx={{ color: "#f44336" }} />
                              )}
                            </IconButton>
                          </ListItem>
                        </div>
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <EmptyCmomponent />
          )}
        </Box>

        <Box
          sx={{
            paddingLeft: 2,
            marginTop: 1,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
            overflowX: "auto",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          {" "}
          {/* Pie Chart - Correct vs Incorrect */}
          <Typography sx={{ fontWeight: "bold" }} marginTop={2} variant="h6">
            Correct vs Incorrect Answers
          </Typography>
          {/* Scrollable container */}
          {pieData.length > 0 ? (
            <ResponsiveContainer height={300}>
              {" "}
              {/* Width is larger than the container to make it scroll */}
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  label
                >
                  <Cell key="correct" fill="#57cc99" />
                  <Cell key="incorrect" fill="#ff8fa3" />
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <EmptyCmomponent />
          )}
        </Box>

        <Box
          sx={{
            paddingLeft: 2,
            marginTop: 1,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
            overflowX: "auto",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          {/* Stacked Bar Chart */}
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            marginTop={2}
            variant="h6"
          >
            Correct vs Incorrect Answers by Student
          </Typography>
          {stackedBarData.length > 0 ? (
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <ResponsiveContainer
                width={stackedBarData.length * 150}
                height={300}
              >
                <BarChart data={stackedBarData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="correct" stackId="a" fill="#57cc99" />
                  <Bar dataKey="incorrect" stackId="a" fill="#ff8fa3" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <EmptyCmomponent />
          )}
        </Box>
      </Grid>

      <Grid sx={{ width: "60%" }}>
        <StudentReportAdditional data={data} />
      </Grid>
    </Box>
  );
};

export default StudentAnswerQuestionList;

import styled from "styled-components";
import Consts from "../consts";
export const CustomContainer = styled.div`
  background-color: #fff;
  padding: ${Consts.CONTAINER_PADDING}px;
  margin: 16px 10px 0 10px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 4px;
  width: 100%; /* Ensures full width */
  display: flex; /* Flex container for content alignment */
  flex-direction: column; /* Stack children vertically */
  overflow-x: auto; /* Enable horizontal scroll for wide content */
`;

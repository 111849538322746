import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Modal,
  Button,
} from "@mui/material";

const ManageLessonAccess = ({
  data,
  open,
  onClose,
  onSubmit,
  existedClassList,
}) => {
  console.log("data => ", data);
  // Initial state for switch values
  const [switchState, setSwitchState] = useState([]);

  useEffect(() => {
    const initialSwitchState = data
      .filter((item) => existedClassList.includes(item.classRoom._id))
      .map((item) => item.classRoom?._id);
    setSwitchState(initialSwitchState);
  }, [data]);

  // Handle switch toggle
  const handleSwitchChange = (id) => {
    setSwitchState((prevState) => {
      if (prevState.includes(id)) {
        // Remove id if it's already in the state
        return prevState.filter((itemId) => itemId !== id);
      } else {
        // Add id if it's not in the state
        return [...prevState, id];
      }
    });
  };

  // Handle submit
  const handleSubmit = () => {
    // Call the onSubmit prop with the current switchState (array of selected class IDs)
    onSubmit(switchState);
    setSwitchState([]);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: "white",
          padding: 4,
          borderRadius: 2,
          maxWidth: 500,
          margin: "auto",
          marginTop: "10%",
          boxShadow: 3,
        }}
      >
        <Typography variant="h6" gutterBottom>
          ເລືອກຫ້ອງທີ່ຕ້ອງການເຫັນບົດຮຽນນີ້
        </Typography>
        <List>
          {data.map((item) => (
            <ListItem
              key={item._id}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <ListItemText
                primary={item.classRoom.name}
                secondary={`Semester: ${item.semester}, Year: ${item.year}`}
              />
              <Switch
                checked={switchState.includes(item.classRoom._id)}
                onChange={() => handleSwitchChange(item.classRoom._id)}
                inputProps={{
                  "aria-label": `Switch for class ${item.classRoom.name}`,
                }}
              />
            </ListItem>
          ))}
        </List>
        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            ຢືນຢັນ
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={onClose}
            sx={{ marginLeft: 2 }}
          >
            ຍົກເລີກ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ManageLessonAccess;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Modal,
  Grid,
} from "@mui/material";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import EmptyComponent from "../../../common/EmptyScreen";

const StudentHomeworkReportAdditional = ({ data }) => {
  const [reportData, setReportData] = useState({});
  const [fullscreenContent, setFullscreenContent] = useState(null);
  const [fullscreenTitle, setFullscreenTitle] = useState("");

  useEffect(() => {
    if (data) {
      setReportData(data);
    }
  }, [data]);

  const handleOpenFullscreen = (title, content) => {
    setFullscreenContent(content);
    setFullscreenTitle(title);
  };

  const handleCloseFullscreen = () => {
    setFullscreenContent(null);
    setFullscreenTitle("");
  };

  // Define colors for pie charts
  const pieColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const renderSection = (title, content) => (
    <Box
      sx={{
        p: 2,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        width: "100%",
        position: "relative",
      }}
    >
      <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
        {title}
      </Typography>
      <IconButton
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => handleOpenFullscreen(title, content)}
      >
        <Fullscreen />
      </IconButton>
      {content}
    </Box>
  );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {renderSection(
            "Question Analysis",
            reportData?.questionAnalysis ? (
              <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                <ResponsiveContainer
                  width={
                    Object.entries(reportData?.questionAnalysis).length * 150
                  }
                  height={300}
                >
                  <BarChart
                    data={Object.entries(reportData?.questionAnalysis).map(
                      ([id, data]) => ({
                        id,
                        ...data,
                      })
                    )}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="question" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="correct" fill="#82ca9d" />
                    <Bar dataKey="incorrect" fill="#ff6666" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <EmptyComponent />
            )
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {renderSection(
            "Student Performance",
            reportData?.studentPerformance ? (
              <TableContainer
                component={Paper}
                style={{ maxHeight: 400, overflowY: "auto" }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Student Name</TableCell>
                      <TableCell align="right">Correct</TableCell>
                      <TableCell align="right">Incorrect</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(reportData?.studentPerformance).map(
                      ([id, student]) => (
                        <TableRow key={id}>
                          <TableCell>{student.studentName}</TableCell>
                          <TableCell align="right">{student.correct}</TableCell>
                          <TableCell align="right">
                            {student.incorrect}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyComponent />
            )
          )}
        </Grid>

        {/* Continue wrapping each renderSection in a Grid item to enforce two-column layout */}
        <Grid item xs={12} md={6}>
          {renderSection(
            "Question Scores",
            reportData.questionScores ? (
              <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                <ResponsiveContainer
                  width={Object.entries(reportData.questionScores).length * 150}
                  height={300}
                >
                  <BarChart
                    data={Object.entries(reportData?.questionScores).map(
                      ([id, data]) => ({
                        id,
                        ...data,
                      })
                    )}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="question" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="totalScore" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <EmptyComponent />
            )
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {renderSection(
            "Answer Distribution",
            reportData?.answerDistribution ? (
              <div
                style={{
                  overflowX: "auto",
                  display: "flex",
                  whiteSpace: "nowrap",
                }}
              >
                {Object.entries(reportData?.answerDistribution).map(
                  ([id, data], index) => (
                    <div
                      key={id}
                      style={{
                        flex: "0 0 auto",
                        width: "300px",
                        marginRight: "16px",
                      }}
                    >
                      <Typography variant="subtitle1">
                        {data?.question}
                      </Typography>
                      <ResponsiveContainer width="100%" height={250}>
                        <PieChart>
                          <Pie
                            dataKey="value"
                            data={[
                              { name: "A", value: data.A },
                              { name: "B", value: data.B },
                              { name: "C", value: data.C },
                              { name: "D", value: data.D },
                            ]}
                            fill="#8884d8"
                            label
                          >
                            {pieColors?.map((color, index) => (
                              <Cell key={`cell-${index}`} fill={color} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  )
                )}
              </div>
            ) : (
              <EmptyComponent />
            )
          )}
        </Grid>

        {/* Repeat the same wrapping with the remaining sections */}
      </Grid>

      <Modal open={!!fullscreenContent} onClose={handleCloseFullscreen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            backgroundColor: "white",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            {fullscreenTitle}
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 16, right: 16 }}
            onClick={handleCloseFullscreen}
          >
            <FullscreenExit />
          </IconButton>
          {fullscreenContent}
        </Box>
      </Modal>
    </Box>
  );
};

export default StudentHomeworkReportAdditional;

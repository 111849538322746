import React, { useState } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  LinearProgress,
  Chip,
  IconButton,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import EmptyCmomponent from "../../../../common/EmptyScreen";

const HardestQuestionList = ({ hardestQuestions }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const renderQuestions = () => (
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        boxShadow: "none",
        maxHeight: openModal ? 800 : 416,
        overflowY: "auto",
        backgroundColor: "#fff",
      }}
    >
      {hardestQuestions.map((question, index) => {
        const isCorrect = question.correctCount > question.incorrectCount;
        const progress =
          (question.correctCount /
            (question.correctCount + question.incorrectCount)) *
            100 || 0;

        return (
          <Accordion
            key={index}
            sx={{
              mb: 3,
              borderRadius: 2,
              backgroundColor: "#f9fafb",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.05)",
              "&:before": {
                display: "none",
              },
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.02)",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#2563eb" }} />}
              sx={{
                backgroundColor: "#e5e7eb",
                borderRadius: "8px",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "#d1d5db",
                },
                px: 2,
              }}
            >
              <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
                <Typography
                  sx={{ fontWeight: "500", color: "#111827", flexGrow: 1 }}
                >
                  {question.questionText}
                </Typography>
                <Chip
                  label={`${progress.toFixed(0)}% ຖືກຕ້ອງ`}
                  size="small"
                  sx={{
                    backgroundColor: isCorrect ? "#4ade80" : "#f87171",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "16px",
                    ml: 2,
                  }}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: "#f3f4f6", borderRadius: "8px", px: 3 }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                sx={{ mb: 2 }}
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <IconButton size="small" sx={{ color: "#10b981" }}>
                    <CheckCircleIcon />
                  </IconButton>
                  <Typography sx={{ color: "#4b5563" }}>
                    <strong>ຖືກຕ້ອງ:</strong> {question.correctCount}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <IconButton size="small" sx={{ color: "#ef4444" }}>
                    <CancelIcon />
                  </IconButton>
                  <Typography sx={{ color: "#4b5563" }}>
                    <strong>ຜິດພາດ:</strong> {question.incorrectCount}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ color: "#6b7280" }}>
                  ຄວາມຄືບໜ້າຄະແນນ:
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: "#e0e7ff",
                    "& .MuiLinearProgress-bar": {
                      background: `linear-gradient(90deg, ${
                        progress >= 50 ? "#3b82f6" : "#ef4444"
                      }, ${progress >= 50 ? "#60a5fa" : "#f87171"})`,
                    },
                    mt: 1,
                  }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Paper>
  );

  return (
    <Box
      sx={{
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
      }}
      p={4}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          ຄຳຖາມທີ່ຍາກທີ່ສຸດ
        </Typography>
        <IconButton onClick={handleOpenModal}>
          <FullscreenIcon />
        </IconButton>
      </Box>
      {hardestQuestions.length > 0 ? (
        renderQuestions()
      ) : (
        <EmptyCmomponent height={416} />
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper
          sx={{
            width: "80%",
            maxHeight: openModal ? 800 : 333,
            height: "80%",
            overflowY: "auto",
            backgroundColor: "white",
            padding: 2,
            borderRadius: 2,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          {hardestQuestions.length > 0 ? (
            renderQuestions()
          ) : (
            <EmptyCmomponent height={416} />
          )}
        </Paper>
      </Modal>
    </Box>
  );
};

export default HardestQuestionList;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Modal,
  Button,
  TextField,
  Stack,
  CircularProgress,
} from "@mui/material";

import AlertMessage from "../../../../common/AlertMessage";
import { fetchPost } from "../../../../common/fetchAPI";

export const convertGender = (gender) => {
  let result = "";
  switch (gender) {
    case "MALE":
      result = "ທ້າວ ";
      break;
    case "FEMALE":
      result = "ນາງ ";
      break;
    case "MONK":
      result = "ພຣະ ";
      break;
    case "NOVICE":
      result = "ສຳມະເນນ";
      break;
    case "OTHER":
      result = "";
      break;
    default: // Do nothing
  }
  return result;
};

const ClassroomQuestionReport = ({ classroomQuestionReport }) => {
  const [showModal, setShowModal] = useState(false);
  const [student, setStudent] = useState("");
  const [sugggestText, setSuggestText] = useState("");

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus((preData) => ({
          ...preData,
          open: false,
        }));
      }, 3000);
    }
  }, [actionStatus]);

  if (!classroomQuestionReport) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const {
    totalStudents,
    totalQuestions,
    totalClassroomScore,
    totalQuestionScore,
    averageClassroomScorePercentage,
    studentScores,
  } = classroomQuestionReport;

  return (
    <Box sx={{ p: 4, backgroundColor: "#eef2f5" }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", mb: 3, color: "#0d47a1" }}
      >
        ລາຍງານຄະແນນການຕອບຄຳຖາມທ້າຍບົດຂອງນັກສຶກສາ
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              textAlign: "center",
              borderRadius: 2,
              backgroundColor: "#f8f9fa",
            }}
          >
            <Typography variant="h7" color="textSecondary">
              ຈຳນວນນັກສຶກສາທັງຫມົດ
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#0d47a1" }}
            >
              {totalStudents}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              textAlign: "center",
              borderRadius: 2,
              backgroundColor: "#f8f9fa",
            }}
          >
            <Typography variant="h7" color="textSecondary">
              ຈຳນວນຄຳຖາມທັງຫມົດ
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#0d47a1" }}
            >
              {totalQuestions}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              textAlign: "center",
              borderRadius: 2,
              backgroundColor: "#f8f9fa",
            }}
          >
            <Typography variant="h7" color="textSecondary">
              ຄະແນນລວມຂອງຫ້ອງຮຽນ
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#0d47a1" }}
            >
              {totalClassroomScore}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              textAlign: "center",
              borderRadius: 2,
              backgroundColor: "#f8f9fa",
            }}
          >
            <Typography variant="h7" color="textSecondary">
              ຄະແນນສະເລ່ຍຂອງຫ້ອງຮຽນ
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#0d47a1" }}
            >
              {averageClassroomScorePercentage}%
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#0d47a1" }}
        >
          ລາຍຊື່ນັກສຶກສາ
        </Typography>
        <TableContainer
          component={Paper}
          elevation={1}
          sx={{ borderRadius: 2, maxHeight: 400, overflow: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#0d47a1",
                    backgroundColor: "#e3f2fd",
                  }}
                >
                  ລະຫັດນັກສຶກສາ
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#0d47a1",
                    backgroundColor: "#e3f2fd",
                  }}
                >
                  ຊື່ ແລະ ນາມສະກຸນ
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#0d47a1",
                    backgroundColor: "#e3f2fd",
                  }}
                >
                  ຄະແນນລວມ
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#0d47a1",
                    backgroundColor: "#e3f2fd",
                  }}
                >
                  ສະເລ່ຍຄະແນນ (%)
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#0d47a1",
                    backgroundColor: "#e3f2fd",
                  }}
                >
                  ສົ່ງຄຳເເນນນຳ
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentScores.map((student) => (
                <TableRow key={student.studentId} hover>
                  <TableCell>{student.studentNumber}</TableCell>
                  <TableCell>
                    {convertGender(student.gennder) + " " + student.name}
                  </TableCell>
                  <TableCell>{student.totalScore}</TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color:
                          parseFloat(student.percentage) >= 50
                            ? "#0d47a1"
                            : "#d32f2f",
                      }}
                    >
                      {student.percentage}%
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Button
                      onClick={async () => {
                        setShowModal(true);
                        setStudent(student);
                      }}
                      variant="contained"
                      sx={{
                        backgroundColor: "#3498db", // Modern blue color
                        color: "#fff",
                        padding: "10px 20px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        borderRadius: "8px", // Slightly rounded corners
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", // Subtle modern shadow
                        textTransform: "none", // Avoid all caps
                        transition:
                          "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out", // Smooth animations
                        "&:hover": {
                          backgroundColor: "#2980b9", // Darker blue on hover
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", // Stronger shadow on hover
                          transform: "scale(1.05)", // Slight zoom effect
                        },
                      }}
                    >
                      ໃຫ້ຄຳເເນະນຳ
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modern-modal-title"
        aria-describedby="modern-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "600px", // Max width of the modal
            maxHeight: "500px", // Max height of the modal
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)", // Modern shadow
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            id="modern-modal-title"
            variant="h6"
            component="h2"
            fontWeight="bold"
            sx={{ mb: 2, textAlign: "center" }}
          >
            {"ສົ່ງຄຳເເນນນຳໃຫ້ນັກສຶກສາ " +
              convertGender(student.gennder) +
              " " +
              student.name}
          </Typography>
          <TextField
            label="ຄຳເເນະນຳ"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={sugggestText}
            onChange={(e) => setSuggestText(e.target.value)}
            sx={{
              mb: 3,
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px", // Rounded corners
                "&:hover fieldset": {
                  borderColor: "#e67e22", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e67e22", // Border color when focused
                },
              },
            }}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: "auto" }}
          >
            <Button
              onClick={() => setShowModal(false)}
              variant="outlined"
              sx={{
                color: "#e74c3c",
                borderColor: "#e74c3c",
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#fdecea", // Light red for hover
                  borderColor: "#c0392b",
                },
              }}
            >
              ຍົກເລີກ
            </Button>
            <Button
              onClick={async () => {
                const response = await fetchPost(
                  "notification/send?userId=" + student?.studentNumber,
                  {
                    title: "ຄຳເເນະນຳຈາກອາຈານ",
                    path: "",
                    body: sugggestText,
                  }
                );
                if (response) {
                  setActionStatus({
                    open: true,
                    type: "success",
                    message: "ສົ່ງຄຳເເນະນຳສຳເລັດ",
                  });
                } else {
                  setActionStatus({
                    type: "error",
                    message: "ລະບົບມີບັນຫາ!",
                    open: true,
                  });
                }
                setShowModal(false);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#3498db",
                color: "#fff",
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#2980b9",
                },
              }}
            >
              ສົ່ງ
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default ClassroomQuestionReport;

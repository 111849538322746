import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { Star, StarBorder, StarHalf } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { fetchGet } from "../../../common/fetchAPI";

const CourseFeedback = () => {
  const { id } = useParams();

  const [feedback, setFFeedback] = useState({
    feedbacks: [],
    averageRate: 0,
  });
  const [loading, setLoading] = useState(true);

  const fetchFeedback = async () => {
    setLoading(true);
    const response = await fetchGet("/tcoursefeedback/" + id);
    if (response) {
      setFFeedback(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFeedback();
  }, []);

  const renderStars = (rate) => {
    const fullStars = Math.floor(rate);
    const halfStar = rate % 1 !== 0;
    const emptyStars = 5 - Math.ceil(rate);

    return (
      <>
        {Array.from({ length: fullStars }, (_, i) => (
          <Star key={`full-${i}`} style={{ color: "#FFD700" }} />
        ))}
        {halfStar && <StarHalf style={{ color: "#FFD700" }} />}
        {Array.from({ length: emptyStars }, (_, i) => (
          <StarBorder key={`empty-${i}`} style={{ color: "#FFD700" }} />
        ))}
      </>
    );
  };

  function formatFeedbackDate(date) {
    const currentDate = new Date();
    const feedbackDate = new Date(date);
    const diffTime = Math.abs(currentDate - feedbackDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffWeeks = Math.floor(diffDays / 7);
    const diffMonths = Math.floor(diffDays / 30);

    if (diffDays <= 7) {
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    } else if (diffWeeks < 4) {
      return `${diffWeeks} week${diffWeeks > 1 ? "s" : ""} ago`;
    } else {
      return `${diffMonths} month${diffMonths > 1 ? "s" : ""} ago`;
    }
  }

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="90vh"
        sx={{
          backgroundColor: "white",
        }}
      >
        <CircularProgress color="primary" />
        <Typography variant="h6" marginTop={2}>
          ກຳລັງໂຫລດ...
        </Typography>
      </Box>
    );
  }

  return (
    <Box padding={1} paddingTop={0}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          backgroundColor: "white",
          paddingTop: "25px",
          paddingBottom: "25px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
        marginBottom={1}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "#333", fontWeight: "bold", textAlign: "center" }}
        >
          ຄະແນນສະເລ່ຍ: {feedback.averageRate.toFixed(1)}/5
        </Typography>
        <Box display="flex" justifyContent="center" mt={1}>
          {renderStars(feedback.averageRate)}
        </Box>
      </Box>

      <Grid container spacing={1}>
        {feedback.feedbacks.map((feedback) => (
          <Grid item xs={12} sm={6} md={4} key={feedback._id}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
              elevation={1}
            >
              <CardContent>
                <Box display="flex" alignItems="center" marginBottom={1}>
                  <Avatar sx={{ bgcolor: "#3f51b5", marginRight: 2 }}>
                    {feedback?.student?.firstNameL.charAt(0)}
                  </Avatar>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {feedback?.student?.firstNameL}{" "}
                      {feedback.student.lastNameL}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatFeedbackDate(feedback.createdAt)}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" marginBottom={1}>
                  {renderStars(feedback.rate)}
                  <Typography
                    variant="body2"
                    marginLeft={1}
                    color="textSecondary"
                  >
                    {feedback.rate}/5
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ color: "#555" }}>
                  {feedback.comment}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CourseFeedback;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { fetchGet } from "../../../common/fetchAPI";
import ErrorIcon from "@mui/icons-material/Error";
import RefreshIcon from "@mui/icons-material/Refresh";
import useReactRouter from "use-react-router";
import { setCourseDetail } from "../../../redux/tCourseSlice";
import { useDispatch, useSelector } from "react-redux";

const WhiteTableCell = styled(TableCell)({
  color: "white",
  textAlign: "center",
});

const StudentSubmitList = () => {
  // store course details
  const course = useSelector((state) => state.tcourse);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const { history } = useReactRouter();

  const [classValue, setClassValue] = useState("");
  const [examValue, setExamValue] = useState("");

  useEffect(() => {
    if (course.exam || course.class) {
      setClassValue(course.class);
      setExamValue(course.exam);
    }
  }, [course]);

  const [classRoomList, setClassRoomList] = useState([]);
  const [examList, setExamList] = useState([]);

  const fetchExamResult = async () => {
    setIsLoading(true);
    setIsError(false);
    setIsEmpty(false);
    const response = await fetchGet(
      `/texam/result/course/${course?.courseId}?classId=${classValue}&examId=${examValue}`
    );
    if (response) {
      if (response.length > 0) {
        setData(response);
      } else {
        setIsEmpty(true);
      }
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchExamResult();
  }, [classValue, examValue]);

  const handleClick = (id) => {
    dispatch(
      setCourseDetail({
        tabIndex: 2,
        exam: examValue,
        class: classValue,
      })
    );
    history.push({
      pathname: "/elearning/examsubmit/" + id,
    });
  };

  const fetchExamList = async () => {
    const _examList = await fetchGet("/texam/course/" + course?.courseId);
    setExamList(_examList);
  };

  const fetchClassrooms = async () => {
    const _classList = await fetchGet("/tcourse/class/" + course?.courseId);
    setClassRoomList(_classList);
  };

  useEffect(() => {
    fetchClassrooms();
    fetchExamList();
  }, []);

  const handleClassChange = (event) => {
    setClassValue(event.target.value);
  };

  const handleExamChange = (event) => {
    setExamValue(event.target.value);
  };

  return (
    <Box
      sx={{
        marginLeft: 2,
        marginRight: 2,
        backgroundColor: "white",
        p: 3,
        minHeight: "80vh",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mt: 1,
          marginBottom: "8px",
          fontWeight: "600",
          color: "#1976d2",
        }}
      >
        ເລືອກຫ້ອງຮຽນ ແລະ ບົດສອບເສັງ
      </Typography>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={12} md={6} p={1}>
          <FormControl fullWidth variant="outlined">
            <InputLabel size="small">ເລືອກຫ້ອງຮຽນ</InputLabel>
            <Select
              size="small"
              value={classValue}
              onChange={handleClassChange}
              label="ເລືອກຫ້ອງຮຽນ"
            >
              {classRoomList.map((_class) => (
                <MenuItem key={_class._id} value={_class.classRoom?._id}>
                  {_class?.classRoom?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} p={1}>
          <FormControl fullWidth variant="outlined">
            <InputLabel size="small">ເລືອກບົດເສັງ</InputLabel>
            <Select
              size="small"
              value={examValue}
              onChange={handleExamChange}
              label="ເລືອກບົດເສັງ"
            >
              {examList.map((exam) => (
                <MenuItem key={exam._id} value={exam?._id}>
                  {exam?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Loading, Error, Empty, and Data States */}
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "60vh",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>
            ກຳລັງໂຫລດ...
          </Typography>
        </Box>
      ) : isError ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "60vh",
          }}
        >
          <ErrorIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
          <Typography variant="h6" color="error">
            ຂໍອະໄພ, ລພບົບມີບັນຫາ
          </Typography>
          <Button
            onClick={fetchExamResult}
            sx={{
              mt: 2,
            }}
            startIcon={<RefreshIcon />}
            variant="contained"
            color="primary"
          >
            ລອງໃຫມ່
          </Button>
        </Box>
      ) : isEmpty ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "60vh",
          }}
        >
          <ErrorIcon color="info" sx={{ fontSize: 60, mb: 2 }} />
          <Typography variant="h6">ບໍ່ມີຂໍ້ມຸນ.</Typography>
          <Button
            sx={{
              mt: 2,
            }}
            onClick={fetchExamResult}
            startIcon={<RefreshIcon />}
            variant="contained"
            color="primary"
          >
            ລອງໃຫມ່
          </Button>
        </Box>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <WhiteTableCell sx={{ border: "1px solid lightgray" }}>
                  ຫົວຂໍ້ບົດເສັງ
                </WhiteTableCell>
                <WhiteTableCell sx={{ border: "1px solid lightgray" }}>
                  ຊື່ ແລະ ນາມສະກຸນ
                </WhiteTableCell>
                <WhiteTableCell sx={{ border: "1px solid lightgray" }}>
                  ເພດ
                </WhiteTableCell>
                <WhiteTableCell sx={{ border: "1px solid lightgray" }}>
                  ປີຮຽນ
                </WhiteTableCell>
                <WhiteTableCell sx={{ border: "1px solid lightgray" }}>
                  ຫ້ອງຮຽນ
                </WhiteTableCell>
                <WhiteTableCell sx={{ border: "1px solid lightgray" }}>
                  ເວລາທີ່ໃຊ້ (ນາທີ)
                </WhiteTableCell>
                <WhiteTableCell sx={{ border: "1px solid lightgray" }}>
                  ສະຖານະ
                </WhiteTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow
                  key={item._id}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                  onClick={() => handleClick(item._id)}
                >
                  <TableCell
                    sx={{
                      border: "1px solid lightgray",
                      textAlign: "center",
                    }}
                  >
                    {item.exam?.title}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid lightgray",
                      textAlign: "center",
                    }}
                  >
                    {item.student.firstNameL} {item.student.lastNameL}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid lightgray",
                      textAlign: "center",
                    }}
                  >
                    {item.student.gender}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid lightgray",
                      textAlign: "center",
                    }}
                  >
                    {item.student.yearLevel}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid lightgray",
                      textAlign: "center",
                    }}
                  >
                    {item.classRoom.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid lightgray",
                      textAlign: "center",
                      color: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    {item.timeToTake}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid lightgray",
                      textAlign: "center",
                      color: item.approved ? "green" : "red", // Change color based on condition
                      fontWeight: "bold", // Make the text bold
                    }}
                  >
                    {item.approved ? "ໃຫ້ຄະເເນນແລ້ວ" : "ລໍຖ້າໃຫ້ຄະເເນນ"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default StudentSubmitList;

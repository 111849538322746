import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
} from "@mui/material";

const LikeListDialog = ({ likes, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>ລາຍຊື່ຜູ້ທີ່ຖືກໃຈ</DialogTitle>
      <DialogContent dividers>
        {likes.length > 0 ? (
          <List>
            {likes.map((like) => (
              <ListItem key={like._id}>
                <ListItemAvatar>
                  <Avatar
                    src={`https://your-image-base-url.com/${like.image.url}`}
                    alt={`${like.firstNameL} ${like.lastNameL}`}
                  >
                    {like.firstNameL[0]}
                    {like.lastNameL[0]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${like.firstNameL} ${like.lastNameL}`}
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      {like.studentNumber}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography align="center" color="text.secondary">
            ຍັງບໍ່ມີຜູ້ທີ່ຖືກໃຈ
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LikeListDialog;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
} from "../../common/fetchAPI";
import ConfirmModal from "../../common/ConfirmDialog";
import { useSelector } from "react-redux";
import AlertMessage from "../../common/AlertMessage";

const EntranceBuilding = () => {
  const [entranceBuildingList, setEntranceBuildingList] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [placeList, setPlaceList] = useState([]);
  const [selectedPlaceId, setSelectedPlaceId] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });

  const [formBuilding, setFormBuilding] = useState({
    building: "",
    buildingInfo: "",
    place: "",
  });

  const [formBuildingValidate, setFormBuildingValidate] = useState({
    building: "",
    buildingInfo: "",
    place: "",
  });

  const isValid = () => {
    const error = {};

    if (!formBuilding.building) error.building = "ກະລຸນາປ້ອນຂໍ້ມູນ";
    if (!formBuilding.place) error.place = "ກະລຸນາປ້ອນຂໍ້ມູນ";

    setFormBuildingValidate(error);
    return Object.keys(error).length === 0;
  };

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  useEffect(() => {
    fetchPlaceList();
  }, [accessmentRedux]);

  useEffect(() => {
    if (selectedPlaceId) fetchEntranceBuilding();
  }, [selectedPlaceId]);

  const fetchPlaceList = async () => {
    const response = await fetchGet(
      `entrance/place?assessmentYear=${accessmentRedux}`
    );
    if (response) {
      setPlaceList(response);
    }
  };

  const fetchEntranceBuilding = async () => {
    const response = await fetchGet(
      `entrance/building?place=${selectedPlaceId}`
    );
    if (response) {
      setEntranceBuildingList(response);
    }
  };

  const handleAddOrUpdateBuilding = async () => {
    if (isValid()) {
      const apiMethod = selectedBuilding ? fetchPut : fetchPost;
      const apiUrl = selectedBuilding
        ? `entrance/building/${selectedBuilding}`
        : "entrance/building";

      const response = await apiMethod(apiUrl, formBuilding);
      if (response) {
        setActionStatus({
          type: "success",
          message: selectedBuilding
            ? "ອັບເດດຂໍ້ມູນສຳເລັດ"
            : "ເພີ່ມຂໍ້ມູນສຳເລັດ",
          open: true,
        });
        fetchEntranceBuilding();
        setShowAddModal(false);
        setSelectedBuilding(null);
      } else {
        setActionStatus({
          type: "error",
          message: "ຂໍອະໄພ ລະບົບມີບັນຫາ",
          open: true,
        });
      }
    }
  };

  const handleDelete = (id) => {
    setSelectedBuilding(id);
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    const response = await fetchDelete(`entrance/building/${selectedBuilding}`);
    if (response) {
      setActionStatus({
        type: "success",
        message: "ລົບຂໍ້ມູນສຳເລັດ",
        open: true,
      });
      fetchEntranceBuilding();
    } else {
      setActionStatus({
        type: "error",
        message: "ຂໍອະໄພ ລະບົບມີບັນຫາ",
        open: true,
      });
    }
    setShowConfirmModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormBuilding({ ...formBuilding, [name]: value });
    setFormBuildingValidate({ ...formBuildingValidate, [name]: "" });
  };

  return (
    <Box
      sx={{
        padding: 2,
        px: 30,
        minHeight: "80vh",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: 2,
        backgroundColor: "white",
      }}
    >
      <FormControl fullWidth>
        <InputLabel>ເລືອກສະຖານທີ</InputLabel>
        <Select
          label="ເລືອກສະຖານທີ"
          value={selectedPlaceId}
          onChange={(e) => setSelectedPlaceId(e.target.value)}
        >
          {placeList.map((place) => (
            <MenuItem key={place._id} value={place._id}>
              {place?.place}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setShowAddModal(true)}
        sx={{ mt: 2, mb: 2 }}
      >
        ເພີ່ມສະຖານທີ
      </Button>
      <Typography mb={2} variant="h5">
        ຂໍ້ມູນລາຍການສະຖານທີ
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#edf2f4",
                  textAlign: "center",
                }}
              >
                ຊື່ອາຄານ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#edf2f4",
                  textAlign: "center",
                }}
              >
                ລາຍລະອຽດ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#edf2f4",
                  textAlign: "center",
                }}
              >
                ສະຖານທີ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#edf2f4",
                  textAlign: "center",
                }}
              >
                ຈຳນວນຫ້ອງ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#edf2f4",
                  textAlign: "center",
                }}
              >
                ການດໍາເນີນການ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entranceBuildingList.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell sx={{ textAlign: "center" }}>
                  {entry.building}
                </TableCell>
                <TableCell>{entry?.buildingInfo}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {entry?.placeDetails?.place}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {entry?.roomCount}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setShowAddModal(true);
                      setSelectedBuilding(entry._id);
                      setFormBuilding(entry);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(entry._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={showAddModal} onClose={() => setShowAddModal(false)}>
        <DialogTitle>
          {selectedBuilding ? "ອັບເດດຂໍ້ມູນ" : "ເພີ່ມຂໍ້ມູນ"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="ຊື່ຕຶກ"
            name="building"
            value={formBuilding.building}
            onChange={handleInputChange}
            fullWidth
            error={!!formBuildingValidate.building}
            helperText={formBuildingValidate.building}
          />
          <TextField
            margin="dense"
            label="ລາຍລະອຽດຕຶກ"
            name="buildingInfo"
            value={formBuilding.buildingInfo}
            onChange={handleInputChange}
            fullWidth
            error={!!formBuildingValidate.buildingInfo}
            helperText={formBuildingValidate.buildingInfo}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>ເລືອກສະຖານທີ</InputLabel>
            <Select
              label="ເລືອກສະຖານທີ"
              name="place"
              value={formBuilding.place}
              onChange={handleInputChange}
              error={!!formBuildingValidate.place}
            >
              {placeList.map((place) => (
                <MenuItem key={place._id} value={place._id}>
                  {place?.place}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddModal(false)}>ຍົກເລີກ</Button>
          <Button onClick={handleAddOrUpdateBuilding} variant="contained">
            ບັນທຶກ
          </Button>
        </DialogActions>
      </Dialog>

      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />

      <ConfirmModal
        open={showConfirmModal}
        message={"ທ່ານຕ້ອງການລົບຂໍ້ມູນແທ້ບໍ່"}
        title={"ລົບຂໍ້ມູນ"}
        onSubmit={confirmDelete}
        onCancel={() => setShowConfirmModal(false)}
      />
    </Box>
  );
};

export default EntranceBuilding;

import { createSlice } from "@reduxjs/toolkit";

export const courseSlice = createSlice({
  name: "tcourse",
  initialState: {
    userId: "",
    courseId: "",
    tabIndex: 0,
    class: "",
    exam: "",
  },
  reducers: {
    setCourseDetail: (state, action) => {
      if (action.payload.courseId !== undefined) {
        state.courseId = action.payload.courseId;
      }
      if (action.payload.tabIndex !== undefined) {
        state.tabIndex = action.payload.tabIndex;
      }
      if (action.payload.class !== undefined) {
        state.class = action.payload.class;
      }
      if (action.payload.exam !== undefined) {
        state.exam = action.payload.exam;
      }
    },
  },
});

export const { setCourseDetail } = courseSlice.actions;
export default courseSlice.reducer;

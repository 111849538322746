import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Alert,
  FormControl,
  CircularProgress,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { AddCircle, Delete } from "@mui/icons-material";
import { fetchPost, fetchPut } from "../../../common/fetchAPI";

const UpdateQuestionModal = ({ open, handleClose, onRefresh, question }) => {
  const lessonId = useSelector((state) => state.lesson);
  const [newQuestion, setNewQuestion] = useState({
    question: "",
    A: "",
    B: "",
    C: "",
    D: "",
    answer: "",
    score: "",
    lesson: lessonId.value,
  });

  useEffect(() => {
    if (question) {
      setNewQuestion({
        id: question?._id,
        question: question?.question,
        A: question?.A,
        B: question?.B,
        C: question?.C,
        D: question?.D,
        answer: question?.answer,
        score: question?.score,
        lesson: lessonId.value,
      });
    }
  }, [question]);

  const [errors, setErrors] = useState({}); // Validation errors state

  const handleChange = (e) => {
    setNewQuestion({
      ...newQuestion,
      [e.target.name]: e.target.value,
      lesson: lessonId.value,
    });
  };

  // Validation function
  const validateFields = () => {
    const newErrors = {};
    if (!newQuestion.question.trim()) newErrors.question = "ກະລຸນາປ້ອນຄຳຖາມ";
    if (!newQuestion.A.trim()) newErrors.A = "ກະລຸນາປ້ອນຕົວເລືອກ A";
    if (!newQuestion.B.trim()) newErrors.B = "ກະລຸນາປ້ອນຕົວເລືອກ B";
    if (!newQuestion.C.trim()) newErrors.C = "ກະລຸນາປ້ອນຕົວເລືອກ C";
    if (!newQuestion.D.trim()) newErrors.D = "ກະລຸນາປ້ອນຕົວເລືອກ D";
    if (!newQuestion.answer) newErrors.answer = "ກະລຸນາເລືອກຄຳຕອບທີ່ຖືກ";
    if (!newQuestion.score) newErrors.score = "ກະລຸນາປ້ອນຄະແນນ";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");

  const handleSave = async () => {
    // Validate the form fields
    if (!validateFields()) {
      return; // Stop if validation fails
    }

    setIsLoading(true);
    try {
      await fetchPut("/tquestion/" + newQuestion.id, newQuestion);
      setIsLoading(false);
      setIsError("");
      onRefresh();
      handleClose();
    } catch (err) {
      setIsError("ມີບັນຫາໃນການເເກ້ໄຂຄຳຖາມ");
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>ແກ້ໄຂຄຳຖາມ</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="ຄຳຖາມ"
          name="question"
          size="medium"
          value={newQuestion.question}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.question}
          helperText={errors.question}
        />
        <TextField
          margin="dense"
          label="ຕົວເລືອກ A"
          name="A"
          size="medium"
          value={newQuestion.A}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.A}
          helperText={errors.A}
        />
        <TextField
          margin="dense"
          label="ຕົວເລືອກ B"
          name="B"
          size="medium"
          value={newQuestion.B}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.B}
          helperText={errors.B}
        />
        <TextField
          margin="dense"
          label="ຕົວເລືອກ C"
          size="medium"
          name="C"
          value={newQuestion.C}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.C}
          helperText={errors.C}
        />
        <TextField
          margin="dense"
          label="ຕົວເລືອກ D"
          size="medium"
          name="D"
          value={newQuestion.D}
          onChange={handleChange}
          fullWidth
          required
          error={!!errors.D}
          helperText={errors.D}
        />

        {/* Using MUI Select for the answer */}
        <FormControl fullWidth margin="dense" required error={!!errors.answer}>
          <InputLabel>ຄຳຕອບທີ່ຖືກ</InputLabel>
          <Select
            size="medium"
            name="answer"
            label="ຄຳຕອບທີ່ຖືກ"
            value={newQuestion.answer}
            onChange={handleChange}
          >
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="C">C</MenuItem>
            <MenuItem value="D">D</MenuItem>
          </Select>
          {!!errors.answer && (
            <p style={{ color: "red", fontSize: "0.75rem" }}>{errors.answer}</p>
          )}
        </FormControl>

        <TextField
          margin="dense"
          label="ຄະແນນ"
          size="medium"
          name="score"
          value={newQuestion.score}
          onChange={handleChange}
          type="number"
          fullWidth
          required
          error={!!errors.score}
          helperText={errors.score}
        />
        {isError && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {isError}
            </Alert>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          {isLoading ? <CircularProgress /> : "ບັນທຶກ"}
        </Button>
        <Button onClick={handleClose} color="secondary">
          ຍົກເລີກ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateQuestionModal;

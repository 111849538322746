import React, { useEffect, useState } from "react";
import { Box, Paper, Grid, Tabs, Tab } from "@mui/material";
import CourseDetailTop from "./Top"; // Adjust the path if needed
import LessonList from "./LessonList"; // Import your LessonList component
import { useParams } from "react-router-dom";
import { fetchGet } from "../../../common/fetchAPI";
import LoadingDialog from "../../../common/LoadingDialog";
import ErrorDialog from "../../../common/ErrorDialog";
import AddLessonModal from "./AddLessonMoal";
import { useSelector, useDispatch } from "react-redux";
import ChapterList from "./ChapterList";
import QuestionList from "./QuestionList";
import ExamList from "./ExamList";
import ELearningReport from "../reports";
import StudentSubmitList from "./ExamSubmitList";
import HomeworkList from "./HomeworkList";
import HomeworkSubmitList from "./HomeworkSubmitList";
import CourseFeedback from "./Feedback";
// import ClassChat from "../classChats/pages/ClassChat";

const TCourseDetail = () => {
  const [courseDetail, setCourseDetail] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemClick, setItemClick] = useState("chapter");

  const course = useSelector((state) => state.tcourse);
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(course.tabIndex);

  useEffect(() => {
    if (course) {
      setTabIndex(course.tabIndex);
    }
  }, [course]);

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const response = await fetchGet(`tcourse/${id}`);
      if (response) setCourseDetail(response);
      else setError(true);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [id]);

  const lessonId = useSelector((state) => state.lesson);
  const [chapterlist, setChapterList] = useState([]);
  const [fetchError, setFetchError] = useState();
  const [chapterLoading, setChaoterLoading] = useState(false);
  const [lessonClickData, setLessonClickData] = useState();

  const fetchChapters = async () => {
    if (lessonId.value) {
      setFetchError(false);
      setChaoterLoading(true);
      try {
        const response = await fetchGet(`tchapter/${lessonId.value}`);
        setLessonClickData(response);
        setChapterList(response?.chapters || []);
      } catch (err) {
        setFetchError(true);
      } finally {
        setChaoterLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchChapters();
  }, [lessonId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100vh", // Full viewport height
      }}
    >
      <LoadingDialog open={loading} />
      <ErrorDialog
        open={error}
        message={"ມີບັນຫາໃນການສະເເດງລາຍລະອຽດບົດສອນ"}
        onClose={() => setError(false)}
        onRefresh={() => {
          fetchCourses();
          setError(false);
        }}
      />
      <AddLessonModal
        courseId={id}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onRefresh={fetchCourses}
      />

      {/* Fixed Tabs at the top */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000, // Ensure the tabs stay above other content
          backgroundColor: "white",
          boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.1)", // Bottom-only shadow
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          sx={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tab
            label="ບົດສອນ"
            sx={{ fontSize: "18px", minWidth: "150px", maxWidth: "150px" }}
          />
          <Tab
            label="ຫົວບົດເສັງ"
            sx={{ fontSize: "18px", minWidth: "150px", maxWidth: "150px" }}
          />
          <Tab
            label="ກວດບົດເສັງ"
            sx={{ fontSize: "18px", minWidth: "150px", maxWidth: "150px" }}
          />
          <Tab
            label="ວຽກບ້ານ"
            sx={{ fontSize: "18px", minWidth: "150px", maxWidth: "150px" }}
          />
          <Tab
            label="ກວດວຽກບ້ານ"
            sx={{ fontSize: "18px", minWidth: "150px", maxWidth: "150px" }}
          />
          <Tab
            label="ລາຍງານ"
            sx={{ fontSize: "18px", minWidth: "150px", maxWidth: "150px" }}
          />
          <Tab
            label="ຄຳຄິດເຫັນ"
            sx={{ fontSize: "18px", minWidth: "150px", maxWidth: "150px" }}
          />
          {/* <Tab
            label="ສົນທະນາ"
            sx={{ fontSize: "18px", minWidth: "150px", maxWidth: "150px" }}
          /> */}
        </Tabs>
      </Box>

      {/* Scrollable content below the fixed tabs */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto", // Allow scrolling for content
          padding: 2, // Add some padding for better spacing
        }}
      >
        {tabIndex === 0 && (
          <Box>
            {!loading && !error && courseDetail && (
              <CourseDetailTop
                onAddLesson={() => setIsModalOpen(true)}
                onRefresh={fetchCourses}
                title={courseDetail.title}
                description={courseDetail.description}
                onButtonClick={() => alert("Button Clicked!")}
              />
            )}

            <Grid container marginTop={0} spacing={1}>
              <Grid item xs={12} md={3}>
                <LessonList
                  onItemClick={(item) => setItemClick(item)}
                  onRefresh={fetchCourses}
                  lessonList={courseDetail?.lessons || []}
                />
              </Grid>

              {lessonId?.value && (
                <Grid item xs={12} md={9}>
                  <Paper
                    sx={{
                      paddingY: 2,
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {itemClick === "chapter" ? (
                      <ChapterList
                        onRefresh={fetchChapters}
                        lesson={lessonClickData}
                        isLoading={chapterLoading}
                        isError={fetchError}
                        chapters={chapterlist}
                      />
                    ) : (
                      <QuestionList />
                    )}
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        {tabIndex === 1 && <ExamList />}
        {tabIndex === 2 && <StudentSubmitList />}
        {tabIndex === 3 && <HomeworkList />}
        {tabIndex === 4 && <HomeworkSubmitList />}
        {tabIndex === 5 && <ELearningReport />}
        {tabIndex === 6 && <CourseFeedback />}
        {/* {tabIndex === 7 && <ClassChat />} */}
      </Box>
    </Box>
  );
};

export default TCourseDetail;

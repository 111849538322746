import React, { useState } from "react";
import { Tab, Tabs, Typography, Box } from "@mui/material";
import { Breadcrumb } from "react-bootstrap";
import { Padding } from "@mui/icons-material";
import { CustomContainer } from "../../common";
import "./index.css";
import TeacherTab from "./statisticTeacher/TeacherTab";
import StudentTab from "./StudentTab";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}{" "}
      {/* Adjusted padding to 0 */}
    </div>
  );
};

export const StatisticPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item active>ຟອມເກັບກຳສະຖິຕິ</Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="horizontal"
        sx={{ marginLeft: 1 }}
      >
        <Tab
          label="ສະຖິຕິຂໍ້ມູນນັກສຶກສາ"
          id="tab-0"
          sx={{
            backgroundColor: value === 0 ? "white" : "inherit",
            borderRadius: "5px 5px 0px 0px",
          }}
        />
        <Tab
          label="ສະຖິຕິຂໍ້ມູນພະນັກງານຄູ-ອາຈານ ມຊ"
          id="tab-1"
          sx={{
            backgroundColor: value === 1 ? "white" : "inherit",
            borderRadius: "5px 5px 0px 0px",
          }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <StudentTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TeacherTab />
      </TabPanel>
    </div>
  );
};

export default StatisticPage;

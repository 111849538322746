import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { TextField,} from "@mui/material";
import "./index.css"; // Import your custom CSS
import { NEW_API_URL } from "../../common/contrant";

function Popup() {
  const [show, setShow] = useState(false);
  const [scholarships, setScholarships] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [validated, setValidated] = useState({
    title: false,
    image: false,
  });

  useEffect(() => {
    fetchScholarships();
  }, []);

  const fetchScholarships = async () => {
    try {
      const response = await axios.get(NEW_API_URL + "popup/all");
      setScholarships(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddNew = () => {
    setShow(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (!newTitle) {
      setValidated((prev) => ({ ...prev, title: true }));
      isValid = false;
    } else {
      setValidated((prev) => ({ ...prev, title: false }));
    }

    if (!newImage) {
      setValidated((prev) => ({ ...prev, image: true }));
      isValid = false;
    } else {
      setValidated((prev) => ({ ...prev, image: false }));
    }

    if (isValid) {
      const formData = new FormData();
      formData.append("title", newTitle);
      formData.append("file", newImage);

      try {
        const response = await axios.post(NEW_API_URL + "popup", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setScholarships([...scholarships, response.data]);
        setShow(false);
      } catch (error) {
        console.error("Error adding scholarship:", error);
      }
    }
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(NEW_API_URL + "/popup/" + itemToDelete._id);
      setScholarships(scholarships.filter((s) => s._id !== itemToDelete._id));
      setShowDeleteModal(false);
      setItemToDelete(null);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <div className="body px-5 py-3">
      <div className="d-flex justify-content-end">
        <Button variant="success" className="d-flex align-items-center" onClick={handleAddNew}>
          <i className="fas fa-plus-circle me-2"></i> ເພີ່ມໃຫມ່
        </Button>
      </div>
      <Row>
        {scholarships.map((item, index) => (
          <Col key={index} xs={12} md={6} lg={3} xl={3}>
            <div className="card card-body p-3 mt-3 position-relative custom-card">
              <div className="rounded border">
                <img
                  src={item.image}
                  alt={item.title}
                  className="card-img-top custom-img"
                />
                <button
                  className="position-absolute top-0 end-0 border-d"
                  onClick={() => handleDeleteClick(item)}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
              <div className="card-body p-2">
                <h5 className="card-title text-center">{item.title}</h5>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      {/* Add Modal for adding new item */}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ເພີ່ມຫົວຂໍ້ ແລະ ຮູບ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSave}>
            <TextField
              label="ຫົວຂໍ້"
              fullWidth
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              required
              error={validated.title}
              helperText={validated.title ? "Please enter a title." : ""}
              margin="normal"
            />
            <TextField
              type="file"
              fullWidth
              onChange={handleImageChange}
              required
              error={validated.image}
              helperText={validated.image ? "Please select an image." : ""}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className="d-flex justify-content-end mt-3">
              <Button variant="secondary" onClick={() => setShow(false)} className="me-2">
                ປິດ
              </Button>
              <Button variant="primary" type="submit">
                ບັນທຶກ
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ທ່ານແນ່ໃຈບໍ່ວ່າຢາກລົບ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>ຫົວຂໍ້: {itemToDelete?.title}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            ຍົກເລີກ
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            ລຶບ
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Popup;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { fetchDelete, fetchGet } from "../../common/fetchAPI";
import useReactRouter from "use-react-router";
import ReplayIcon from "@mui/icons-material/Replay";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../common/ConfirmModal";
import EvaluationPreview from "./evaluationPreview";
import { toast } from "react-toastify";
import { USER_KEY } from "../../consts";

const EvaluationList = () => {
  const [evaluationList, setEvaluationList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedTemplate] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [showPreview, setShowPreview] = useState(false);

  const getEvaluationList = async () => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = user ? JSON.parse(user) : null;
    try {
      const response = await fetchGet(
        "/evaluation?faculty=" + (userObject?.data?.faculty?._id || [])
      );
      setEvaluationList(response || []);
    } catch (err) {
      console.error("Error fetching evaluation list:", err);
    }
  };

  useEffect(() => {
    getEvaluationList();
  }, []);

  const { history } = useReactRouter();

  const deleteTemplate = async () => {
    if (!selectedId) return;

    try {
      const _delete = await fetchDelete("/evaluation/" + selectedId);
      if (_delete) {
        toast.info("ລົບສຳເລັດ", { autoClose: 1500 });
        setShowDeleteModal(false);
        await getEvaluationList();
      } else {
        toast.error("ລະບົບມີບັນຫາໃນການລົບ", { autoClose: 1500 });
      }
    } catch (err) {
      console.error("Error deleting evaluation:", err);
    }
  };

  return (
    <Grid
      sx={{
        backgroundColor: "white",
      }}
      container
    >
      <EvaluationPreview
        open={showPreview}
        close={() => setShowPreview(false)}
        data={selectedItem || {}}
      />
      <ConfirmModal
        title="ທ່ານຕ້ອງການລົບແທ້ບໍ່?"
        message="ຖ້າທ່ານກົດຢືນຢັນທຸກຂໍ້ມຸນທີ່ຢູ່ໃນຟອມນີ້ຈະຖືລົບອອກຫມົດ?"
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={deleteTemplate}
        open={showDeleteModal}
      />
      <Box
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h5">ລາຍການເເບບຟອມປະເມີນ</Typography>

        <Box>
          <Button
            onClick={() => {
              history.push("/evaluation/add");
            }}
            startIcon={<AddIcon sx={{ color: "white" }} />}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 1,
              marginRight: 1,
              paddingBottom: 1,
              backgroundColor: "#04658f",
              color: "white",
              "&:hover": {
                backgroundColor: "#03506b",
              },
            }}
          >
            ສ້າງເເບບຟອມໃຫມ່
          </Button>

          <IconButton color="primary" onClick={getEvaluationList}>
            <ReplayIcon />
          </IconButton>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: "100px",
                  backgroundColor: "#04658f",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ລຳດັບ
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: "#04658f",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ຫົວຂໍ້
              </TableCell>
              <TableCell
                style={{
                  width: "200px",
                  backgroundColor: "#04658f",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ຈັດການ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluationList?.length > 0 ? (
              evaluationList.map((row, index) => (
                <TableRow key={row?.id || index}>
                  <TableCell
                    style={{
                      width: "100px",
                      textAlign: "center",
                      color: "black",
                      backgroundColor: "white",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "start",
                      color: "black",
                      backgroundColor: "white",
                    }}
                  >
                    {row?.description || "N/A"}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "200px",
                      textAlign: "center",
                      color: "black",
                      backgroundColor: "white",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedItem(row);
                        setShowPreview(true);
                      }}
                      color="success"
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        history.push("/evaluation/add/", { data: row });
                      }}
                      color="warning"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedTemplate(row?.id);
                        setShowDeleteModal(true);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }}>
                  ບໍ່ພົບຂໍ້ມູນ
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default EvaluationList;

import { NEW_API_URL, API_KEY } from "../common/contrant";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
} from "@mui/material";
import axios from "axios";
import { Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../redux/filtrSlice";
import { USER_KEY } from "../consts";
import { fetchGet } from "./fetchAPI";

const EntranceStudentFilter = ({ description, onSelect, status }) => {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const filterData = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [majors, setMajors] = useState([]);

  const [placeList, setPlaceList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [classRoomList, setClassRoomList] = useState([]);

  const fetchPlaceList = async () => {
    const response = await fetchGet("entrance/place");
    if (response) setPlaceList(response);
  };

  const fetchBuildingList = async () => {
    const response = await fetchGet(
      `entrance/building?place=${
        formData?.place === "All" ? "" : formData?.place
      }`
    );
    if (response) setBuildingList(response);
  };

  const fetchClassRoomList = async () => {
    const response = await fetchGet(
      `entrance/room?building=${
        formData?.building === "All" ? "" : formData?.building
      }`
    );
    if (response) setClassRoomList(response);
  };

  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    major: filterData.major || "",
    date: "",
    title: "",
    assessmentYear: accessmentRedux,
    building: "",
    place: "",
    room: "",
  });

  useEffect(() => {
    if (formData?.place) {
      fetchBuildingList();
    }
  }, [formData?.place]);

  useEffect(() => {
    if (formData?.building) {
      fetchClassRoomList();
    }
  }, [formData?.building]);

  useEffect(() => {
    fetchPlaceList();
    setFormData((predata) => ({
      ...predata,
      assessmentYear: accessmentRedux,
    }));
  }, [accessmentRedux]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "faculty") {
      setFormData((prevData) => ({
        ...prevData,
        department: "", // Reset department when faculty changes
        [name]: value,
      }));
    }
    if (name === "department") {
      setFormData((prevData) => ({
        ...prevData,
        major: "", // Reset department when faculty changes
        [name]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Fetch faculties, departments, and education levels from your backend API
    // Update the state variables (faculties, departments, educationLevels) accordingly
    // For simplicity, I'm using placeholder data here

    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch majors
    if (formData.department) {
      axios
        .get(NEW_API_URL + "getmajo/" + formData.department, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setMajors(response.data?.data || []))
        .catch((error) => console.error(error));
    }
  }, [formData.faculty, formData.department]);

  useEffect(() => {
    if (onSelect) {
      const _formData = formData;
      if (_formData.building === "All") _formData.building = "";
      if (_formData.place === "All") _formData.place = "";
      if (_formData.room === "All") _formData.room = "";
      onSelect(_formData);
    }
  }, [formData]);

  return (
    <Paper elevation={0}>
      <Grid sx={{ textAlign: "left", width: "100%" }}>
        <Typography>{description}</Typography>
      </Grid>
      <Grid container pt={2} justifyContent="center" spacing={2}>
        <Grid item xs={8}>
          <FormControl fullWidth size="small" margin="normal">
            <TextField
              name="title"
              onChange={handleChange}
              type="text"
              variant="standard"
              placeholder="ຄົ້ນຫານັກສຶກສາ (ລະຫັດ, ຊື່, ນາມສະກຸນ, ເບິໂທລະສັບ)"
              InputProps={{
                style: { height: "33px" },
                startAdornment: <SearchIcon />,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl size="small" fullWidth>
            <input
              id="date"
              style={{ height: "41px", marginTop: "8px" }}
              name="date"
              className="form-control"
              type="date"
              defaultValue={formData.date}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth size="small" margin="normal">
            <InputLabel htmlFor="faculty">ຄະນະ</InputLabel>
            <Select
              disabled={
                userObject?.data?.role === "FAC_ADMIN" ||
                userObject?.data?.role === "DEP_ADMIN"
              }
              label="ຄະນະ"
              name="faculty"
              value={formData.faculty || ""}
              onChange={handleChange}
            >
              <MenuItem key="" value="">
                ທັງຫມົດ
              </MenuItem>
              {faculties.map((faculty) => (
                <MenuItem key={faculty._id} value={faculty._id}>
                  {faculty.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth size="small" margin="normal">
            <InputLabel htmlFor="department">ພາກ</InputLabel>
            <Select
              disabled={userObject?.data?.role === "DEP_ADMIN"}
              label="ພາກ"
              name="department"
              value={formData.department || ""}
              onChange={handleChange}
            >
              <MenuItem key="" value="">
                ທັງຫມົດ
              </MenuItem>
              {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth size="small" margin="normal">
            <InputLabel htmlFor="major">ສາຂາ</InputLabel>
            <Select
              label="ສາຂາ"
              name="major"
              value={formData.major || ""}
              onChange={handleChange}
            >
              <MenuItem key="" value="">
                ທັງຫມົດ
              </MenuItem>
              {majors.map((major) => (
                <MenuItem key={major._id} value={major._id}>
                  {major.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {(status === "APPROVED" || status === "PASSED") && (
          <>
            <Grid item xs={4}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel size="small">ເລືອກສະຖານທີ</InputLabel>
                <Select
                  size="small"
                  label="ເລືອກສະຖານທີ"
                  name="place"
                  value={formData?.place}
                  onChange={handleChange}
                >
                  <MenuItem value="All">ທັງຫມົດ</MenuItem>
                  {placeList.map((place) => (
                    <MenuItem key={place._id} value={place._id}>
                      {place.place}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth sx={{ mb: 2 }} disabled={!formData?.place}>
                <InputLabel size="small">ເລືອກຕຶກ</InputLabel>
                <Select
                  size="small"
                  label="ເລືອກຕຶກ"
                  name="building"
                  value={formData?.building}
                  onChange={handleChange}
                >
                  <MenuItem value="All">ທັງຫມົດ</MenuItem>
                  {buildingList.map((building) => (
                    <MenuItem key={building._id} value={building._id}>
                      {building.building}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth sx={{ mb: 2 }} disabled={!formData?.place}>
                <InputLabel size="small">ເລືອກຫ້ອງເສັງ</InputLabel>
                <Select
                  size="small"
                  label="ເລືອກຫ້ອງເສັງ"
                  name="room"
                  value={formData?.room}
                  onChange={handleChange}
                >
                  <MenuItem value="All">ທັງຫມົດ</MenuItem>
                  {classRoomList.map((room) => (
                    <MenuItem key={room._id} value={room._id}>
                      {room.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default EntranceStudentFilter;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  CircularProgress,
  Input,
  Link,
  Grid,
  Paper,
} from "@mui/material";
import { fetchPostFormData, fetchPutFormData } from "../../../common/fetchAPI";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const AddLessonModal = ({ open, onClose, onRefresh, courseId, lessonData }) => {
  const [lesson, setLesson] = useState(lessonData?.lesson || "");
  const [title, setTitle] = useState(lessonData?.title || "");
  const [description, setDescription] = useState(lessonData?.description || "");
  const [file, setFile] = useState(null);
  const [existingFileLink, setExistingFileLink] = useState(
    lessonData?.file || ""
  );
  const [fileLink, setFileLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (lessonData?.file) {
      setExistingFileLink(lessonData.file);
    }
  }, [lessonData]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileLink("");
      setExistingFileLink("");
    } else {
      setFile(null);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!lesson) newErrors.lesson = "Lesson number is required";
    if (!title) newErrors.title = "Title is required";
    if (!file && !existingFileLink && !fileLink) {
      newErrors.file = "File or File Link is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("lesson", lesson);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("tcourse", courseId);
      formData.append("fileLink", fileLink);

      if (file) {
        formData.append("file", file);
      }

      if (lessonData) {
        await fetchPutFormData("tlesson/" + lessonData._id, formData);
      } else {
        await fetchPostFormData("tlesson", formData);
      }
      onRefresh();
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "500px",
          maxWidth: "90%",
          margin: "auto",
          backgroundColor: "white",
          padding: 3,
          borderRadius: 2,
          boxShadow: 4,
          marginTop: "100px",
        }}
      >
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {lessonData ? "ເເກ້ໄຂບົດທີໃຫມ່" : "ເພີ່ມບົດທີໃຫມ່"}
        </Typography>
        <form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="ບົດທີ່"
                  variant="outlined"
                  type="number"
                  value={lesson}
                  onChange={(e) => setLesson(e.target.value)}
                  error={!!errors.lesson}
                  helperText={errors.lesson}
                  inputProps={{ min: 1 }} // Set minimum value to 1
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="ັຫົວຂໍ້ບົດ"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="ລາຍລະອຽດບົດ"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                {file ? (
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    ຊື່ໄຟຣທິເລືອກ: {file.name}
                  </Typography>
                ) : existingFileLink ? (
                  <Link href={existingFileLink} target="_blank" rel="noopener">
                    ໄຟຣທີ່ມີຢູ່: {existingFileLink.split("/").pop()}
                  </Link>
                ) : null}
                <Button
                  variant="contained"
                  component="label"
                  color={file ? "success" : "primary"}
                  startIcon={<CloudUploadIcon />}
                  sx={{ width: "100%", marginTop: 2, borderRadius: "10px" }}
                >
                  {file ? "ປ່ຽນເອກະສານ" : "ເພີ່ມເອກະສານ"}
                  <Input
                    type="file"
                    onChange={handleFileChange}
                    accept="application/pdf"
                    sx={{ display: "none" }}
                  />
                </Button>
                {errors.file && (
                  <Typography color="error" sx={{ marginTop: 1 }}>
                    {errors.file}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="ລິ້ງເອກະສານບົດສອນ"
                  variant="outlined"
                  value={fileLink}
                  onChange={(e) => setFileLink(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
              sx={{ borderRadius: "10px" }}
            >
              ຍົກເລີກ
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              sx={{ borderRadius: "10px" }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : lessonData ? (
                "ເເກ້ໄຂ"
              ) : (
                "ເພີ່ມ"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddLessonModal;

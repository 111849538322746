import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Select,
  Button,
  MenuItem,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  ListItemText,
  CircularProgress,
  Alert
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { fetchGet, fetchPut } from "../../../common/fetchAPI";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ReplayIcon from "@mui/icons-material/Replay";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import useReactRouter from "use-react-router";
import AlertMessage from "../../../common/AlertMessage";
import ConfirmModal from "../../../common/ConfirmDialog";
import { useSelector } from "react-redux";

function calculateScores(data) {
  // Initialize totals
  let totalScore = 0;
  let totalChoiceScore = 0;
  let totalTextScore = 0;
  let waitForScore = 0;

  // Iterate over each section
  data.forEach((section) => {
    // Iterate over each question in the section
    section.questions.forEach((question) => {
      totalScore += question.score;

      // Check if the question is multiple-choice or text-based
      if (question.type === "multiple-choice") {
        totalChoiceScore += question.score;
      } else if (question.type === "text-based") {
        totalTextScore += question.score;

        // Count text-based questions waiting for a score
        if (!question.score || question.score === 0) {
          waitForScore += 1;
        }
      }
    });
  });

  // Ensure no NaN values are returned
  return {
    totalScore: isNaN(totalScore) ? 0 : totalScore,
    totalChoiceScore: isNaN(totalChoiceScore) ? 0 : totalChoiceScore,
    totalTextScore: isNaN(totalTextScore) ? 0 : totalTextScore,
    waitForScore: isNaN(waitForScore) ? 0 : waitForScore,
  };
}

const HomeworkSubmit = () => {
  const [sections, setSections] = useState([]);
  const location = useLocation();
  const { id } = useParams();
  const { history } = useReactRouter();
  const [isApproved, setIsApproved] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setIsLoadinng] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const course = useSelector((state) => state.tcourse);

  const fetchExam = async () => {
    const _examData = await fetchGet("/thomework/student/" + id);
    setData(_examData);
    setIsApproved(_examData?.approved);
    setSections(_examData?.sections || []);
  };

  useEffect(() => {
    fetchExam();
  }, []);
  useEffect(() => {
    // Auto calculate the correctness of multiple-choice questions
    const updatedSections = sections.map((section) => ({
      ...section,
      questions: section.questions.map((question) => {
        if (question.type === "multiple-choice") {
          return {
            ...question,
            isCorrect: question.answer === question.correctOption,
          };
        }
        return question;
      }),
    }));
    setSections(updatedSections);
  }, []);

  const handleScoreChange = (sectionIndex, questionIndex, value) => {
    const updatedSections = sections.map((section, sIdx) => {
      if (sIdx === sectionIndex) {
        const updatedQuestions = section.questions.map((question, qIdx) => {
          if (qIdx === questionIndex) {
            return {
              ...question,
              score: value,
            };
          }
          return question;
        });
        return { ...section, questions: updatedQuestions };
      }
      return section;
    });

    setSections(updatedSections);
  };

  const onCancel = () => {
    history.push("/elearning/teacher/course/" + course.courseId);
  };

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "error",
  });

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  const handleSubmit = async () => {
    setShowConfirm(false);
    const data = { sections: sections, resultId: id };
    if (data) {
      setIsLoadinng(true);
      const response = await fetchPut("/thomework/teacher/submit/" + id, data);
      if (response) {
        setIsLoadinng(false);
        setActionStatus({
          open: true,
          message: location?.state ? "ເເກ້ໄຂສຳເລັດ" : "ເພີ່ມສຳເລັດ", // Success message
          type: "success",
        });
      } else {
        setActionStatus({
          open: true,
          message: "ລະບົບມີບັນຫາ", // Error message
          type: "error",
        });
      }
      setIsLoadinng(false);
    }
  };

  const [score, setScore] = useState({
    totalScore: 0,
    totalChoiceScore: 0,
    totalTextScore: 0,
    waitForScore: 0,
  });

  useEffect(() => {
    const _score = calculateScores(sections);
    setScore(_score);
  }, [sections]);

  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      <ConfirmModal
        message={"ຫລັງຈາກຢືນຢັນແລ້ວຈະບໍ່ສາມາດຢືນຢັນໄດ້ໃນຄັ້ງຕໍ່ໄປ!"}
        title={"ຢືນຢັນການໃຫ້ຄະເເນນ"}
        open={showConfirm}
        onSubmit={handleSubmit}
        onCancel={() => setShowConfirm(false)}
      />
      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />
      <Box
        sx={{
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
          padding: 2,
          marginBottom: 1,
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center", // Center items along the y-axis
          justifyContent: "flex-start", // Align items to the left
        }}
      >
        <Button
          startIcon={<CancelIcon />}
          onClick={onCancel}
          variant="contained"
          color="error"
          sx={{
            borderRadius: 3,
            mr: 2,
            px: 3,
          }} // Add marginRight here
        >
          ຍົກເລີກ
        </Button>
        <Button
          startIcon={<SaveIcon />}
          sx={{
            borderRadius: 3,
            mr: 2,
            px: 3,
          }}
          disabled={isApproved}
          onClick={() => setShowConfirm(true)}
          variant="contained"
          color="primary"
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : location?.state ? (
            "ຢືນຢັນ"
          ) : (
            "ຢືນຢັນ"
          )}
        </Button>
        <IconButton onClick={fetchExam} color="primary">
          <ReplayIcon />
        </IconButton>
        {isApproved && (
          <Alert
            severity="info"
            sx={{
              fontWeight: "bold",
              backgroundColor: "#e3f2fd", // Light blue background
              color: "#0d47a1", // Dark blue text color for contrast
              borderRadius: 2,
              marginLeft: 2, // Add some space between the alert and buttons
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              ບົດເສັງຂອງນັກສຶກສາຄົນນີ້ຖືກໃຫ້ຄະເເນນແລ້ວ
            </Typography>
          </Alert>
        )}
      </Box>

      {data && (
        <Box
          sx={{
            mb: 2,
            backgroundColor: "#fff",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f5faff",
              borderRadius: "16px 16px 0 0",
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                fontWeight: "bold",
                color: "#1a3d7c",
                textAlign: "left",
                py: 1,
              }}
            >
              ຂໍ້ມູນນັກສຶກສາ
            </Typography>
          </Box>
          <Box sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
                >
                  {data?.exam?.title}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>ຊື່ ແລະ ນາມສະກຸນ:</strong> {data.student.firstNameL}{" "}
                  {data?.student?.lastNameL} ({data.student.firstNameE}{" "}
                  {data?.student?.lastNameE})
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>ລະຫັດນັກສຶກສາ:</strong>{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      fontWeight:
                        data.student.studentNumber.length < 5
                          ? "bold"
                          : "medium",
                      fontSize:
                        data.student.studentNumber.length < 5
                          ? "1.15rem"
                          : "1rem",
                      color:
                        data.student.studentNumber.length < 5
                          ? "primary.main"
                          : "#555",
                    }}
                  >
                    {data.student.studentNumber}
                  </Typography>
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>ຫ້ອງຮຽນ:</strong>{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      fontWeight:
                        data.classRoom.name.length < 5 ? "bold" : "medium",
                      fontSize:
                        data.classRoom.name.length < 5 ? "1.15rem" : "1rem",
                      color:
                        data.classRoom.name.length < 5
                          ? "primary.main"
                          : "#555",
                    }}
                  >
                    {data.classRoom.name}
                  </Typography>
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>ປີຮຽນ:</strong>{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#333",
                    }}
                  >
                    {data.student.yearLevel}
                  </Typography>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>ຄະແນນລວມ:</strong>{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      color: "#0ead69",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {score.totalScore}
                  </Typography>
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>ປາລະໄນ:</strong>{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      color: "#0ead69",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {score.totalChoiceScore}
                  </Typography>
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>ອັດຕະໄນ:</strong>{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      color: "#0ead69",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {score.totalTextScore}
                  </Typography>
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>ຈຳນວນຄຳຕອບທີ່ລໍຖ້າການໃຫ້ຄະແນນ:</strong>{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      color: "#e63946",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {score.waitForScore}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          mb: 1,
          backgroundColor: "#fff",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            marginBottom: "15px",
            backgroundColor: "#f5faff",
            borderRadius: "3px 3px 0 0",
            padding: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginLeft: "15px",
              fontWeight: "bold",
              color: "#1a3d7c",
              textAlign: "left", // Align text to the left
              paddingY: 1,
            }}
          >
            ລາຍການຄຳຖາມ - ຄຳຕອບຂອງນັກສຶກສາ
          </Typography>
        </Box>
        {sections.length > 0 &&
          sections.map((section, sectionIndex) => {
            // Calculate the total scores for the section with NaN handling
            const totalSectionScore = section.questions.reduce(
              (acc, q) =>
                acc + (isNaN(q.score) || q.score === null ? 0 : q.score),
              0
            );
            const totalChoiceScore = section.questions
              .filter((q) => q.type === "multiple-choice")
              .reduce(
                (acc, q) =>
                  acc + (isNaN(q.score) || q.score === null ? 0 : q.score),
                0
              );
            const totalTextScore = section.questions
              .filter((q) => q.type === "text-based")
              .reduce(
                (acc, q) =>
                  acc + (isNaN(q.score) || q.score === null ? 0 : q.score),
                0
              );

            // If totalTextScore is NaN, only use the total of multiple-choice questions
            const finalTotalScore = isNaN(totalTextScore)
              ? totalChoiceScore
              : totalSectionScore;

            const waitForScore = section.questions.filter(
              (q) => q.type === "text-based" && (!q.score || q.score === 0)
            ).length;

            return (
              <Accordion key={section.index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}
                >
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        ພາກທີ {" " + section.index + " : "}{" "}
                        {section.sectionTitle}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography mr={3} variant="body1">
                            <strong>ຄະເເນນລວມ:</strong>{" "}
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{
                                color: "#0ead69",
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                              }}
                            >
                              {isNaN(finalTotalScore) ? 0 : finalTotalScore}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography mr={3} variant="body1">
                            <strong>ປາລະໄນ:</strong>{" "}
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{
                                color: "#0ead69",
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                              }}
                            >
                              {isNaN(totalChoiceScore) ? 0 : totalChoiceScore}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography mr={3} variant="body1">
                            <strong>ອັດຕະໄນ:</strong>{" "}
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{
                                color: "#0ead69",
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                              }}
                            >
                              {isNaN(totalTextScore) ? 0 : totalTextScore}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography mr={3} variant="body1">
                            <strong>ຖ້າໃຫ້ຄະເເນນ:</strong>{" "}
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{
                                color: "#0ead69",
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                              }}
                            >
                              {isNaN(waitForScore) ? 0 : waitForScore}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {section.questions.map((question, questionIndex) => (
                    <Box
                      key={question.index}
                      sx={{
                        mb: 2,
                        borderRadius: 2,
                      }}
                    >
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              backgroundColor: "#f5faff",
                              paddingLeft: 3,
                              paddingRight: 3,
                              paddingTop: 3,
                            }}
                          >
                            ຄຳຖາມທີ {question.index}: {question.text}
                          </Typography>
                        </Grid>
                        {question.isCorrect !== undefined && (
                          <Grid item>
                            {question.isCorrect ? (
                              <CheckCircleIcon sx={{ color: "success.main" }} />
                            ) : (
                              <CloseIcon sx={{ color: "error.main" }} />
                            )}
                          </Grid>
                        )}
                      </Grid>

                      {question.type === "text-based" ? (
                        <Box
                          sx={{
                            backgroundColor: "#f5faff",
                            paddingLeft: 3,
                            paddingRight: 3,
                            paddingBottom: 3,
                          }}
                        >
                          <Typography variant="body2">
                            <strong>ຄຳຕອບນັກສຶກສາ:</strong>{" "}
                            <Typography
                              component="span"
                              sx={{
                                fontWeight:
                                  question.answer.length < 5
                                    ? "bold"
                                    : "normal",
                                fontSize:
                                  question.answer.length < 5
                                    ? "1.2rem"
                                    : "inherit",
                                color:
                                  question.answer.length < 5
                                    ? "primary.main"
                                    : "inherit",
                              }}
                            >
                              {question.answer}
                            </Typography>
                          </Typography>
                          <FormControl fullWidth margin="normal" sx={{ mt: 2 }}>
                            <InputLabel>ຄະເເນນ</InputLabel>
                            <Select
                              value={question.score || ""}
                              label="ຄະເເນນ"
                              onChange={(e) =>
                                handleScoreChange(
                                  sectionIndex,
                                  questionIndex,
                                  e.target.value
                                )
                              }
                              sx={{
                                backgroundColor: "#ffffff",
                                borderRadius: 2,
                                fontWeight: "bold",
                              }}
                            >
                              {[...Array(question.maxScore + 1).keys()].map(
                                (score) => (
                                  <MenuItem key={score} value={score}>
                                    {score}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            paddingLeft: 3,
                            paddingRight: 3,
                            paddingBottom: 3,
                            backgroundColor: "#f5faff",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              <strong>ຄະເເນນ:</strong>{" "}
                              <Typography
                                component="span"
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "1.1rem",
                                  color: "primary.main",
                                }}
                              >
                                {question.score}
                              </Typography>{" "}
                              / {question.maxScore}
                            </Typography>
                          </Box>

                          <Divider sx={{ my: 2 }} />
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, mb: 1 }}
                          >
                            ລາຍການຕົວເລືອກ:
                          </Typography>
                          <List>
                            {Object.entries(question.options).map(
                              ([key, value]) => (
                                <ListItem key={key} disablePadding>
                                  <ListItemIcon>
                                    {key === question.correctOption ? (
                                      <CheckCircleIcon
                                        sx={{ color: "success.main" }}
                                      />
                                    ) : (
                                      <RadioButtonUncheckedIcon color="action" />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={`${key}: ${value}`}
                                    primaryTypographyProps={{
                                      color:
                                        key === question.correctOption
                                          ? "success.main"
                                          : "textPrimary",
                                      fontWeight:
                                        key === question.correctOption
                                          ? 600
                                          : 400,
                                      fontSize: "1rem",
                                    }}
                                  />
                                  {key === question.answer && (
                                    <Typography
                                      variant="body2"
                                      sx={{ color: "primary.main", ml: 1 }}
                                    >
                                      (ຄຳຕອບນັກສຶກສາ)
                                    </Typography>
                                  )}
                                </ListItem>
                              )
                            )}
                          </List>
                        </Box>
                      )}
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Box>
    </Box>
  );
};

export default HomeworkSubmit;

import React, { useEffect, useState } from "react";
import { Typography, Box, Paper, IconButton, Modal } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import EmptyCmomponent from "../../../../common/EmptyScreen";

// Custom tooltip component for displaying student details
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { range, students } = payload[0].payload;

    return (
      <Paper sx={{ p: 2 }}>
        <Typography variant="subtitle1">ຊ່ວງ: {range}</Typography>
        <Typography variant="body2">ນັກຮຽນ:</Typography>
        <ul style={{ margin: 0, paddingLeft: 16 }}>
          {students.map((student) => (
            <li key={student.studentId}>
              {student.firstName} {student.lastName} - ເວລາ:{" "}
              {student.timeToTake} ນາທີ
            </li>
          ))}
        </ul>
      </Paper>
    );
  }

  return null;
};

const AverageTimeToTakeGraph = ({ averageTimeToTake }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (averageTimeToTake) {
      // Transform the data into an array for Recharts
      const data = Object.entries(averageTimeToTake).map(
        ([range, { count, students }]) => ({
          range,
          count,
          students,
        })
      );
      setData(data);
    }
  }, [averageTimeToTake]);

  const renderChart = () => (
    <Box sx={{ minWidth: "700px" }}>
      <ResponsiveContainer width="100%" height={openModal ? 500 : 400}>
        <BarChart data={data}>
          <XAxis
            dataKey="range"
            label={{
              value: "ຊ່ວງເວລາ (ນາທີ)",
              position: "insideBottom",
              offset: -5,
            }}
          />
          <YAxis
            label={{
              value: "ຈຳນວນນັກຮຽນ",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="count" fill="#42a5f5" name="ຈຳນວນນັກຮຽນ" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundColor: "white",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        p: 3,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          ເວລາສະເລ່ຍໃນການເຮັດງານ
        </Typography>
        <IconButton onClick={handleOpenModal}>
          <FullscreenIcon />
        </IconButton>
      </Box>
      <Paper elevation={3} sx={{ p: 2, overflowX: "auto", boxShadow: "none" }}>
        {data.length > 0 ? renderChart() : <EmptyCmomponent height={400} />}
      </Paper>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper
          sx={{
            width: "80%",
            maxHeight: openModal ? 800 : 400,
            height: "80%",
            overflowY: "auto",
            backgroundColor: "white",
            padding: 2,
            borderRadius: 2,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          {data.length > 0 ? renderChart() : <EmptyCmomponent height={400} />}
        </Paper>
      </Modal>
    </Box>
  );
};

export default AverageTimeToTakeGraph;

import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import StudentList from "./studentList";

const StudentGraduatedPage = () => {
  const [activeTab, setActiveTab] = useState(0); // State to track active tab

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", mt: 3 }}>
      <StudentList />
      {/* Tabs */}
      {/* <Tabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        centered
      >
        <Tab label="ລາຍການານັກສຶກສາ" />
        <Tab label="ນັກສຶກສາສຳເລັດການສຶກສາ" />
      </Tabs> */}

      {/* Tab Panels */}
      {/* <Box sx={{ mt: 3 }}>
        {activeTab === 0 && <StudentList />}
        {activeTab === 1 && (
          <Typography variant="h6">Manage Content Goes Here</Typography>
        )} 
      </Box>
      */}
    </Box>
  );
};

export default StudentGraduatedPage;

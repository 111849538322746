import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchDelete, fetchGet } from "../../common/fetchAPI";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Paper,
  Avatar,
  Tooltip,
  Modal,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import StatusStepper from "./StudentStatus";
import { convertGender } from "../../helpers/user";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../common/ConfirmDialog";
import useReactRouter from "use-react-router";
import AlertMessage from "../../common/AlertMessage";
import { set } from "lodash";
import { Breadcrumb } from "react-bootstrap";

const EntranceStudentDetail = () => {
  const { history } = useReactRouter();
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });

  const handleShowDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleDelete = async () => {
    const response = await fetchDelete(`entrance/student/${id}`);
    if (response?.success) {
      // Redirect to the previous page
      setActionStatus({
        open: true,
        message: "ລຶບຂໍ້ມູນນັກສຶກສາສຳເລັດ",
        type: "success",
      });

      setTimeout(() => {
        history.push("/entrance");
      }, 2000);
    } else {
      setActionStatus({
        open: true,
        message: "ລຶບຂໍ້ມູນນັກສຶກສາບໍ່ສຳເລັດ",
        type: "error",
      });
    }
    setOpenDeleteModal(false);
  };
  console.log("student::::", student)
  const fetchStudent = async () => {
    const response = await fetchGet(`/entrance/student/${id}`);
    if (response?.success) {
      setStudent(response.student);
    }
  };

  const handleOpenModal = (imageUrl) => {
    setModalImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setRotation(0);
    setZoom(1);
  };

  const rotateImage = () => {
    setRotation((prev) => prev + 90);
  };

  const zoomIn = () => {
    setZoom((prev) => prev + 0.1);
  };

  const zoomOut = () => {
    setZoom((prev) => (prev > 0.1 ? prev - 0.1 : prev));
  };

  useEffect(() => {
    fetchStudent();
  }, []);

  const getLabelFromKey = (key) => {
    switch (key) {
      case "Village":
        return "ບ້ານ";
      case "District":
        return "ເມືອງ";
      case "Province":
        return "ແຂວງ";
      default:
        return key;
    }
  };

  if (!student) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        f
        alignItems="center"
        height="100vh"
        sx={{
          backgroundColor: "#fff",
        }}
        flexDirection="column"
      >
        <CircularProgress color="primary" size={40} /> {/* Corrected size */}
        <Typography variant="h6" mt={2}>
          ກຳລັງໂຫລດ...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() =>
            history.push("/entrance")
          }
        >
          Entrance
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍລະອຽດຂໍ້ມູນນັກສຶກສາ</Breadcrumb.Item>
      </Breadcrumb>
      <Box padding={3}>
        <Paper
          sx={{
            overflow: "hidden",
            position: "relative", // Required for absolute positioning
          }}
        >
          {/* Top-Right Button */}
          <Box
            sx={{
              position: "absolute",
              top: 16, // Adjust as needed
              right: 16, // Adjust as needed
              zIndex: 1, // Ensure it appears above content
            }}
          >
            <IconButton onClick={handleShowDeleteModal} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
          <ConfirmModal
            open={openDeleteModal}
            onCancel={() => setOpenDeleteModal(false)}
            onSubmit={handleDelete}
            title="ລຶບຂໍ້ມູນນັກສຶກສາ"
            message="ທ່ານແມ່ນຕ້ອງການລຶບຂໍ້ມູນນັກສຶກສານີ້ບໍ?"
          />
          <AlertMessage
            open={actionStatus.open}
            message={actionStatus.message}
            type={actionStatus.type}
            handleClose={() =>
              setActionStatus({ open: false, message: "", type: "" })
            }
          />
          {/* Main Content */}
          <Grid container spacing={0}>
            {/* Left Panel */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                backgroundColor: "#f5f5f5",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 3,
              }}
            >
              <Avatar
                onClick={() => handleOpenModal(student?.profileUrl)}
                sx={{
                  width: 100,
                  height: 100,
                  marginBottom: 2,
                  border: "4px solid white",
                }}
                src={student.profileUrl}
              >
                {student.firstNameL[0]}
                {student.lastNameL[0]}
              </Avatar>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#333", textAlign: "center" }}
              >
                {student.firstNameL} {student.lastNameL}
              </Typography>
            </Grid>

            {/* Right Panel */}
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                padding: 3,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>ລະຫັດຟອມນັກສຶກສາ:</b> {student.code}
                  </Typography>
                </Grid>
                {student.examCode && (
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <b>ເລກເຂົ້າຫ້ອງເສັງນັກສຶກສາ:</b> {student.examCode}
                    </Typography>
                  </Grid>
                )}
                {student?.place && (
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <b>ສະຖານທິເສັງ:</b> {student?.place?.place}
                    </Typography>
                  </Grid>
                )}
                {student?.building && (
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <b>ຊື່ອາຄານ:</b> {student?.building?.building}
                    </Typography>
                  </Grid>
                )}
                {student?.room && (
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <b>ຫ້ອງເສັງ:</b> {student?.room?.name}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>ເພດ:</b> {convertGender(student.gender)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>ສະຖານະ:</b>{" "}
                    {student?.maritualStatus === "SINGLE" ? "ໂສດ" : "ແຕ່ງງານແລ້ວ"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>ວັນເດືອນປີເກີດ:</b>{" "}
                    {new Date(student.birthday).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>ສະຖານະສຸຂະພາບ:</b>{" "}
                    {student.healthStatus === "GOOD" ? "ສົມບູນ" : "ພິການ"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>ຈົບຈາກແຂວງ:</b> {student.graduateFromProvince}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>ຊົນເຜົ່າ:</b> {student.tribe.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>ຈົບຈາກໂຮງຮຽນ:</b> {student.graduateFromSchool}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {/* Status */}
        <StatusStepper onRefresh={fetchStudent} student={student} />

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">ຂໍ້ມຸນທີ່ຢູ່</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1">
              <b>ທີ່ຢູ່ປັດຈຸບັນ:</b>
            </Typography>
            <Grid container spacing={2}>
              {["village", "district", "province"].map((field) => (
                <Grid item xs={12} sm={4} key={field}>
                  <Typography>
                    <b>
                      {getLabelFromKey(field.charAt(0).toUpperCase() + field.slice(1))} :
                    </b>{" "}
                    {student.address[field]}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Typography variant="subtitle1" mt={2}>
              <b>ສະຖານທີເກີດ:</b>
            </Typography>
            <Grid container spacing={2}>
              {["village", "district", "province"].map((field) => (
                <Grid item xs={12} sm={4} key={field}>
                  <Typography>
                    <b>
                      {getLabelFromKey(field.charAt(0).toUpperCase() + field.slice(1))} :
                    </b>{" "}
                    {student.birthAddress[field]}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Typography variant="subtitle1" mt={2}>
              <b>ທີ່ຢູ່ພໍ່ແມ່:</b>
            </Typography>
            <Grid container spacing={2}>
              {["village", "district", "province"].map((field) => (
                <Grid item xs={12} sm={4} key={field}>
                  <Typography>
                    <b>
                      {getLabelFromKey(field.charAt(0).toUpperCase() + field.slice(1))} :
                    </b>{" "}
                    {student.parentAddress[field]}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Contact Information */}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">ຂໍ້ມຸນຕິດຕໍ່</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Tooltip title="ອີເມວ">
                  <Box display="flex" alignItems="center">
                    <b>ອີເມວ:</b> {student?.email}
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Tooltip title="ເບີໂທລະສັບ">
                  <Box display="flex" alignItems="center">
                    <b>ເບີໂທລະສັບ:</b> {student.phoneMobile}
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>ເບີຕິດຕໍ່ສຸກເສີນ:</b> {student.emergencyPhone}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>ເບີຜູ້ປົກຄອງ:</b> {student.parentTelephone}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>
                  <b>ເບີໂທລະສັບເຮືອນ:</b> {student.phoneHome}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Major Information */}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">ສາຍທີ່ເລືອກຮຽນ</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Main Major */}
            <Typography variant="subtitle1" gutterBottom>
              <b>ສາຍຫລັກ:</b>
            </Typography>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} sm={4} textAlign="left">
                <Typography variant="body1">
                  <b>ສາຂາ:</b> {student?.major?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} textAlign="left">
                <Typography variant="body1">
                  <b>ພາກ:</b> {student?.department?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} textAlign="left">
                <Typography variant="body1">
                  <b>ຄະນະ:</b> {student?.faculty?.name}
                </Typography>
              </Grid>
            </Grid>

            {/* Secondary Major */}
            <Typography variant="subtitle1" gutterBottom mt={4}>
              <b>ສາຍສຳຮອງ:</b>
            </Typography>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={4} textAlign="left">
                <Typography variant="body1">
                  <b>ສາຂາ:</b> {student?.major2?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} textAlign="left">
                <Typography variant="body1">
                  <b>ພາກ:</b> {student?.department2?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} textAlign="left">
                <Typography variant="body1">
                  <b>ຄະນະ:</b> {student?.faculty2?.name}
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom mt={4}>
              <b>ສາຍສຳຮອງ:</b>
            </Typography>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} sm={4} textAlign="left">
                <Typography variant="body1">
                  <b>ສາຂາ:</b> {student?.major3?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} textAlign="left">
                <Typography variant="body1">
                  <b>ພາກ:</b> {student?.department3?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} textAlign="left">
                <Typography variant="body1">
                  <b>ຄະນະ:</b> {student?.faculty3?.name}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Documents */}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">ເອກະສານຕິດຄັດ</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {[
                {
                  label: "ສຳມະໂນຄົວ",
                  url: student.idCardOrFamilyBookUrl?.url,
                },
                { label: "ໃບປະກາດ", url: student?.certificateUrl?.url },
                { label: "ບັດປະຈຳຕົວ", url: student?.idCardUrl?.url },
                { label: "ໃບຄະເເນນ", url: student?.scorecardUrl?.url },
              ].map((doc, index) => (
                <Grid
                  style={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                  item
                  xs={3}
                  sm={3}
                  key={index}
                >
                  <img
                    src={doc.url}
                    alt={doc.label}
                    style={{
                      cursor: "pointer",
                      width: "70%",
                      height: "70%",
                      borderRadius: 8,
                      boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                    }}
                    onClick={() => handleOpenModal(doc.url)}
                  />
                  <Typography textAlign="center" variant="body2" mt={1}>
                    {doc.label}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/* Modal for Image View */}
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            bgcolor="rgba(0, 0, 0, 0.8)"
          >
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: "absolute", top: 16, right: 16, color: "white" }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={modalImage}
              alt="Document Preview"
              style={{
                transform: `rotate(${rotation}deg) scale(${zoom})`,
                maxHeight: "80%",
                maxWidth: "80%",
                transition: "transform 0.3s ease",
              }}
            />
            <Box display="flex" mt={2}>
              <IconButton onClick={rotateImage} sx={{ color: "white" }}>
                <RotateRightIcon />
              </IconButton>
              <IconButton onClick={zoomIn} sx={{ color: "white" }}>
                <ZoomInIcon />
              </IconButton>
              <IconButton onClick={zoomOut} sx={{ color: "white" }}>
                <ZoomOutIcon />
              </IconButton>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default EntranceStudentDetail;

import { configureStore } from "@reduxjs/toolkit";
import accessmentReducer from "./accessmentSlice";
import filterReducer from "./filtrSlice";
import lessonReducer from "./lessonSlice";
import courseReducer from "./tCourseSlice";

// Middleware to save state to local storage
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("tcourse", serializedState);
  } catch (error) {
    console.error("Failed to save state to local storage:", error);
  }
};

// Middleware to load state from local storage
const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("tcourse");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error("Failed to load state from local storage:", error);
    return undefined;
  }
};

// Preloaded state
const preloadedState = {
  tcourse: loadFromLocalStorage(),
};

// Configure the store
const store = configureStore({
  reducer: {
    accessment: accessmentReducer,
    filter: filterReducer,
    lesson: lessonReducer,
    tcourse: courseReducer,
  },
  preloadedState,
});

// Subscribe to store changes to save the tcourse state to local storage
store.subscribe(() => {
  saveToLocalStorage(store.getState().tcourse);
});

export default store;

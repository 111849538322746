import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { fetchGet } from "../../common/fetchAPI";
import LoadingDialog from "../../common/LoadingDialog";

const modernColors = ["#6C63FF", "#4CAF50", "#FF9800", "#F44336", "#9C27B0"];

const CustomTooltip = ({ active, payload, label, type }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    if (type === "grade") {
      return (
        <Box sx={{ backgroundColor: "#fff", padding: 2, boxShadow: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            ເກຣດ : {data.name}
          </Typography>
          <Divider sx={{ marginY: 1 }} />
          <Typography>ນັກສຶກສາທັງຫມົດ: {data.total}</Typography>
          {Object.entries(data.gradeCounts || {}).map(([key, value]) => (
            <Typography key={key}>
              {key}: {value}
            </Typography>
          ))}
        </Box>
      );
    }

    if (type === "student") {
      return (
        <Box sx={{ backgroundColor: "#fff", padding: 2, boxShadow: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            ຄະນະ: {data.facultyName}
          </Typography>
          <Typography>ນັກສຶກສາທັງຫມົດ: {data.total}</Typography>
          <Typography>ຊາຍ: {data.male}</Typography>
          <Typography>ຍິງ: {data.female}</Typography>
          <Divider sx={{ marginY: 1 }} />
          <Typography variant="subtitle2">ປີຮຽນ:</Typography>
          {Object.entries(data.years || {}).map(([year, count]) => (
            <Typography key={year}>
              ປີຮຽນ {year}: {count}
            </Typography>
          ))}
        </Box>
      );
    }

    if (type === "user") {
      return (
        <Box sx={{ backgroundColor: "#fff", padding: 2, boxShadow: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            ຄະນະ: {data.facultyName}
          </Typography>
          <Typography>ລວມທັງຫມົດ: {data.total}</Typography>
          <Divider sx={{ marginY: 1 }} />
          {Object.entries(data.roles || {}).map(([role, count]) => (
            <Typography key={role}>
              {role}: {count}
            </Typography>
          ))}
        </Box>
      );
    }
  }
  return null;
};

const Dashboard = () => {
  const [data, setData] = useState();

  const [isloading, setIsLoading] = useState(false);

  const fetcDashboardData = async () => {
    setIsLoading(true);
    const response = await fetchGet("/dashboard");
    if (response) {
      setData(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetcDashboardData();
  }, []);

  return (
    <>
      {isloading ? (
        <LoadingDialog open={isloading} />
      ) : (
        <>
          {data ? (
            <Box sx={{ padding: 4, backgroundColor: "#f5f7fa" }}>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
                ລາຍງານ
              </Typography>
              <Grid
                container
                sx={{
                  backgroundColor: "#f5f7fa",
                }}
                spacing={4}
              >
                <Grid item xs={12}>
                  <GradeReport gradeReport={data.gradeReport} />
                </Grid>
                <Grid item xs={12}>
                  <StudentReport studentReport={data.studentReport} />
                </Grid>
                <Grid item xs={12}>
                  <UserReport userReport={data.userReport} />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Typography variant="h6" align="center" sx={{ marginTop: 4 }}>
              No data available
            </Typography>
          )}
        </>
      )}
    </>
  );
};

const GradeReport = ({ gradeReport }) => {
  return (
    <div>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", mb: 2 }}>
        ລາຍງານຄະເເນນ
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          gap: 2,
          paddingBottom: 2,
        }}
      >
        {gradeReport.map((item) => (
          <Paper
            key={item._id}
            elevation={2}
            sx={{
              padding: 2,
              minWidth: 300,
              flex: "0 0 auto",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {item.faculty.name}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {item.faculty.description}
            </Typography>
            <Divider sx={{ marginY: 1 }} />
            <PieChart width={200} height={200}>
              <Pie
                dataKey="value"
                data={Object.entries(item.gradeCounts).map(([key, value]) => ({
                  name: key,
                  value,
                  total: item.total,
                  ...item.gradeCounts,
                }))}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {Object.entries(item.gradeCounts).map(([key], index) => (
                  <Cell
                    key={key}
                    fill={modernColors[index % modernColors.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip type="grade" />} />
            </PieChart>
          </Paper>
        ))}
      </Box>
    </div>
  );
};

const StudentReport = ({ studentReport }) => {
  return (
    <div>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", mb: 2 }}>
        ລາຍງານນັກສຶກສາ
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          gap: 2,
          paddingBottom: 2,
        }}
      >
        {studentReport.map((item) => (
          <Paper
            key={item._id}
            elevation={1}
            sx={{
              padding: 2,
              minWidth: 300,
              flex: "0 0 auto",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {item.faculty.name}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {item.faculty.description}
            </Typography>
            <Divider sx={{ marginY: 1 }} />
            <BarChart
              width={300}
              height={200}
              data={Object.entries(item.years).map(([year, count]) => ({
                year,
                count,
                facultyName: item.faculty.name,
                ...item.gender,
              }))}
            >
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip content={<CustomTooltip type="student" />} />
              <Legend />
              <Bar dataKey="count" fill="#4CAF50" />
            </BarChart>
          </Paper>
        ))}
      </Box>
    </div>
  );
};

const COLORS = [
  "#FF5733", // Vibrant Red
  "#33FF57", // Bright Green
  "#3357FF", // Bright Blue
  "#F4D03F", // Yellow
  "#C70039", // Dark Red
  "#1ABC9C", // Teal
  "#8E44AD", // Purple
  "#E67E22", // Orange
  "#3498DB", // Sky Blue
  "#9B59B6", // Lavender
  "#F1C40F", // Gold
  "#34495E", // Navy Blue
  "#2ECC71", // Lime Green
  "#E74C3C", // Salmon
];

const UserReport = ({ userReport }) => {
  if (!userReport) {
    return <CircularProgress />;
  }

  const { overall, facultiesGrouped } = userReport;

  const rolesData = Object.entries(overall.roles).map(([key, value]) => ({
    name: key,
    value,
  }));

  const genderData = [
    { name: "Male", value: overall.gender.male },
    { name: "Female", value: overall.gender.female },
  ];

  const facultiesData = facultiesGrouped.map((faculty) => ({
    name: faculty.faculty.name,
    value: faculty.gender.total,
  }));

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", mb: 2 }}>
        ລາຍງານຂໍ້ມຸນອາຈານ ແລະ ພະນັກງານ
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={4}>
        {/* Roles Bar Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              ເເຍກຕາມລະດັບ
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={rolesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Gender Pie Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              ເເຍກຕາມເພດ
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={genderData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  label
                >
                  {genderData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Faculties Pie Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              ເເຍກຕາມຄະນະ
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={facultiesData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  label
                >
                  {facultiesData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;

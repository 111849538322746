import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  ListItemIcon,
  Modal,
  Button,
  CircularProgress,
} from "@mui/material";
import { Edit, Delete, MoreVert, ChevronRight } from "@mui/icons-material";
import AddLessonModal from "./AddLessonMoal";
import { useParams } from "react-router-dom";
import { fetchDelete, fetchGet, fetchPut } from "../../../common/fetchAPI";
import { useSelector } from "react-redux";
import ManageLessonAccess from "./ManageLessonAccess";
import AlertMessage from "../../../common/AlertMessage";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const modalStyle = (theme) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)", // Softer shadow
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2, // More rounded corners
  outline: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
});

const buttonContainerStyle = {
  marginTop: 3,
  display: "flex",
  justifyContent: "center",
  gap: 2,
};

const Lesson = ({ onChapterClick, onQuestionClick, lesson, onRefresh }) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openAccess, setOpenAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams();
  const lessonId = useSelector((state) => state.lesson);
  const [clickType, setClickType] = useState("");
  const [classRoomList, setClassRoomList] = useState([]);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const fetchClassrooms = async () => {
    const _classList = await fetchGet("/tcourse/class/" + id);
    setClassRoomList(_classList);
  };

  useEffect(() => {
    fetchClassrooms();
  }, []);

  const handleMenuClick = (event) => {
    event.stopPropagation(); // Prevent menu click from triggering collapse
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = () => {
    setOpenUpdate(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
    handleMenuClose();
  };

  const handleOpenManageAccess = () => {
    setOpenAccess(true);
    handleMenuClose();
  };

  const confirmDelete = async () => {
    setLoading(true);
    setError("");
    try {
      await fetchDelete("tlesson/" + lesson._id);
      onRefresh();
      setOpenDeleteDialog(false);
    } catch (err) {
      setError("ມີບາງຢ່າງຜິດພາດ ການລົບບໍ່ສຳເລັດ.");
    } finally {
      setLoading(false);
    }
  };

  const handleBoxClick = () => {
    setExpanded(!expanded);
  };

  const manageAccess = async (classList) => {
    const response = await fetchPut("/tlesson/class/" + lesson._id, classList);
    if (response) {
      setActionStatus({
        message: "ການດຳເນີນການສຳເລັດ",
        open: true,
        type: "success",
      });
    } else {
      setActionStatus({
        message: "ລະບົບມີບັນຫາໃນການດຳເນີນການ",
        open: true,
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus((predata) => ({
          ...predata,
          open: false,
        }));
      }, 3000);
    }
  }, [actionStatus.open]);

  const handleListItemClick = (event, clickHandler) => {
    if (clickHandler === onChapterClick) {
      setClickType("chapter");
    } else if (clickHandler === onQuestionClick) {
      setClickType("question");
    }
    event.stopPropagation(); // Prevent child click from triggering collapse
    clickHandler();
  };

  return (
    <Box
      sx={{
        m: 0,
        p: 0,
        backgroundColor: "white",
        border: "1px solid #ddd",
        borderRadius: "8px", // Modern rounded edges
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow
        cursor: "pointer",
        transition: "background-color 0.3s ease", // Smooth hover effect
        "&:hover": {
          backgroundColor: "#f9f9f9",
        },
      }}
      onClick={handleBoxClick}
      tabIndex={0}
    >
      <AlertMessage
        open={actionStatus.open}
        type={actionStatus.type}
        message={actionStatus.message}
      />
      <ManageLessonAccess
        existedClassList={lesson?.classRoom || []}
        data={classRoomList}
        onSubmit={async (classList) => {
          setOpenAccess(false);
          await manageAccess(classList);
        }}
        open={openAccess}
        onClose={() => setOpenAccess(false)}
      />
      {/* Modal for updating the lesson */}
      <AddLessonModal
        onRefresh={onRefresh}
        courseId={id}
        lessonData={lesson}
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
      />

      <Modal open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <Box sx={(theme) => modalStyle(theme)}>
          <Typography
            variant="h6"
            id="modal-title"
            gutterBottom
            sx={{ fontWeight: 600 }} // Make title bold
          >
            {"ທ່ານຕ້ອງການລົບແທ້ບໍ່​"}
          </Typography>
          <Typography
            variant="body2"
            id="modal-description"
            color="textSecondary"
            mb={3}
            sx={{ fontSize: "1rem" }} // Modern, readable font size
          >
            {"ຂໍມຸນຕ່າງໆຢູ່ໃນບົດຈະຖືກລົບໄປນຳ"}
          </Typography>
          <Box sx={buttonContainerStyle}>
            <Button
              sx={{
                px: 4,
                py: 1.5,
                borderRadius: 4, // Rounded button
                boxShadow: "0px 4px 12px rgba(255, 0, 0, 0.2)", // Soft shadow for action button
              }}
              onClick={confirmDelete}
              variant="contained"
              color="error"
            >
              {loading ? <CircularProgress size={20} /> : "ຢືນຢັນ"}
            </Button>
            <Button
              sx={{
                px: 4,
                py: 1.5,
                borderRadius: 4,
                borderColor: (theme) => theme.palette.primary.main, // Modern outline
                color: (theme) => theme.palette.primary.main,
              }}
              onClick={() => setOpenDeleteDialog(false)}
              variant="outlined"
            >
              ຍົກເລີກ
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "&:hover": {
            backgroundColor: "#f1f3f5",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{
            userSelect: "none",
            flexGrow: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            mr: 2,
            fontWeight: 500, // Modern text weight
            color: "#333", // Softer black for modern look
          }}
        >
          {" ບົດທີ " + lesson.lesson + ": " + lesson.title}
        </Typography>

        {/* Menu button */}
        <IconButton onClick={handleMenuClick}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={(event) => {
              event.stopPropagation(); // Prevent collapse trigger
              handleOpenManageAccess();
            }}
          >
            <AdminPanelSettingsIcon sx={{ mr: 1 }} />
            ຈັດການການເຂົ້າເຖີງ
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              event.stopPropagation(); // Prevent collapse trigger
              handleUpdate();
            }}
          >
            <Edit sx={{ mr: 1 }} />
            ເເກ້ໄຂ
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation(); // Prevent collapse trigger
              handleDelete();
            }}
          >
            <Delete sx={{ mr: 1 }} />
            ລົບ
          </MenuItem>
        </Menu>
      </Box>

      {/* Collapsible content */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List sx={{ p: 0, m: 0 }}>
          <ListItem
            onClick={(event) => handleListItemClick(event, onChapterClick)}
            sx={{
              backgroundColor:
                clickType === "chapter" && lessonId.value === lesson._id
                  ? "#f1f3f5"
                  : "white",
              borderTop: "1px solid #ddd",
              borderBottom: "1px solid #ddd",
              p: 1,
              cursor: "pointer",
              "&:hover, &:focus": {
                backgroundColor: "#f1f3f5",
              },
            }}
          >
            <ListItemText primary="ບົດສອນ" />
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
          </ListItem>
          <ListItem
            onClick={(event) => handleListItemClick(event, onQuestionClick)}
            sx={{
              backgroundColor:
                clickType === "question" && lessonId.value === lesson._id
                  ? "#f1f3f5"
                  : "white",
              borderBottom: "1px solid #ddd",
              p: 1,
              cursor: "pointer",
              "&:hover, &:focus": {
                backgroundColor: "#f1f3f5",
              },
            }}
          >
            <ListItemText primary="ຄຳຖາມ" />
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
          </ListItem>
        </List>
      </Collapse>
    </Box>
  );
};

export default Lesson;

import { NEW_API_URL, API_KEY } from "../../common/contrant";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../../redux/filtrSlice";
import { USER_KEY } from "../../consts";
import getRoleDescription from "../../common/GetRoleDescription";
import { ROLE_LIST } from "../../common/GetRoleDescription";
import { CustomContainer } from "../../common";
import Loading from "../../common/Loading";
import useReactRouter from "use-react-router";
import { Tabs, Tab } from "@mui/material";
import ElearningReport from "./reports/indexAdmin";

const ETeacherlist = () => {
  const [value, setValue] = useState(0);

  const { history } = useReactRouter();
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const filterData = useSelector((state) => state.filter);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [count, setCount] = useState(0);

  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    role: "",
    title: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    fetchData();
  };

  const handleChange = (event) => {
    if (event.target.value === "faculty")
      setFormData((predata) => ({
        ...predata,
        department: "",
      }));
    else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const fetchData = async () => {
    setUsers([]);
    setIsLoading(true);
    try {
      const _formData = formData;
      if (formData.faculty === "ທັງຫມົດ") _formData.faculty = "";
      if (formData.department === "ທັງຫມົດ") _formData.department = "";
      if (formData.role === "ທັງຫມົດ") _formData.role = "";

      // Create a new URLSearchParams object and append parameters
      const apiUrl = `${NEW_API_URL}users?limit=${rowsPerPage}&faculty=${_formData.faculty}&department=${_formData.department}&page=${page}&keyword=${_formData.title}&role=${_formData?.role}`;
      const response = await axios.get(apiUrl, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setCount(data?.count || 0);
      setUsers(data?.data);
      setIsLoading(false);
    } catch (error) {
      setUsers([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [formData, rowsPerPage, page]); // Empty dependency array means this effect runs once on component mount

  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Fetch faculties, departments, and education levels from your backend API
    // Update the state variables (faculties, departments, educationLevels) accordingly
    // For simplicity, I'm using placeholder data here

    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));
  }, [formData]);

  const handleRowClick = (user) => {
    console.log("Row clicked:", user);
    history.push("/elearning/teacher/" + user?._id);

    // case e-learning
    const _user = {
      data: {
        id: user?._id,
      },
    };
    localStorage.setItem("@userKey", JSON.stringify(_user));
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="bg-white">
      <Tabs
        value={value}
        onChange={handleTabChange}
        centered
        sx={{
          width: "100%",
        }}
      >
        <Tab
          sx={{
            fontSize: "18px",
            minWidth: "150px",
            maxWidth: "150px",
          }}
          label="ອາຈານສອນ"
        />

        <Tab
          sx={{
            fontSize: "18px",
            minWidth: "150px",
            maxWidth: "150px",
          }}
          label="ລາຍງານ"
        />
      </Tabs>

      {value === 0 && (
        <>
          <CustomContainer>
            <Grid
              container
              className="d-flex justify-content-center px-4"
              spacing={2}
            >
              <Grid item xs={3}>
                <FormControl fullWidth size="small" margin="normal">
                  <InputLabel htmlFor="faculty">ຄະນະ</InputLabel>
                  <Select
                    disabled={
                      userObject?.data?.role === "FAC_ADMIN" ||
                      userObject?.data?.role === "DEP_ADMIN"
                    }
                    label="ຄະນະ"
                    name="faculty"
                    value={formData.faculty || "ທັງຫມົດ"}
                    onChange={handleChange}
                  >
                    <MenuItem key={"ທັງຫມົດ"} value={"ທັງຫມົດ"}>
                      ທັງຫມົດ
                    </MenuItem>
                    {faculties.map((faculty) => (
                      <MenuItem key={faculty._id} value={faculty._id}>
                        {faculty.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth size="small" margin="normal">
                  <InputLabel htmlFor="department">ພາກ</InputLabel>
                  <Select
                    disabled={userObject?.data?.role === "DEP_ADMIN"}
                    label="ພາກ"
                    name="department"
                    value={formData.department || "ທັງຫມົດ"}
                    onChange={handleChange}
                  >
                    <MenuItem key={"ທັງຫມົດ"} value={"ທັງຫມົດ"}>
                      ທັງຫມົດ
                    </MenuItem>
                    {departments.map((department) => (
                      <MenuItem key={department._id} value={department._id}>
                        {department.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size="small" margin="normal">
                  <InputLabel htmlFor="educationLevel">ລະດັບຜູ້ໃຊ້</InputLabel>
                  <Select
                    label="ລະດັບຜູ້ໃຊ້"
                    name="role"
                    value={formData.role || "ທັງຫມົດ"}
                    onChange={handleChange}
                  >
                    <MenuItem key={"ທັງຫມົດ"} value={"ທັງຫມົດ"}>
                      ທັງຫມົດ
                    </MenuItem>
                    {ROLE_LIST.map((role) => (
                      <MenuItem key={role} value={role}>
                        {getRoleDescription(role)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth size="small" margin="normal">
                  <TextField
                    fullWidth
                    name="title"
                    onChange={handleChange}
                    type="text"
                    className="mt-2"
                    variant="standard"
                    placeholder="ຄົ້ນຫາຜູ້ໃຊ້"
                    InputProps={{
                      style: {
                        height: "33px",
                      },
                      startAdornment: <SearchIcon />,
                    }}
                  />
                </FormControl>
              </Grid>
              <TableContainer
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <Table
                    sx={{
                      width: "100%",
                    }}
                  >
                    <TableHead>
                      <TableRow className="thead-primary">
                        <TableCell className="tablecell-head">ລຳດັບ</TableCell>
                        <TableCell className="tablecell-head">ໄອດີ</TableCell>
                        <TableCell className="tablecell-head">
                          ຊື່ ແລະ ນາມສະກຸນ
                        </TableCell>
                        <TableCell className="tablecell-head">ອີເມວ</TableCell>
                        <TableCell className="tablecell-head">
                          ເບີໂທລະສັບ
                        </TableCell>
                        <TableCell className="tablecell-head">
                          ລະດັບຜູ້ໃຊ້
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user, index) => (
                        <TableRow
                          key={user._id}
                          hover // Adds hover effect for better UX
                          onClick={() => handleRowClick(user)} // Handle row click
                          sx={{
                            cursor: "pointer", // Show pointer cursor
                          }}
                        >
                          <TableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="start">{user?.userId}</TableCell>
                          <TableCell align="start">
                            {user.gender === "FEMALE"
                              ? `ທ່ານ ນາງ ${user?.firstname ?? "---"} ${
                                  user?.lastname ?? "---"
                                }`
                              : `ທ່ານ ${user?.firstname ?? "---"} ${
                                  user?.lastname ?? "---"
                                }`}
                          </TableCell>
                          <TableCell align="start">
                            {user?.email || ""}
                          </TableCell>
                          <TableCell align="center">
                            {user?.phone || ""}
                          </TableCell>
                          <TableCell align="center">
                            {getRoleDescription(user?.role)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        50,
                        { label: "All", value: count },
                      ]}
                      colSpan={6}
                      count={count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      elevation={0}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Table>
                )}
              </TableContainer>
            </Grid>
          </CustomContainer>
        </>
      )}
      {value === 1 && <ElearningReport />}
    </div>
  );
};

export default ETeacherlist;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
  IconButton,
  Modal,
  Paper,
} from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EmptyCmomponent from "../../../../common/EmptyScreen";

// Pie chart data
const pieChartData = (questionType) => {
  if (!questionType) return []; // Ensure questionType is defined
  return [
    { name: "ຖືກຕ້ອງ", value: questionType.correctCount || 0 },
    { name: "ຜິດພາດ", value: questionType.incorrectCount || 0 },
  ];
};

// Colors for pie chart
const COLORS = ["#28a745", "#dc3545"];

// Custom Tooltip
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const {
      name,
      totalQuestions,
      correctCount,
      incorrectCount,
      totalScore,
      maxPossibleScore,
      averageScore,
      correctPercentage,
    } = payload[0].payload;
    return (
      <Box
        p={2}
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
        >
          {name}
        </Typography>
        <Typography variant="body2">ຈຳນວນຄຳຖາມ: {totalQuestions}</Typography>

        {/* Correct Count with Highlight and Icon */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <CheckCircleIcon color="success" />
          <Typography variant="body2">
            ຖືກຕ້ອງ:{" "}
            <Box
              component="span"
              sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
            >
              {correctCount}
            </Box>
          </Typography>
        </Box>

        {/* Incorrect Count with Highlight and Icon */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <CancelIcon color="error" />
          <Typography variant="body2">
            ຜິດພາດ:{" "}
            <Box
              component="span"
              sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
            >
              {incorrectCount}
            </Box>
          </Typography>
        </Box>

        <Typography variant="body2">ຄະແນນທັງໝົດ: {totalScore}</Typography>
        <Typography variant="body2">
          ຄະແນນສູງສຸດທີ່ສາມາດໄດ້: {maxPossibleScore}
        </Typography>
        <Typography variant="body2">
          ຄະແນນເລັ່ງສະເລ່ຍ: {averageScore}
        </Typography>
        <Typography variant="body2">
          ອັດຕາຄວາມຖືກຕ້ອງ:{" "}
          <Box component="span" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
            {correctPercentage}%
          </Box>
        </Typography>
      </Box>
    );
  }
  return null;
};

const QuestionTypeAverage = ({ analysisResult }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (analysisResult) {
      setData(analysisResult);
    }
  }, [analysisResult]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const renderCharts = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 3,
      }}
    >
      {/* Multiple-Choice Pie Chart */}
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", mb: 1, fontWeight: "bold" }}
        >
          ປາລະໄນ
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ResponsiveContainer width="100%" height={344}>
            <PieChart>
              <Pie
                data={pieChartData(data?.["multiple-choice"])}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label={({ name, value }) => `${name}: ${value}`}
              >
                {pieChartData(data?.["multiple-choice"]).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      {/* Text-Based Pie Chart */}
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", mb: 1, fontWeight: "bold" }}
        >
          ອັດຕາໄນ
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieChartData(data?.["text-based"])}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label={({ name, value }) => `${name}: ${value}`}
              >
                {pieChartData(data?.["text-based"]).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        p: 3,
      }}
    >
      <Card
        sx={{ boxShadow: "none", backgroundColor: "#fff", borderRadius: 2 }}
      >
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              ລາຍງານການຕອບຖືກຕ້ອງ ແລະ ຜິດຕາມປະເພດຄຳຖາມ
            </Typography>
            <IconButton onClick={handleOpenModal}>
              <FullscreenIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2 }} />

          {data.length > 0 ? renderCharts() : <EmptyCmomponent height={383} />}
        </CardContent>
      </Card>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper
          sx={{
            width: "80%",
            height: "80%",
            overflowY: "auto",
            backgroundColor: "white",
            padding: 3,
            borderRadius: 2,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          {data.length > 0 ? renderCharts() : <EmptyCmomponent height={383} />}
        </Paper>
      </Modal>
    </Box>
  );
};

export default QuestionTypeAverage;

import React, { useState } from "react";
import { Box } from "@mui/material";
import Lesson from "./Lesson";
import { useDispatch } from "react-redux";
import { setLesson } from "../../../redux/lessonSlice";
import { fetchPut } from "../../../common/fetchAPI";

// LessonList component
const LessonList = ({ lessonList, onRefresh, onItemClick }) => {
  const dispatch = useDispatch();

  const handleSwitch = async (lessonId, open) => {
    await fetchPut("tlesson/open/" + lessonId + "?open=" + open);
    onRefresh();
    // console.log(`Switch toggled for lesson ${lessonId}`);
  };

  const handleUpdate = (lessonId) => {
    console.log(`Update clicked for lesson ${lessonId}`);
  };

  const handleDelete = (lessonId) => {
    console.log(`Delete clicked for lesson ${lessonId}`);
  };

  const handleChapterClick = (lessonId) => {
    console.log(`Chapter clicked for lesson ${lessonId}`);
    dispatch(setLesson(lessonId));
  };

  const handleQuestionClick = (lessonId) => {
    dispatch(setLesson(lessonId));
  };

  return (
    <Box
      sx={{
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
      }}
    >
      {lessonList.map((lesson) => (
        <Lesson
          onRefresh={() => {
            onRefresh();
          }}
          key={lesson._id}
          lesson={lesson}
          onSwitch={(e) => handleSwitch(lesson._id, e)}
          onUpdate={() => handleUpdate(lesson._id)}
          onDelete={() => handleDelete(lesson._id)}
          onChapterClick={() => {
            handleChapterClick(lesson._id);
            onItemClick("chapter");
          }}
          onQuestionClick={() => {
            onItemClick("question");
            handleQuestionClick(lesson._id);
          }}
        />
      ))}
    </Box>
  );
};

export default LessonList;

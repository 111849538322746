import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormControl,
} from "@mui/material";

const AssignDateToEvaluateForm = ({ open, onClose, onSubmit }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSubmit = () => {
    onSubmit({ startDate, endDate });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Assign Evaluation Dates</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 2,
          }}
        >
          <FormControl fullWidth size="small">
            <Typography
              htmlFor="startDate"
              style={{ marginBottom: "5px", display: "block" }}
            >
              Start Date
            </Typography>
            <input
              id="startDate"
              style={{
                height: "41px",
                padding: "5px",
                fontSize: "1rem",
                borderRadius: "4px",
                border: "1px solid rgba(0, 0, 0, 0.23)",
              }}
              className="form-control"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormControl>

          <FormControl fullWidth size="small">
            <Typography
              htmlFor="endDate"
              style={{ marginBottom: "5px", display: "block" }}
            >
              End Date
            </Typography>
            <input
              id="endDate"
              style={{
                height: "41px",
                padding: "5px",
                fontSize: "1rem",
                borderRadius: "4px",
                border: "1px solid rgba(0, 0, 0, 0.23)",
              }}
              className="form-control"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignDateToEvaluateForm;

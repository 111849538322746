import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import { translateSubjectGroup } from "../../../common/super";

const ExportCourseDetail = ({ data }) => {
  const onExport = (data) => {
    let queue = 0;
    const SubjectCourseExport = data.map((courseItem) => {
      queue++;
      return {
        ລຳດັບ: queue,
        ລະຫັດວິຊາ: courseItem.subject.code,
        ຊື່ວິຊາຮຽນ: courseItem.subject.title,
        ໜ່ວຍກິດ: courseItem.subject.credit,
        ໝວດວິຊາ: translateSubjectGroup(courseItem.subjectGroup),
        ປີຮຽນ: courseItem.year,
        ພາກຮຽນ: courseItem.semester,
      };
    });

    const ws = XLSX.utils.json_to_sheet(SubjectCourseExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SubjectCourse");

    // Write the XLSX file
    const xlsxBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const blob = new Blob([new Uint8Array(xlsxBuffer)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "subjectcourses.xlsx");
  };
  return (
    <div>
      <Button
        variant="outlined"
        size="large"
        color="success"
        style={{ textTransform: "none" }}
        onClick={() => onExport(data)}
      >
        Export
      </Button>
    </div>
  );
};

export default ExportCourseDetail;

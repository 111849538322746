import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from "recharts";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { fetchPost } from "../../../common/fetchAPI";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { students } = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      >
        <p style={{ fontWeight: "bold" }}>{label}</p>
        <ul>
          {students.map((student, index) => (
            <li key={index}>
              {student.firstNameL} {student.lastNameL} ({student.studentNumber})
              - ຄະແນນ: {student.score}, ເວລາ: {student.timeToTake} ນາທີ
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

const ExamReport = ({ classList }) => {
  const [open, setOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);

    const response = await fetchPost("/elearning/exam/report", {
      classRoomList: classList.map((classItem) => classItem._id),
    });

    if (response) {
      setData(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [classList]);

  const handleBarClick = (data) => {
    setSelectedClass(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsFullScreen(false);
  };

  const handleExpandClick = () => {
    setIsFullScreen(true);
    setOpen(true);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 400,
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!loading && data.length === 0) {
    return (
      <div
        style={{ minHeight: "300px", textAlign: "center", marginTop: "200px" }}
      >
        <Typography variant="h6">ບໍ່ມີຂໍ້ມູນ</Typography>
      </div>
    );
  }

  return (
    <div style={{ position: "relative", paddingTop: "20px" }}>
      {/* Report Title */}
      <Typography
        variant="h6"
        fontWeight={500}
        ml={2}
        mb={1}
        component="h1"
        align="left"
      >
        ລາຍງານຄະແນນສອບເສັງຂອງຫ້ອງຮຽນ
      </Typography>
      <IconButton
        aria-label="expand"
        onClick={handleExpandClick}
        style={{
          position: "absolute",
          top: 15,
          right: 0,
          marginBottom: "30px",
        }}
      >
        <FullscreenIcon />
      </IconButton>
      <ResponsiveContainer
        width="100%"
        height={400}
        style={{ marginTop: "40px" }}
      >
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barGap={20} // Set space between each bar
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="classRoomDetails.name" />
          <YAxis />
          {/* <Tooltip content={<CustomTooltip />} /> */}
          <Bar
            dataKey="averageScore"
            name="ຄະແນນສະເລ່ຍ"
            onClick={handleBarClick}
            barSize={50} // Set fixed width for each bar
          >
            {data.map((entry, index) => {
              const classColor =
                classList.find(
                  (classItem) => classItem.name === entry.classRoomDetails.name
                )?.color || "#3498db"; // Default color if not found

              return <Cell key={`cell-${index}`} fill={classColor} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ overflowY: "auto" }}
        fullScreen={isFullScreen}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isFullScreen ? "90%" : 400,
            height: isFullScreen ? "90%" : "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            {isFullScreen
              ? "ລາຍງານການສອບເສັງ - ເບິ່ງເຕັມຈໍ"
              : `ລາຍຊື່ນັກຮຽນສໍາລັບ ${selectedClass?.classRoomDetails.name}`}
          </Typography>
          {isFullScreen && (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="classRoomDetails.name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="averageScore"
                  name="ຄະແນນສະເລ່ຍ"
                  onClick={handleBarClick}
                >
                  {data.map((entry, index) => {
                    const classColor =
                      classList.find(
                        (classItem) =>
                          classItem.name === entry.classRoomDetails.name
                      )?.color || "#3498db"; // Default color if not found

                    return <Cell key={`cell-${index}`} fill={classColor} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}

          {!isFullScreen && selectedClass && (
            <Box sx={{ mt: 2 }}>
              {selectedClass?.students.map((student, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#f7f7f7",
                    p: 2,
                    mb: 2,
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mb: 0.5 }}
                  >
                    {student.firstNameL} {student.lastNameL}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ຄະແນນ:{" "}
                    <span style={{ color: "#3498db", fontWeight: "bold" }}>
                      {student.score}
                    </span>
                    , ເວລາ:{" "}
                    <span style={{ color: "#e67e22", fontWeight: "bold" }}>
                      {student.timeToTake}
                    </span>{" "}
                    ນາທີ
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ExamReport;

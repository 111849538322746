import React from "react";
import { Modal } from "reactstrap";

const EvaluationPreview = ({ open, close, data }) => {
  console.log("data:", data);
  return (
    <Modal
      size="lg"
      style={{ maxWidth: "900px", width: "100%" }}
      isOpen={open}
      toggle={close}
      onClosed={close}
    >
      <div className="text-nowrap">
        <h4 className="font-weight-bold text-center">ຕົວຢ່າງຜົນໄດ້ຮັບ</h4>
        <div className="text-center font-weight-normal">
          ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ
        </div>
        <div className="text-center font-weight-normal">
          ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ
        </div>
        <div>- ມະຫາວິທະຍາໄລແຫ່ງຊາດ</div>
        <div className="text-center font-weight-bold">
          ແບບປະເມີນການສິດສອນຂອງ ຄູ-ອາຈານໂດຍນັກສຶກສາ
        </div>
        <div>
          ຄຳແນະນໍາ:1. ກາລຸນາເລືອກຕິກ ແລະ
          ຕື່ມຂໍ້ມູນລົງບ່ອນຫວ່າງຕາຫ້ອງທີ່ຈະໃຫ້ຄະແນນຕາມຄວາມເປັນຈິງ.
        </div>
        <div style={{ paddingLeft: 60 }}>
          2. ຖ້າທ່ານເຂົ້າຮຽນບໍ່ສະໝໍ່າສະເໝີ(ບໍ່ເຖິງ 80%
          ຂອງເວລາຮຽນທັງໝົດ)ໃຫ້ສະຫຼະສິດໃນການປະເມີນ.
        </div>
        <div
          style={{
            border: "1px solid black",
            margin: 5,
            padding: 5,
            display: "inline-block",
            fontWeight: "bold",
          }}
        >
          ພາກທີ່ 1: ຂໍ້ມູນພື້ນຖານ
        </div>
        <div className="d-flex">
          1. ຊື່ ແລະ ນາມສະກຸນອາຈານສອນ:
          <div
            style={{ borderBottom: "1px dotted black", width: "100%" }}
          ></div>
        </div>
        <div className="d-flex">
          2. ວິຊາສອນ:
          <div style={{ borderBottom: "1px dotted black", width: 200 }}></div>
          ພາກຮຽນ
          <div style={{ borderBottom: "1px dotted black", width: 150 }}></div>
          ສົກສຶກສາ
          <div style={{ borderBottom: "1px dotted black", width: 150 }}></div>
        </div>
        <div className="d-flex">
          3. ສາຂາທີ່ນັກສຶກສາສັງກັດ:
          <div
            style={{ borderBottom: "1px dotted black", width: "100%" }}
          ></div>
        </div>
        {data?.sections?.map((section, index) => (
          <React.Fragment key={index}>
            {/* Display section title with section number */}
            <div
              style={{
                border: "1px solid black",
                margin: 5,
                padding: 5,
                fontWeight: "bold",
                display: "inline-block",
              }}
            >
              ພາກທີ່ {index + 2}: {section?.title}
            </div>

            {/* Check if section type is '5' or '3' to adjust the table layout accordingly */}
            {section?.type === "5" && (
              <table className="table table-bordered table-sm">
                <thead>
                  <tr className="text-center">
                    <th
                      rowSpan={2}
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        width: "50%",
                      }}
                    >
                      ເນື້ອໃນການປະເມີນ
                    </th>
                    <th colSpan={5}>ລະດັບຄວາມພໍໃຈຂອງນັກສຶກສາ</th>
                  </tr>
                  <tr className="text-center">
                    <td>
                      <div>ຫຼາຍທີ່ສຸດ</div>(5)
                    </td>
                    <td>
                      <div>ຫຼາຍ</div>(4)
                    </td>
                    <td>
                      <div>ປານກາງ</div>(3)
                    </td>
                    <td>
                      <div>ໜ້ອຍ</div>(2)
                    </td>
                    <td>
                      <div>ຄວນປັບປຸງ</div>(1)
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {section?.subsections?.map((subsection, subIndex) => (
                    <React.Fragment key={`${index}-${subIndex}`}>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>
                          {"+ " + subsection?.title}
                        </td>
                      </tr>
                      {subsection?.items?.map((item, itemIndex) => (
                        <tr key={`${index}-${subIndex}-${itemIndex}`}>
                          <td>{itemIndex + 1 + ". " + item?.title}</td>
                          {["1", "2", "3", "4", "5"].map((idx) => (
                            <td key={idx}></td>
                          ))}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}

            {section?.type === "3" && (
              <table className="table table-bordered table-sm">
                <thead>
                  <tr className="text-center">
                    <th
                      rowSpan={2}
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        width: "50%",
                      }}
                    >
                      ເນື້ອໃນການປະເມີນ
                    </th>
                    <th colSpan={3}>ລະດັບ</th>
                  </tr>
                  <tr className="text-center">
                    <td>
                      <div>ດີ</div>(3)
                    </td>
                    <td>
                      <div>ກາງ</div>(2)
                    </td>
                    <td>
                      <div>ອ່ອນ</div>(1)
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {section?.subsections?.map((subsection, subIndex) => (
                    <React.Fragment key={`${index}-${subIndex}`}>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>
                          {"+ " + subsection?.title}
                        </td>
                      </tr>
                      {subsection?.items?.map((item, itemIndex) => (
                        <tr key={`${index}-${subIndex}-${itemIndex}`}>
                          <td>{itemIndex + 1 + ". " + item?.title}</td>
                          {["1", "2", "3"].map((idx) => (
                            <td key={idx}></td>
                          ))}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
            {section?.type === "open" &&
              section?.subsections?.map((subsection, subIndex) => (
                <React.Fragment key={`${index}-${subIndex}`}>
                  {subsection?.items?.map((item, itemIndex) => (
                    <div key={`${index}-${subIndex}-${itemIndex}`}>
                      <div>{itemIndex + 1 + "). " + item?.title}</div>
                      {[1, 2, 3, 4].map((dotIndex) => (
                        <div
                          style={{
                            borderBottom: "1px dotted black",
                            width: "100%",
                            height: 20,
                          }}
                          key={dotIndex}
                        ></div>
                      ))}
                    </div>
                  ))}
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
      </div>
    </Modal>
  );
};

export default EvaluationPreview;

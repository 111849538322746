import React from "react";
import { Modal, Button, Typography, Box } from "@mui/material";

const modalStyle = (theme) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  outline: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  border: `1px solid ${theme.palette.divider}`,
});

const buttonContainerStyle = {
  marginTop: 3,
  display: "flex",
  justifyContent: "center",
  gap: 2,
};

const ConfirmModal = ({ open, message, title, onSubmit, onCancel }) => {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Typography variant="h6" id="modal-title" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="body2"
          id="modal-description"
          color="textSecondary"
          mb={3}
        >
          {message}
        </Typography>
        <Box sx={buttonContainerStyle}>
          <Button
            sx={{ px: 4, py: 1.5 }}
            onClick={onSubmit}
            variant="contained"
            color="error"
          >
            ຢືນຢັນ
          </Button>
          <Button
            sx={{ px: 4, py: 1.5 }}
            onClick={onCancel}
            variant="outlined"
            color="primary"
          >
            ຍົກເລີກ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;

import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import SectionAverage from "./studentHomeworkResult/sectionAverage";
import QuestionTypeAverage from "./studentHomeworkResult/questionTypeAnalyze";
import HardestQuestinList from "./studentHomeworkResult/hardestQuestion";
import AverageScoreTable from "./studentHomeworkResult/averageScore";
// import AverageTimeToTakeGraph from "./studentHomeworkResult/averageTime";
import StudentList from "./studentHomeworkResult/simpleReport";
import { fetchGet } from "../../../common/fetchAPI";
import StudentHomeworkReportAdditional from "./studentHomeworkAnswrReportAdditonal";

const StudentHomeworkReport = ({ classId, examId }) => {
  const [examResult, setExamResult] = useState({
    students: [],
    timeSpentVsPerformance: {},
    analysisResult: {},
    averageTimeToTake: {},
    averageByScore: {},
    sectionWisePerformance: [],
    engagementInsights: {},
    hardestQuestions: {},
  });
  const fetchExamResult = async () => {
    const _examResult = await fetchGet(
      "/thomework/result/" + examId + "/" + classId
    );
    if (_examResult) {
      setExamResult(_examResult);
    }
  };

  useEffect(() => {
    fetchExamResult();
  }, [classId, examId]);

  return (
    <Box sx={{ p: 3, backgroundColor: "transparent", m: 0 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <SectionAverage
            sectionWisePerformance={examResult?.sectionWisePerformance}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <QuestionTypeAverage analysisResult={examResult?.analysisResult} />
        </Grid>
        <Grid item xs={12} md={6}>
          <HardestQuestinList
            hardestQuestions={examResult?.hardestQuestions || []}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AverageScoreTable averageByScore={examResult?.averageByScore} />
        </Grid>
        <Grid item xs={12}>
          <StudentHomeworkReportAdditional data={examResult} />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <AverageTimeToTakeGraph
            averageTimeToTake={examResult?.averageTimeToTake}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default StudentHomeworkReport;

import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  Box,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import { fetchGet } from "../../common/fetchAPI";
import PersonIcon from "@mui/icons-material/Person";
import ChairIcon from "@mui/icons-material/EventSeat";
import CategoryIcon from "@mui/icons-material/Category";

const PlaceFilter = ({ onSelect }) => {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [placeList, setPlaceList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [classRoomList, setClassRoomList] = useState([]);
  const [roomDetail, setRoomDetail] = useState(null);
  const [formData, setFormData] = useState({
    assessmentYear: accessmentRedux,
    building: "",
    place: "",
    room: "",
  });

  const fetchPlaceList = async () => {
    const response = await fetchGet("entrance/place/" + formData.room);
    if (response) setPlaceList(response);
  };

  const fetchRoomDetail = async () => {
    if (formData.room) {
      const response = await fetchGet(`entrance/room/${formData.room}`);
      if (response) {
        setRoomDetail(response);
      }
    } else {
      setRoomDetail(null);
    }
  };

  const fetchBuildingList = async () => {
    const response = await fetchGet(
      `entrance/building?place=${
        formData?.place === "All" ? "" : formData?.place
      }`
    );
    if (response) setBuildingList(response);
  };

  const fetchClassRoomList = async () => {
    const response = await fetchGet(
      `entrance/room?building=${
        formData?.building === "All" ? "" : formData?.building
      }`
    );
    if (response) setClassRoomList(response);
  };

  useEffect(() => {
    if (formData?.place) {
    }
    fetchBuildingList();
  }, [formData?.place]);

  useEffect(() => {
    setClassRoomList([]);
    setRoomDetail(null);
    if (formData?.building) fetchClassRoomList();
  }, [formData?.building]);

  useEffect(() => {
    fetchRoomDetail();
  }, [formData.room]);

  useEffect(() => {
    fetchPlaceList();
    setFormData((prevData) => ({
      ...prevData,
      assessmentYear: accessmentRedux,
    }));
  }, [accessmentRedux]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (onSelect) onSelect(formData);
  }, [formData, onSelect]);

  return (
    <Paper elevation={0}>
      <Grid container pt={2} justifyContent="center" spacing={2}>
        {/* Place Selector */}
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel size="small">ເລືອກສະຖານທີ</InputLabel>
            <Select
              size="small"
              label="ເລືອກສະຖານທີ"
              name="place"
              value={formData?.place}
              onChange={handleChange}
            >
              {placeList.map((place) => (
                <MenuItem key={place._id} value={place._id}>
                  {place.place}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Building Selector */}
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 2 }} disabled={!formData?.place}>
            <InputLabel size="small">ເລືອກຕຶກ</InputLabel>
            <Select
              size="small"
              label="ເລືອກຕຶກ"
              name="building"
              value={formData?.building}
              onChange={handleChange}
            >
              {buildingList.map((building) => (
                <MenuItem key={building._id} value={building._id}>
                  {building.building}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 3 }} disabled={!formData?.building}>
            <InputLabel size="small">ເລືອກຫ້ອງເສັງ</InputLabel>
            <Select
              size="small"
              label="ເລືອກຫ້ອງເສັງ"
              name="room"
              value={formData?.room}
              onChange={handleChange}
            >
              {classRoomList.map((room) => (
                <MenuItem key={room._id} value={room._id}>
                  {room.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Details Section */}

          {roomDetail && (
            <Grid container spacing={2}>
              {/* Assigned Students */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center">
                  <PersonIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    <strong>ນັກສຶກສາທີ່ຈັດແລ້ວ:</strong>{" "}
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "1.1rem",
                        color: "#1976d2",
                      }}
                    >
                      {roomDetail?.details?.assignedStudents || "0"}
                    </span>
                  </Typography>
                </Box>
              </Grid>

              {/* Available Seats */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center">
                  <ChairIcon color="secondary" sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    <strong>ບ່ອນນັ່ງທີ່ເຫລືອ:</strong>{" "}
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "1.1rem",
                        color: "#388e3c",
                      }}
                    >
                      {roomDetail?.details?.availableSeats || "0"}
                    </span>
                  </Typography>
                </Box>
              </Grid>

              {/* Group Info */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center">
                  <CategoryIcon color="info" sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    <strong>ສຳລັບກຸ່ມຮຽນ:</strong>{" "}
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "1.1rem",
                        color: "#1976d2",
                      }}
                    >
                      {roomDetail?.room?.group || ""}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PlaceFilter;

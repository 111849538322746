import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  TextField,
  Typography,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/contrant";
import { USER_KEY } from "../../consts";
import formatDate from "./formatDate";
import getActionTypeTitle from "./getTypeAction";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);
const token = userObject?.accessToken;

export const RegisterStudentLogsList = () => {
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState("");
  const [logsList, setLogList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedActionType, setSelectedActionType] = useState("ALL");
  const [showAllStudents, setShowAllStudents] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const highlightText = (text, userId) => {
    if (!userId) return text;

    const regex = new RegExp(`(${userId})`, "gi");
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          style={{ backgroundColor: "#4895ef", color: "white" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChange = (event) => {
    setSelectedActionType(event.target.value);
  };

  const fetchLogs = async () => {
    try {
      const response = await axios.get(
        NEW_API_URL +
          `logs?&date=${date}&type=STUDENT_REGISTER&page=${page+1}&limit=${rowsPerPage}&keyword=${keyword}&action=${selectedActionType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            api_key: API_KEY,
          },
        }
      );
      setLogList(response.data?.data || []);
      setTotalCount(response.data?.count);
    } catch (err) {}
  };

  useEffect(() => {
    fetchLogs();
  }, [page, rowsPerPage, keyword, date, selectedActionType]);

  return (
    <Paper elevation={0} className="pb-3">
      <div className="row">
        <div className="col-6">
          <FormControl sx={{ marginTop: "37px" }} fullWidth size="small">
            <TextField
              name="title"
              onChange={(e) => setKeyword(e.target.value)}
              type="text"
              variant="standard"
              placeholder="ຄົ້ນຫາໄອດີ"
              InputProps={{
                style: {
                  height: "33px",
                },
                startAdornment: <SearchIcon />,
              }}
            />
          </FormControl>
        </div>
        <div className="col-3">
          <FormControl fullWidth>
            <Typography
              htmlFor="actiontype"
              style={{ marginBottom: "5px", display: "block" }}
            >
              ຄົ້ນຫາປະເພດກາານຈັດການ
            </Typography>{" "}
            <Select
              size="small"
              labelId="select-label"
              id="actiontype"
              value={selectedActionType || ""}
              onChange={handleChange}
              defaultValue={""}
            >
              <MenuItem value="ALL">ທັງຫມົດ</MenuItem>
              <MenuItem value="REGISTER">ລົງທະບຽນປີ 2 ຂຶ້ນໄປ</MenuItem>
              <MenuItem value="REGISTER_MANY">ລົງທະບຽນປີ 1</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-3">
          <FormControl fullWidth size="small">
            <Typography
              htmlFor="date"
              style={{ marginBottom: "5px", display: "block" }}
            >
              ຄົ້ນຫາຕາມວັນທີ່
            </Typography>
            <input
              id="date"
              style={{
                height: "41px",
              }}
              className="form-control"
              type="date"
              defaultValue={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </FormControl>
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#e9ecef" }}>
            <TableRow style={{ backgroundColor: "#e9ecef" }}>
              <TableCell colSpan={1} align="center">
                ລຳດັບ
              </TableCell>
              <TableCell colSpan={2} align="center">
                ຜູ້ເເກ້ໄຂ
              </TableCell>
              <TableCell colSpan={2} align="start">
                ນັກສຶກສາ
              </TableCell>
              <TableCell colSpan={4} align="center">
                ຂໍ້ມຸນເຄື່ອງ
              </TableCell>
              <TableCell colSpan={2} align="center">
                ປະເພດການລົງທະບຽນ
              </TableCell>
              <TableCell colSpan={2} align="center">
                ວັນທີ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logsList.length > 0 &&
              logsList.map((log, index) => (
                <TableRow key={log._id}>
                  <TableCell colSpan={1} align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    {highlightText(log?.author?.userId, keyword)}
                  </TableCell>

                  {log?.action === "REGISTER" ? (
                    <TableCell colSpan={2} align="start">
                      {highlightText(log?.student?.studentNumber, keyword)}
                    </TableCell>
                  ) : (
                    <TableCell colSpan={2} align="start">
                      {showAllStudents
                        ? log.students.map((student, index) => (
                            <span key={student.studentNumber}>
                              {highlightText(student.studentNumber, keyword)}
                              {index !== log.students.length - 1 && <br />}
                            </span>
                          ))
                        : log.students.slice(0, 3).map((student, index) => (
                            <span key={student.studentNumber}>
                              {highlightText(student.studentNumber, keyword)}
                              {index !== 2 && <br />}
                            </span>
                          ))}
                      {log.students.length > 3 && (
                        <>
                          <IconButton
                            onClick={() => setShowAllStudents(!showAllStudents)}
                          >
                            {showAllStudents ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  )}
                  <TableCell colSpan={4} align="start">
                    {log?.userAgent}
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    {log?.action === "REGISTER_MANY" ? (
                      <Typography
                        sx={{
                          color: "#4361ee",
                        }}
                      >
                        ລົງທະບຽນປີ 1
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#43aa8b",
                        }}
                      >
                        ລົງທະບຽນປີ 2 ຂຶ້ນໄປ
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    {formatDate(log?.createdAt)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[
              10,
              20,
              50,
              { label: "ທັງຫມົດ", value: totalCount },
            ]}
            colSpan={13} // Adjusted colSpan to include the Actions column
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            elevation={0}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RegisterStudentLogsList;

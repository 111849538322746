import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Button,
  Modal,
  TextField,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { fetchPut } from "../../common/fetchAPI";
import { NEW_API_URL } from "../../common/contrant";
import { USER_KEY } from "../../consts";
import PlaceFilter from "./PlaceFilter";

const StatusStepper = ({ student, onRefresh }) => {
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [placeData, setPlaceData] = useState({
    place: "",
    building: "",
    room: "",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const steps = ["ລໍຖ້າຢືນຢັນ", "ຢືນຢັນແລ້ວ", "ເສັງຜ່ານແລ້ວ"];

  const getCurrentStep = (status) => {
    switch (status) {
      case "PENDING":
        return 0;
      case "APPROVED":
        return 1;
      case "PASSED":
        return 2;
      default:
        return -1; // Not applicable for Rejected
    }
  };

  const currentStep = getCurrentStep(student.status.approvedStatus);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setReason("");
    setError("");
  };

  const handleConfirmDialogOpen = () => setConfirmDialogOpen(true);
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);

  useEffect(() => {
    if (snackbar.open) {
      setTimeout(() => {
        setSnackbar({ open: false, message: "", severity: "success" });
      }, 3000);
    }
  }, []);

  const handleAction = async (action) => {
    setLoading(true);

    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);
    const userId = userObject?.data?.id;

    setError(""); // Clear any previous error messages
    const payload = {
      approvedStatus: action,
      place: placeData?.place,
      building: placeData?.building,
      room: placeData?.room,
      user: userId,
      ...(action === "REJECTED" && { reason }),
    };

    try {
      const response = await fetchPut(
        `${NEW_API_URL}entrance/student/${student._id}`,
        payload
      );

      if (!response.success) {
        throw new Error("Failed to update status");
      }

      // Show Snackbar
      setSnackbar({
        open: true,
        message: `ການດຳເນີນການສຳເລັດ`,
        severity: "success",
      });

      // Call Parent Callback to Refresh Data
      onRefresh();

      // Close appropriate UI elements
      if (action === "REJECTED") {
        handleCloseModal();
      } else if (action === "APPROVED") {
        handleConfirmDialogClose();
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: "ລະບົບມີບັນຫາ ໃຫ້ລອງໃຫມ່ພາຍຫລັງ.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">ສະຖານະ</Typography>
      </AccordionSummary>

      <AccordionDetails>
        {student.status.approvedStatus === "REJECTED" ? (
          <Box>
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {"ຖືກປະຕີເສດ: " + student.status.reason}
            </Alert>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmDialogOpen}
                disabled={loading}
                style={{ marginRight: "16px" }}
              >
                ຢືນຢັນໃຫມ່ອີກຄັ້ງ
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            {/* Stepper for Normal Status Flow */}
            <Stepper activeStep={currentStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {/* Display Approving User */}
            {(student.status.approvedStatus === "APPROVED" ||
              student.status.approvedStatus === "APPROVED") &&
              student.status.user && (
                <Box
                  mt={2}
                  p={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid #ddd",
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    sx={{ marginRight: "16px" }}
                    variant="subtitle1"
                    fontWeight="bold"
                  >
                    {student.status.approvedStatus === "APPROVED"
                      ? "ຢືນຢັນໂດຍ"
                      : student.status.approvedStatus === "REJECTED"
                      ? "ປະຕີເສດໂດຍ"
                      : ""}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "4px",
                    }}
                    variant="body2"
                  >
                    {student?.status?.user?.gender === "FEMALE"
                      ? `ທ່ານ ນາງ ${
                          student?.status?.user?.firstname ?? "---"
                        } ${student?.status?.user?.lastname ?? "---"}`
                      : `ທ່ານ ${student?.status?.user?.firstname ?? "---"} ${
                          student?.status?.user?.lastname ?? "---"
                        }`}
                  </Typography>
                </Box>
              )}

            {/* Approve and Reject Buttons for Pending Status */}
            {student.status.approvedStatus === "PENDING" && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
              >
                <Button
                  variant="contained"
                  onClick={handleConfirmDialogOpen}
                  disabled={loading}
                  style={{ marginRight: "16px" }}
                >
                  ຢືນຢັນ
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleOpenModal}
                  disabled={loading}
                >
                  ປະຕິເສດ
                </Button>
              </Box>
            )}
          </>
        )}

        {/* Display Error Message */}
        {error && (
          <Box mt={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
      </AccordionDetails>

      {/* Modal for Reject Reason */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">ສາເຫດທີ່ປະຕິເສດ</Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="ໃສ່ລາຍລະອຽດເປັນຫຍັງຈື່ງປະຕີເສດ"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleAction("REJECTED")}
              disabled={!reason.trim() || loading}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "ຢືນຢັນ"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog for Approve */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        aria-labelledby="confirm-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            width: "500px", // Set your desired width
            maxWidth: "90%", // Ensure responsiveness
          },
        }}
      >
        <DialogTitle id="confirm-dialog-title">ຢືນຢັນນັກສຶກສາ</DialogTitle>
        <DialogContent>
          <PlaceFilter onSelect={(data) => setPlaceData(data)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="secondary">
            ຍົກເລີກ
          </Button>
          <Button
            onClick={() => {
              handleAction("APPROVED");
            }}
            color="primary"
            variant="contained"
            disabled={
              loading || Object.values(placeData).some((value) => !value)
            }
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "ຢືນຢັນ"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Alerts */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Accordion>
  );
};

export default StatusStepper;

import { NEW_API_URL, API_KEY } from "../common/contrant";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
} from "@mui/material";
import axios from "axios";
import { Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../redux/filtrSlice";
import { USER_KEY } from "../consts";

const PrereStudentFilter = ({ description, onSelect }) => {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const filterData = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [majors, setMajors] = useState([]);

  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    major: filterData.major || "",
    date: "",
    title: "",
    assessmentYear: accessmentRedux,
  });

  useEffect(() => {
    setFormData((predata) => ({
      ...predata,
      assessmentYear: accessmentRedux,
    }));
  }, [accessmentRedux]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "faculty") {
      setFormData((prevData) => ({
        ...prevData,
        department: "", // Reset department when faculty changes
        [name]: value,
      }));
    }
    if (name === "department") {
      setFormData((prevData) => ({
        ...prevData,
        major: "", // Reset department when faculty changes
        [name]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Fetch faculties, departments, and education levels from your backend API
    // Update the state variables (faculties, departments, educationLevels) accordingly
    // For simplicity, I'm using placeholder data here

    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch majors
    if (formData.department) {
      axios
        .get(NEW_API_URL + "getmajo/" + formData.department, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setMajors(response.data?.data || []))
        .catch((error) => console.error(error));
    }
  }, [formData.faculty, formData.department]);

  useEffect(() => {
    if (onSelect) {
      onSelect(formData);
    }
  }, [formData]);

  return (
    <Paper elevation={0}>
      <Grid sx={{ textAlign: "left", width: "100%" }}>
        <Typography>{description}</Typography>
      </Grid>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth size="small" margin="normal">
            <TextField
              name="title"
              onChange={handleChange}
              type="text"
              variant="standard"
              placeholder="ຄົ້ນຫານັກສຶກສາ (ລະຫັດ, ຊື່, ນາມສະກຸນ, ເບິໂທລະສັບ)"
              InputProps={{
                style: { height: "33px" },
                startAdornment: <SearchIcon />,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth size="small" margin="normal">
            <InputLabel htmlFor="faculty">ຄະນະ</InputLabel>
            <Select
              disabled={
                userObject?.data?.role === "FAC_ADMIN" ||
                userObject?.data?.role === "DEP_ADMIN"
              }
              label="ຄະນະ"
              name="faculty"
              value={formData.faculty || ""}
              onChange={handleChange}
            >
              <MenuItem key="" value="">
                ທັງຫມົດ
              </MenuItem>
              {faculties.map((faculty) => (
                <MenuItem key={faculty._id} value={faculty._id}>
                  {faculty.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth size="small" margin="normal">
            <InputLabel htmlFor="department">ພາກ</InputLabel>
            <Select
              disabled={userObject?.data?.role === "DEP_ADMIN"}
              label="ພາກ"
              name="department"
              value={formData.department || ""}
              onChange={handleChange}
            >
              <MenuItem key="" value="">
                ທັງຫມົດ
              </MenuItem>
              {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth size="small" margin="normal">
            <InputLabel htmlFor="major">ສາຂາ</InputLabel>
            <Select
              label="ສາຂາ"
              name="major"
              value={formData.major || ""}
              onChange={handleChange}
            >
              <MenuItem key="" value="">
                ທັງຫມົດ
              </MenuItem>
              {majors.map((major) => (
                <MenuItem key={major._id} value={major._id}>
                  {major.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth size="small" margin="normal">
            <input
              id="date"
              style={{ height: "41px" }}
              name="date"
              className="form-control"
              type="date"
              defaultValue={formData.date}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PrereStudentFilter;

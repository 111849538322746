import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Grid,
  Card,
  Typography,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { fetchGet } from "../../../common/fetchAPI";

const AddPostDialog = ({ open, onClose, onSubmit }) => {
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [selectedTags, setSelectedTags] = useState([]); // Store as full tag objects
  const [allTags, setAllTags] = useState([]);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [customTag, setCustomTag] = useState("");

  const fetchTags = async (query) => {
    try {
      const response = await fetchGet("/tpost/tag?query=" + query);
      if (response) {
        setAllTags(response.map((tag) => ({ name: tag.name, _id: tag._id })));
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const truncateFilename = (name, maxLength = 40) => {
    return name.length > maxLength
      ? `${name.substring(0, maxLength)}...`
      : name;
  };

  const isImageFile = (file) => file.type.startsWith("image/");
  const isVideoFile = (file) => file.type.startsWith("video/");

  const handleSubmit = () => {
    const user = localStorage.getItem("@userKey");
    const userObject = JSON.parse(user);

    // Transform selectedTags to only names before submitting
    const tagsToSubmit = selectedTags.map((tag) => tag.name);

    const formData = new FormData();
    formData.append("description", description);
    formData.append("teacherId", userObject?.data?.id);
    formData.append("tags", JSON.stringify(tagsToSubmit));
    files.forEach((file) => formData.append("files", file));
    onSubmit(formData);
    setFiles([]);
    setDescription("");
    setAllTags([]);
    onClose();
  };

  const handleAddCustomTag = () => {
    if (customTag.trim() !== "") {
      const newTag = { name: customTag, _id: Date.now().toString() }; // Unique ID for custom tag
      setAllTags((prevTags) => [...prevTags, newTag]);
      setSelectedTags((prevTags) => [...prevTags, newTag]);
      setCustomTag("");
      setShowAddTagModal(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>ເພີ່ມບົດຄວາມໃຫມ່</DialogTitle>
        <DialogContent>
          <TextField
            label="ລາຍລະອຽດບົດຄວາມ"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
          />

          <Box display="flex" alignItems="center" mt={2}>
            <Autocomplete
              fullWidth
              multiple
              options={allTags}
              value={selectedTags}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              onInputChange={(event, newInputValue) => {
                if (newInputValue && newInputValue.trim() !== "") {
                  fetchTags(newInputValue);
                } else {
                  setAllTags([]);
                }
              }}
              onChange={(event, newValue) => setSelectedTags(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="ແທັກ" placeholder="ເລືອກແທັກ" />
              )}
            />
            <IconButton
              color="primary"
              onClick={() => setShowAddTagModal(true)}
              sx={{ ml: 1 }}
            >
              <AddCircleIcon />
            </IconButton>
          </Box>

          <Box mt={2}>
            <Button
              variant="contained"
              component="label"
              startIcon={<UploadIcon />}
            >
              ອັບໂຫລດເອກະສານ
              <input type="file" hidden multiple onChange={handleFileChange} />
            </Button>
          </Box>
          <Grid container spacing={2} mt={2}>
            {files.map((file, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 1,
                    backgroundColor: "#f0f4f8",
                    borderRadius: "8px",
                  }}
                >
                  {isImageFile(file) ? (
                    <Box
                      component="img"
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      sx={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "4px",
                        marginBottom: 1,
                      }}
                    />
                  ) : isVideoFile(file) ? (
                    <Box
                      component="video"
                      src={URL.createObjectURL(file)}
                      controls
                      sx={{
                        width: "100%",
                        height: "150px",
                        borderRadius: "4px",
                        marginBottom: 1,
                      }}
                    />
                  ) : (
                    <Box display="flex" alignItems="center" width="100%">
                      <InsertDriveFileIcon
                        sx={{ marginRight: 1, color: "#2196f3" }}
                      />
                      <Typography variant="subtitle2" noWrap>
                        {truncateFilename(file.name)}
                      </Typography>
                    </Box>
                  )}
                  <IconButton
                    color="error"
                    onClick={() => handleFileDelete(index)}
                    size="small"
                    sx={{ alignSelf: "flex-end" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            ຍົກເລີກ
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            ຢືນຢັນ
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for adding custom tags */}
      <Dialog open={showAddTagModal} onClose={() => setShowAddTagModal(false)}>
        <DialogTitle>ເພີ່ມເເທັກໃຫມ່</DialogTitle>
        <DialogContent>
          <TextField
            label="ຊື່ເເທັກ"
            value={customTag}
            onChange={(e) => setCustomTag(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddTagModal(false)} color="primary">
            ຍົກເລີກ
          </Button>
          <Button
            onClick={handleAddCustomTag}
            color="primary"
            variant="contained"
          >
            ເພີ່ມເເທັກ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddPostDialog;

import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Typography,
  Autocomplete,
} from '@mui/material';
import axios from 'axios';
import { USER_KEY, API_KEY } from "../../consts";
import { NEW_API_URL } from '../../common/contrant';
import SearchIcon from '@mui/icons-material/Search';

const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);

function ElearningStudent() {
  const [searchValue, setSearchValue] = useState('');
  const [type, setType] = useState('GET');
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [logsList, setLogs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [studentList, setStudentList] = useState([]); // State for teacher list
  const [selectedItem, setSelectedItem] = useState("");
  const [formData, setFormData] = useState({
    teacher: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const fetchLogs = async () => {
    try {
      const response = await axios.get(
        `https://test.api.laodevhub.com/api/v1/euserlog?page=${page + 1}&limit=${rowsPerPage}&type=${type}&startDate=${startDate}&endDate=${endDate}&userType=STUDENT`,
        { headers: { api_key: API_KEY } }
      );
      setLogs(response.data?.logs || []);
      setTotalCount(response.data?.totalLogs || 0);
    } catch (err) {
      console.error('Failed to fetch logs:', err);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [page, rowsPerPage, type, startDate, endDate]); // Remove fetchTeachers from here
  
  // on seaarch teacher
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://test.api.laodevhub.com/api/v1/student/search?keyword=${searchValue}`
        );
        setStudentList(response.data?.data); // Assuming the API returns an array of options
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (searchValue !== "") {
      fetchData();
    } else {
      setStudentList([]); // Clear options if searchValue is empty
    }
  }, [searchValue]);

  const handleSelect = (event, newValue) => {
    setSelectedItem(newValue);
    if (newValue) {
      setFormData((preData) => ({
        ...preData,
        teacher: newValue?._id,
      }));
    }
  };

  const typeLabels = {
    "COMMENT": "ການໃຫ້ຄຳເຫັນ",
    "COMMENT_UPDATE": "ການແກ້ໄຂຄຳເຫັນ",
    "COMMENT_DELETE": "ການລຶບຄຳເຫັນ",
    "ANSWER": "ການຕອບ"
  };

  return (
    <Paper elevation={0} className="pb-3">
      <div className="row">
        <div className="col-3">
          <FormControl fullWidth size="small">
            <div style={{ marginTop: '30px' }}>
              <Autocomplete
                s size="small"
                options={studentList || []}
                getOptionLabel={(option) => option.firstname + " " + option.lastname}
                fullWidth
                name="teacher"
                onChange={handleSelect}
                onInputChange={(event, newValue) => setSearchValue(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ຄົ້ນຫານັກຮຽນ"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <SearchIcon style={{ marginRight: 8 }} />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </FormControl>
        </div>
        <div className="col-3">
          <FormControl fullWidth size="small">
            <Typography
              htmlFor="actiontype"
              style={{ marginBottom: "5px", display: "block" }}
            >
              ຄົ້ນຫາປະເພດ
            </Typography>
            <Select
              value={type || ""}
              onChange={(e) => setType(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>ຄົ້ນຫາປະເພດ</em>
              </MenuItem>
              <MenuItem value="COMMENT">ການໃຫ້ຄຳເຫັນ</MenuItem>
              <MenuItem value="COMMENT_UPDATE">ການແກ້ໄຂຄຳເຫັນ</MenuItem>
              <MenuItem value="COMMENT_DELETE">ການລຶບຄຳເຫັນ</MenuItem>
              <MenuItem value="ANSWER">ການຕອບ</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-3">
          <FormControl fullWidth size="small">
            <Typography
              htmlFor="date"
              style={{ marginBottom: "5px", display: "block" }}
            >
              ຄົ້ນຫາວັນທີເລີ່ມຕົ້ນ
            </Typography>
            <input
              id="startdate"
              style={{
                height: "41px",
              }}
              className="form-control"
              type="date"
              defaultValue={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormControl>
        </div>
        <div className="col-3">
          <FormControl fullWidth size="small">
            <Typography
              htmlFor="date"
              style={{ marginBottom: "5px", display: "block" }}
            >
              ຄົ້ນຫາວັນທີສຸດທ້າຍ
            </Typography>
            <input
              id="enddate"
              style={{
                height: "41px",
              }}
              className="form-control"
              type="date"
              defaultValue={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormControl>
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#e9ecef" }}>
            <TableRow style={{ backgroundColor: "#e9ecef" }}>
              <TableCell align="center">ລຳດັບ</TableCell>
              <TableCell align="center">ຜູ້ເຂົ້າ</TableCell>
              <TableCell align="center">ປະເພດ</TableCell>
              <TableCell align="center">ຂໍ້ມູນເຄື່ອງ</TableCell>
              <TableCell align="center">ຂໍ້ມູນ</TableCell>
              <TableCell align="center">ລາຍລະອຽດ</TableCell>
              <TableCell align="center">ວັນທີເຂົ້າ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logsList.length > 0 ? (
              logsList.map((log, index) => (
                <TableRow key={log._id}>
                  <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell align="center">{log.userType}</TableCell>
                  <TableCell align="center"> {typeLabels[log.type] || log.type}</TableCell>
                  <TableCell align="center">{log.agent}</TableCell>
                  <TableCell align="center">{typeof log.data === 'object' ? JSON.stringify(log.data) : log.data}</TableCell>
                  <TableCell align="center">{typeof log.description === 'object' ? JSON.stringify(log.description) : log.description}</TableCell>
                  <TableCell align="center">{new Date(log.createdAt).toLocaleString()}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">ບໍ່ມີປະຫວັດການເຂົ້າເຖິງ</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, { label: 'ທັງຫມົດ', value: totalCount }]}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page} // Page is zero-based in TablePagination
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>
    </Paper>
  );
}

export default ElearningStudent;

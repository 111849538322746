import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Typography, Alert } from "@mui/material";

export default function AlertMessage({ open, message, type, handleClose }) {
  const isSuccess = type === "success";

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      // autoHideDuration={3000} // Auto-close after 3 seconds
      onClose={handleClose}
      // TransitionComponent={SlideTransition} // Slide transition effect
    >
      <Alert
        onClose={handleClose}
        severity={isSuccess ? "success" : "error"}
        sx={{
          // backgroundColor: isSuccess ? "#57cc99" : "#da627d",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Shadow for modern look
          borderRadius: "8px", // Rounded corners
          padding: "12px 24px", // Better spacing
          fontWeight: "bold", // Ensures bold is applied across the alert
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: "#343a40",
          }}
        >
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
}

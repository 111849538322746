import React from "react";
import { Modal, Button, Typography, Box } from "@mui/material";

const modalStyle = (theme) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)", // Softer shadow
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2, // More rounded corners
  outline: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
});

const buttonContainerStyle = {
  marginTop: 3,
  display: "flex",
  justifyContent: "center",
  gap: 2,
};

const ConfirmModal = ({ open, message, title, onSubmit, onCancel }) => {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={(theme) => modalStyle(theme)}>
        <Typography
          variant="h6"
          id="modal-title"
          gutterBottom
          sx={{ fontWeight: 600 }} // Make title bold
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          id="modal-description"
          color="textSecondary"
          mb={3}
          sx={{ fontSize: "1rem" }} // Modern, readable font size
        >
          {message}
        </Typography>
        <Box sx={buttonContainerStyle}>
          <Button
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 4, // Rounded button
              boxShadow: "0px 4px 12px rgba(255, 0, 0, 0.2)", // Soft shadow for action button
            }}
            onClick={onSubmit}
            variant="contained"
            color="error"
          >
            ຢືນຢັນ
          </Button>
          <Button
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 4,
              borderColor: (theme) => theme.palette.primary.main, // Modern outline
              color: (theme) => theme.palette.primary.main,
            }}
            onClick={onCancel}
            variant="outlined"
          >
            ຍົກເລີກ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;

import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import CustomMessage from "../../common/CustomStatusMessage";

const BillDetailComponent = ({ data, onCancel, onSuccess, onFailed }) => {
  const contentRef = useRef(null);

  const Top = () => (
    <>
      <div className="c-row">
        <div className="col-8 "></div>
        <div className="col-4 text-start">
          <p>ທບ8</p>
        </div>
      </div>
      <div className="c-row mb-0">
        <div className="col-8 text-start">
          <p>ມະຫາວິທະຍາໄລເເຫ່ງຊາດ</p>
        </div>
        <div className="col-4 text-start">
          <p>ເລກທີ.......</p>
        </div>
      </div>
      <div className="c-row">
        <div className="col-8 text-start">
          <p>ຊື່ຄະນະວິຊາ: {data?.student?.faculty?.name}</p>
        </div>
        <div className="col-4 text-start">
          <p>ວັນທີ......../......../........</p>
        </div>
      </div>
    </>
  );

  const Header = () => (
    <>
      <div className="c-row">
        <div className="col-12 text-center mb-2">
          <h5>ໃບລົງທະບຽນຮຽນສົກ {data?.student?._assessmentYear}</h5>
        </div>
      </div>
      <div className="c-row">
        <div className="col-12 text-start">
          <p>
            ລະຫັດນັກສຶກສາ: {data?.student?.userId} ຊື່:{" "}
            {data?.student?.firstNameL + " " + data?.student?.lastNameL}
          </p>
        </div>
      </div>
      <div className="c-row">
        <div className="col-12 text-start">
          <p>
            ຫ້ອງເບີ: {data?.student?.classRoom?.name} ເບີໂທ:{" "}
            {data?.student?.phone}
          </p>
        </div>
      </div>
    </>
  );

  const CourseList = () => (
    <>
      <div className="c-row">
        <div className="col-12 text-center">
          <h5>
            ລາຍວິຊາຮຽນ ປີ{" "}
            {data?.student?.yearLevel + " ສາຂາ: " + data?.student?.major?.name}
          </h5>
        </div>
      </div>

      <div className="row">
        <table>
          <thead>
            <tr>
              <th colSpan={1} className="py-2 text-center bg-white text-dark">
                ລຳດັບ
              </th>
              <th colSpan={2} className="text-start bg-white text-dark">
                ລະຫັດວິຊາ
              </th>
              <th colSpan={3} className="text-start bg-white text-dark">
                ຊື່ວິຊາ
              </th>
              <th colSpan={1} className="text-cemter bg-white text-dark">
                ຫນ່ວຍກິດ
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.courses.map((course, index) => (
              <tr key={course?.courseCode}>
                <td colSpan={1} className="text-center py-2">
                  {index + 1}
                </td>
                <td className="text-start py-2" colSpan={2}>
                  {" "}
                  {course?.subject?.code}
                </td>
                <td className="text-start py-2" colSpan={3}>
                  {course?.subject?.title}
                </td>
                <td className="text-center py-2" colSpan={1}>
                  {" "}
                  {course?.subject?.credit}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );

  const generatePDF = () => {
    const input = contentRef.current;
    const scale = 3; // Increase the scale value for higher resolution and better quality

    html2canvas(input, { scale: scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(
        '<img src="' + imgData + '" onload="window.print();window.close()">'
      );
      printWindow.document.close();
    });
  };

  const Footter = () => (
    <>
      <div className="row">
        <div className="col-4">
          <p>ພະເເນກການເງິນ</p>
        </div>
        <div className="col-4">
          <p>ຄະນະວິຊາການ</p>
        </div>
        <div className="col-4">
          <p>ລາຍເຊັນນັກສຶກສາ</p>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-start ps-5">
          <p className="ps-3">ຫມາຍເຫດ: ນັກສຶກສາເກັບໄວ້ ຫ້າມເຮັດເສຍ</p>
        </div>
      </div>
    </>
  );

  const PayList = () => (
    <div className="c-row">
      <div className="col text-end">
        <p>ລວມຫນ່ວກິດ {" " + totalUnit + " "} ຫນ່ວຍກິດ</p>
        <p>ຄ່າຫນ່ວຍກິດ {data?.creditPrice?.toLocaleString()} /ຫນ່ວຍກິດ ກີຍ</p>
        {data?.otherPaidList.map((pay) => (
          <div key={pay?.title_la}>
            <p>
              {pay?.title_la} {pay?.price.toLocaleString()} ກີບ
            </p>
          </div>
        ))}
        <p>ລວມເງິນ {" " + data?.totalPaid.toLocaleString() + " "} ກີບ</p>
      </div>
    </div>
  );

  const Commit = () => (
    <>
      <div className="c-row">
        <div className="col-12 text-center">
          <h4>ທ່ານຕ້ອງການດຳເນີນການລົງທະບຽນແທ້ບໍ່?</h4>
        </div>
      </div>
      <div className="d-flex gap-5 mt-3 mb-3">
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            setIsLoading(true);
            setZoomOut("");
            register();
          }}
        >
          ຢືນຢັນ
        </Button>
        <Button
          color="warning"
          variant="contained"
          size="large"
          onClick={() => {
            onCancel();
          }}
        >
          ຍົກເລີກ
        </Button>
      </div>
    </>
  );

  const register = async () => {
    try {
      setIsLoading(true);

      await axios.put(
        NEW_API_URL +
          "register/status?id=" +
          data?.student?._id +
          "&status=" +
          true +
          "&year=" +
          data?.student?.yearLevel +
          "&totalPaid=" +
          data?.totalPaid +
          "&assessmentYear=" +
          data?.student?._assessmentYear,
        data.student, // Passing data.student as the request body
        {
          headers: {
            api_key: API_KEY,
          },
        }
      );

      setIsLoading(false);
      onSuccess();
    } catch (err) {
      setIsLoading(false);
      onFailed();
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const totalUnit = data?.courses.reduce(
    (total, course) => total + (course?.unit || 0),
    0
  );
  const [zoomOut, setZoomOut] = useState("zm");

  return (
    <div>
      <Dialog open={isLoading} aria-labelledby="loading-dialog">
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <div className="my-modal-regis">
        <div className={"modal-content-regis " + zoomOut}>
          <div ref={contentRef} className="c-row">
            <div className="col-6 pr-3 mt-4">
              <div className="mr-3">
                <Top />
                <Header />
                <CourseList />
                <PayList />
                <Footter />
              </div>
            </div>
            <div className="col-6 pr-3 mt-4">
              <div className="ml-3">
                <Top />
                <Header />
                <CourseList />
                <PayList />
                <Footter />
              </div>
            </div>
          </div>

          <Commit />
        </div>
      </div>
    </div>
  );
};

export default BillDetailComponent;

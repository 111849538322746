import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Avatar,
  Typography,
  IconButton,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { fetchDelete, fetchGet, fetchPost } from "../../../common/fetchAPI";
import ConfirmModal from "../../../common/ConfirmDialog";

const getTimeDifference = (timestamp) => {
  const now = new Date();
  const createdTime = new Date(timestamp);
  const differenceInSeconds = Math.floor((now - createdTime) / 1000);

  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} seconds ago`;
  } else if (differenceInSeconds < 3600) {
    const minutes = Math.floor(differenceInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (differenceInSeconds < 86400) {
    const hours = Math.floor(differenceInSeconds / 3600);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (differenceInSeconds < 604800) {
    const days = Math.floor(differenceInSeconds / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (differenceInSeconds < 2592000) {
    const weeks = Math.floor(differenceInSeconds / 604800);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (differenceInSeconds < 31536000) {
    const months = Math.floor(differenceInSeconds / 2592000);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else {
    const years = Math.floor(differenceInSeconds / 31536000);
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }
};

const Comment = ({
  comment,
  onReplyClick,
  activeCommentId,
  onClickDelete,
  onSubmitReply,
}) => {
  const [isReplying, setIsReplying] = useState(false);
  const [replyText, setReplyText] = useState("");

  const handleReplyClick = () => {
    setIsReplying(true);
    onReplyClick(comment._id);
  };

  const handleCancelReply = () => {
    setIsReplying(false);
    setReplyText(""); // Clear the reply input
  };

  const handleReplySubmit = () => {
    if (replyText.trim()) {
      onSubmitReply(comment._id, replyText); // Pass the comment ID and reply text
      setIsReplying(false);
      setReplyText(""); // Clear the input after submission
    }
  };

  const isTeacherOwner = () => {
    const user = JSON.parse(localStorage.getItem("@userKey"));
    const teacherId = user?.data?.id;
    return comment.instructor?.teacher?._id === teacherId;
  };

  return (
    <Box mb={2} ml={comment.parentComment ? 4 : 0} p={2} borderRadius={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Avatar
            src={
              comment.instructor?.profilePicture ||
              (comment.student &&
                `${comment?.student?.firstNameL[0]}${comment?.student?.lastNameL[0]}`)
            }
            alt="Avatar"
          />
          <Typography variant="body1" fontWeight="bold" ml={1}>
            {comment.student
              ? `${comment?.student?.firstNameL} ${comment?.student?.lastNameL}`
              : isTeacherOwner()
              ? comment.instructor?.teacher?.firstname +
                " " +
                comment?.instructor?.teacher?.lastname +
                " (ທ່ານ)"
              : comment.instructor
              ? comment.instructor?.teacher?.firstname +
                " " +
                comment?.instructor?.teacher?.lastname
              : "Anonymous"}
          </Typography>
        </Box>
        <Typography variant="caption">
          {getTimeDifference(comment.createdAt)}
        </Typography>
      </Box>
      <Box mt={2} position="relative">
        {isTeacherOwner() && (
          <IconButton
            onClick={() => onClickDelete(comment._id)}
            size="small"
            color="error"
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
        <Box
          sx={{
            backgroundColor: "#f8f9fa",
            width: "100%",
            borderRadius: 3,
            padding: 2,
          }}
        >
          <Typography ml={2} variant="body2">
            {comment.content}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mt={1}>
          <IconButton size="small" onClick={handleReplyClick}>
            <ReplyIcon fontSize="small" />
          </IconButton>
          <Typography variant="caption">ຕອບກັບ</Typography>
        </Box>
      </Box>
      {comment.replies.map((reply) => (
        <Comment
          key={reply._id}
          comment={reply}
          onReplyClick={onReplyClick}
          activeCommentId={activeCommentId}
          onClickDelete={onClickDelete}
          onSubmitReply={onSubmitReply}
        />
      ))}
      {isReplying && activeCommentId === comment._id && (
        <Box mt={2}>
          <TextField
            fullWidth
            placeholder="ປ້ອນຄຳເຫັນຂອງທ່ານນ..."
            variant="outlined"
            multiline
            rows={2}
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          />
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              onClick={handleReplySubmit}
            >
              ສົ່ງ
            </Button>
            <IconButton
              size="small"
              color="error"
              onClick={handleCancelReply}
              sx={{ ml: 1 }}
            >
              <CancelIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const CommentListDialog = ({ postId, open, onClose }) => {
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [data, setData] = useState({ success: false, comments: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const fetchComments = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await fetchGet(`/tpost/comment/${postId}`);
      if (response) {
        setData(response);
        setLoading(false);
      } else {
        setError(true);
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteComment = async () => {
    if (commentId) {
      setShowConfirm(false);
      setIsLoading(true);
      const response = await fetchDelete(`/tpost/comment/${commentId}`);
      setIsLoading(false);
      if (response) {
        await fetchComments();
      }
    }
  };

  const replyComment = async (parentCommentId, replyText) => {
    setIsLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("@userKey"));
      const teacherId = user?.data?.id;
      const response = await fetchPost(`/tpost/reply`, {
        postId,
        parentCommentId,
        instructorId: teacherId,
        content: replyText,
      });

      if (response) {
        await fetchComments(); // Refresh comments after reply
      }
    } catch (error) {
      console.error("Error submitting reply:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (postId) fetchComments();
  }, [postId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <ConfirmModal
        open={showConfirm}
        title={"ລົບຄຳເຫັນ!"}
        message="ທ້ານຕ້ອງການລົບຄຳເຫັນນີ້ເເທ້ບໍ່??"
        onCancel={() => setShowConfirm(false)}
        onSubmit={deleteComment}
      />
      <DialogTitle>ລາຍການຄຳເຫັນ</DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" align="center">
            ລະບົບມີບັນຫາໃນການສະເເດງລາຍການຄຳຄິດເຫັນ.
          </Typography>
        ) : data.comments.length > 0 ? (
          data.comments.map((comment) => (
            <Comment
              key={comment._id}
              comment={comment}
              onReplyClick={setActiveCommentId}
              activeCommentId={activeCommentId}
              onClickDelete={(id) => {
                setCommentId(id);
                setShowConfirm(true);
              }}
              onSubmitReply={replyComment}
            />
          ))
        ) : (
          <Typography align="center">ບໍ່ມີລາຍການຄຳເຫັນ</Typography>
        )}
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button onClick={onClose} variant="contained">
          ປິດ
        </Button>
      </Box>
    </Dialog>
  );
};

export default CommentListDialog;

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button,
  TextField,
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReplayIcon from "@mui/icons-material/Replay";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useParams } from "react-router-dom";
import AlertMessage from "../../../common/AlertMessage";

import LoadingDialog from "../../../common/LoadingDialog";
import { fetchGet, fetchPost, fetchPut } from "../../../common/fetchAPI";

import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import useReactRouter from "use-react-router";

// const data = [
//   {
//     _id: "66a71bc25b2b0a246533d56e",
//     classRoom: {
//       _id: "63d9e94d68b0c5135fcafc6a",
//       name: "Bsc 1/4.5",
//     },
//     course: "66a71bc25b2b0a246533d566",
//     faculty: "636ca741cb5e606698a23c04",
//     department: "636ca88bcb5e606698a23d6d",
//     major: "636ca9f0cb5e606698a23f40",
//     semester: 1,
//     year: 1,
//     assessmentYear: ["2023 - 2024"],
//     createdAt: "2024-07-29T04:34:10.653Z",
//     updatedAt: "2024-07-29T04:34:10.654Z",
//     __v: 0,
//   },
// ];

// Icons for multi-select checkbox
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const ItemTypes = {
  SECTION: "section",
  QUESTION: "question",
};

const DraggableItem = ({ item, index, moveItem, children }) => {
  const [, ref] = useDrag({
    type: item.type,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: item.type,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return <div ref={(node) => ref(drop(node))}>{children}</div>;
};

const ExamAdd = () => {
  const { history } = useReactRouter();
  const user = localStorage.getItem("@userKey");
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const { id } = useParams();

  const location = useLocation();

  const fetchExamData = async () => {
    if (location?.state) {
      const examId = location.state;
      const _examData = await fetchGet("/texam/" + examId);
      if (examData) {
        setExamData(_examData);
      }
    }
  };

  useEffect(() => {
    fetchExamData();
  }, [location]);

  // const examId = location.state || {};

  // console.log("examId => ", examId);

  const [selectedClassList, setSelectedClassList] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [errors, setErrors] = useState({});
  const [examData, setExamData] = useState({
    title: "",
    course: "Course ID",
    teacher: "Teacher ID",
    classRoom: [],
    startExamDatetime: "2024-10-15T08:00:00Z",
    endExamDatetime: "2024-10-15T09:00:00Z",
    sections: [
      {
        index: 1,
        sectionTitle: "",
        questions: [
          {
            index: 1,
            type: "multiple-choice",
            text: "",
            options: { A: "", B: "", C: "", D: "" },
            correctOption: "",
            maxScore: 0,
          },
        ],
      },
    ],
  });

  const [classRoomList, setClassRoomList] = useState([
    // {
    //   classRoom: {
    //     _id: "63d9e94d68b0c5135fcafc6a",
    //     name: "test",
    //   },
    // },
  ]);

  useEffect(() => {
    // because it an object or course with classroom field
    // so we shuld get class room id inside course array
    if (classRoomList.length > 0) {
      const classIdList = classRoomList.map((course) => course.classRoom._id);
      setExamData({ ...examData, classRoom: classIdList });
    }
  }, [classRoomList]);

  const fetchClassrooms = async () => {
    const _classList = await fetchGet("/tcourse/class/" + id);
    setClassRoomList(_classList);
  };

  useEffect(() => {
    const userObject = JSON.parse(user);
    setExamData((preData) => ({
      ...preData,
      teacher: userObject?.data?.id,
      course: id,
    }));
    fetchClassrooms();
  }, [token]);

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (field, value) => {
    setExamData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSectionTitleChange = (sectionIndex, value) => {
    const updatedSections = [...examData.sections];
    updatedSections[sectionIndex].sectionTitle = value;
    setExamData({ ...examData, sections: updatedSections });
  };

  const handleQuestionChange = (sectionIndex, questionIndex, field, value) => {
    const updatedSections = [...examData.sections];
    updatedSections[sectionIndex].questions[questionIndex][field] = value;
    setExamData({ ...examData, sections: updatedSections });
  };

  const handleQuestionTypeChange = (sectionIndex, questionIndex, type) => {
    const updatedSections = [...examData.sections];
    updatedSections[sectionIndex].questions[questionIndex].type = type;
    if (type === "text-based") {
      delete updatedSections[sectionIndex].questions[questionIndex].options;
      delete updatedSections[sectionIndex].questions[questionIndex]
        .correctOption;
    }
    setExamData({ ...examData, sections: updatedSections });
  };

  const moveSection = (fromIndex, toIndex) => {
    const updatedSections = [...examData.sections];
    const [movedSection] = updatedSections.splice(fromIndex, 1);
    updatedSections.splice(toIndex, 0, movedSection);
    setExamData({ ...examData, sections: updatedSections });
  };

  const moveQuestion = (sectionIndex, fromIndex, toIndex) => {
    const updatedSections = [...examData.sections];
    const questions = [...updatedSections[sectionIndex].questions];
    const [movedQuestion] = questions.splice(fromIndex, 1);
    questions.splice(toIndex, 0, movedQuestion);
    updatedSections[sectionIndex].questions = questions;
    setExamData({ ...examData, sections: updatedSections });
  };

  const addSection = () => {
    const newSection = {
      index: examData.sections.length + 1,
      sectionTitle: `ຫົວຂໍ່ໃຫມ່ ${examData.sections.length + 1}`,
      questions: [],
    };
    setExamData({ ...examData, sections: [...examData.sections, newSection] });
  };

  const addQuestion = (sectionIndex) => {
    const updatedSections = [...examData.sections];
    const newQuestion = {
      index: updatedSections[sectionIndex].questions.length + 1,
      type: "multiple-choice", // default type
      text: `ຄຳຖາມໃຫມ່ ${updatedSections[sectionIndex].questions.length + 1}`,
      options: { A: "", B: "", C: "", D: "" },
      correctOption: "A",
      maxScore: 5,
    };
    updatedSections[sectionIndex].questions.push(newQuestion);
    setExamData({ ...examData, sections: updatedSections });
  };

  const deleteSection = (index) => {
    const updatedSections = examData.sections.filter((_, i) => i !== index);
    setExamData({ ...examData, sections: updatedSections });
  };

  const deleteQuestion = (sectionIndex, questionIndex) => {
    const updatedSections = [...examData.sections];
    const updatedQuestions = updatedSections[sectionIndex].questions.filter(
      (_, i) => i !== questionIndex
    );
    updatedSections[sectionIndex].questions = updatedQuestions;
    setExamData({ ...examData, sections: updatedSections });
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Validation logic
  const validateFields = () => {
    let valid = true;
    let newErrors = {};

    if (!examData.title) {
      newErrors.title = "Ttitle exam datetime is required";
      valid = false;
    }
    if (!examData.startExamDatetime) {
      newErrors.startExamDatetime = "Start exam datetime is required";
      valid = false;
    }
    if (!examData.endExamDatetime) {
      newErrors.endExamDatetime = "End exam datetime is required";
      valid = false;
    }
    if (examData.classRoom.length === 0) {
      newErrors.classRoom = "Classroom is required";
      valid = false;
    }

    examData.sections.forEach((section, sectionIndex) => {
      if (!section.sectionTitle) {
        newErrors[`sectionTitle-${sectionIndex}`] = "ຫົວຂໍ້ໃຫຍ່ is required";
        valid = false;
      }

      section.questions.forEach((question, questionIndex) => {
        if (!question.text) {
          newErrors[`questionText-${sectionIndex}-${questionIndex}`] =
            "ຄຳຖາມ is required";
          valid = false;
        }

        if (question.type === "multiple-choice") {
          if (
            !question.options?.A ||
            !question.options?.B ||
            !question.options?.C ||
            !question.options?.D
          ) {
            newErrors[`questionOptions-${sectionIndex}-${questionIndex}`] =
              "All multiple-choice options are required";
            valid = false;
          }
        }
      });
    });

    setErrors(newErrors);
    return valid;
  };

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "error",
  });

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  // Handle form submission
  const handleSubmit = async () => {
    if (validateFields()) {
      try {
        setIsLoading(true);

        let createExam;
        if (location?.state) {
          // If location.state exists, make a PUT request
          createExam = await fetchPut(`/texam/${location.state}`, examData);
        } else {
          // Otherwise, make a POST request
          createExam = await fetchPost("/texam", examData);
        }

        setIsLoading(false);

        if (createExam) {
          setActionStatus({
            open: true,
            message: location?.state ? "ເເກ້ໄຂສຳເລັດ" : "ເພີ່ມສຳເລັດ", // Success message
            type: "success",
          });
        } else {
          setActionStatus({
            open: true,
            message: "ລະບົບມີບັນຫາ", // Error message
            type: "error",
          });
        }
      } catch (error) {
        setIsLoading(false);
        setActionStatus({
          open: true,
          message: "ມີບັນຫາເກີດຂຶ້ນ. ກະລຸນາລອງໃໝ່", // Generic error message
          type: "error",
        });
      }
    } else {
      console.log("ຂໍ້ມູນໃນແບບຟອມບໍ່ຖືກຕ້ອງ.");
    }
  };

  const onCancel = () => {
    history.push("/elearning/teacher/course/" + id);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box p={2}>
        <AlertMessage
          open={actionStatus.open}
          message={actionStatus.message}
          type={actionStatus.type}
        />
        <LoadingDialog open={isLoading} message={"ກຳລັງໂຫລດ..."} />

        <Box
          sx={{
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
            padding: 2,
            marginBottom: 1,
            backgroundColor: "white",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "end",
            justifyContent: "flex-start", // Align items to the right
          }}
        >
          <Button
            startIcon={<CancelIcon />}
            onClick={onCancel}
            variant="contained"
            color="error"
            sx={{
              borderRadius: 3,
              mr: 2,
              px: 3,
            }} // Add marginRight here
          >
            ຍົກເລີກ
          </Button>
          <Button
            startIcon={<SaveIcon />}
            sx={{
              borderRadius: 3,
              mr: 2,
              px: 3,
            }} // Add marginRight here
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            {location?.state ? "ເເກ້ໄຂ" : "ເພີ່ມ"}
          </Button>
          <IconButton onClick={fetchExamData} color="primary">
            <ReplayIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Column layout so each part can occupy its own row
            backgroundColor: "white",
            padding: 3,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
          }}
          mb={1}
        >
          {/* Title input - Full width */}
          <TextField
            label="ຫົວຂໍ້ບົດສອບເສັງ"
            value={examData?.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
            fullWidth
            error={!!errors.title}
            helperText={errors.titles}
            sx={{ mb: 2 }} // Add bottom margin for spacing
          />

          {/* Row of remaining inputs */}
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              flexDirection: "row", // Keep the remaining inputs in a row
            }}
          >
            <Autocomplete
              fullWidth
              multiple
              options={classRoomList}
              disableCloseOnSelect
              getOptionLabel={(option) => `ຫ້ອງຮຽນ: ${option.classRoom.name}`} // Display classroom name
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Typography>{`ຫ້ອງຮຽນ: ${option.classRoom.name}`}</Typography>{" "}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  error={!!errors.classRoom}
                  helperText={errors?.classRoom}
                  {...params}
                  label="ເລືອກຫ້ອງຮຽນ"
                  placeholder="ຫ້ອງຮຽນ"
                />
              )}
              onChange={(event, newValue) => {
                // Map selected values to their respective classRoom._id
                const selectedClassroomIds = newValue.map(
                  (item) => item.classRoom._id
                );
                setSelectedClassList(selectedClassroomIds); // Update state with classroom._id
              }}
              value={classRoomList.filter((item) =>
                selectedClassList.includes(item.classRoom._id)
              )} // Filter by classroom._id for selected values
            />

            <TextField
              label="ຊ່ວງເວລາເລີ່ມເປີດການເສັງ"
              type="datetime-local"
              value={examData.startExamDatetime.split("Z")[0]}
              onChange={(e) =>
                handleInputChange("startExamDatetime", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
              error={!!errors.startExamDatetime}
              helperText={errors.startExamDatetime}
            />
            <TextField
              label="ຊ່ວງເວລາປິດການເສັງ"
              type="datetime-local"
              value={examData.endExamDatetime.split("Z")[0]}
              onChange={(e) =>
                handleInputChange("endExamDatetime", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
              error={!!errors.endExamDatetime}
              helperText={errors.endExamDatetime}
            />
            <TextField
              label="ໄລ່ຍະເວລາການເສັງ (ນາທີ)"
              type="number"
              value={examData.duration}
              onChange={(e) => handleInputChange("duration", e.target.value)}
              fullWidth
              error={!!errors.duration}
              helperText={errors.duration}
            />
          </Box>
        </Box>

        {examData.sections.map((section, sectionIndex) => (
          <DraggableItem
            key={sectionIndex}
            item={{ type: ItemTypes.SECTION }}
            index={sectionIndex}
            moveItem={moveSection}
          >
            <Accordion
              sx={{
                mb: 1,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <TextField
                  label="ຫົວຂໍ້ໃຫຍ່"
                  value={section.sectionTitle}
                  variant="outlined"
                  fullWidth
                  sx={{ marginRight: 2 }}
                  onChange={(e) =>
                    handleSectionTitleChange(sectionIndex, e.target.value)
                  }
                  error={!!errors[`sectionTitle-${sectionIndex}`]}
                  helperText={errors[`sectionTitle-${sectionIndex}`]}
                />
                <IconButton
                  onClick={() => deleteSection(sectionIndex)}
                  sx={{ color: "red", ml: "auto" }}
                >
                  <DeleteIcon />
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                {section.questions.map((question, questionIndex) => (
                  <DraggableItem
                    key={questionIndex}
                    item={{ type: ItemTypes.QUESTION }}
                    index={questionIndex}
                    moveItem={(from, to) =>
                      moveQuestion(sectionIndex, from, to)
                    }
                  >
                    <Accordion
                      expanded={
                        expanded === `panel${sectionIndex}${questionIndex}`
                      }
                      onChange={handleAccordionChange(
                        `panel${sectionIndex}${questionIndex}`
                      )}
                      sx={{
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
                        mb: 1,
                        backgroundColor: "#f8f9fa",
                        borderRadius: 2,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          mb:
                            expanded === `panel${sectionIndex}${questionIndex}`
                              ? 0
                              : 1,
                        }}
                      >
                        <Box display="flex" alignItems="center" width="100%">
                          {/* ຄຳຖາມ Field */}
                          <TextField
                            label="ຄຳຖາມ"
                            value={question.text}
                            variant="outlined"
                            fullWidth
                            sx={{
                              flexGrow: 1,
                              marginRight: 2,
                              backgroundColor: "white", // Set background color to white
                              mt: 0.5, // Add 5px margin on top
                            }}
                            onChange={(e) =>
                              handleQuestionChange(
                                sectionIndex,
                                questionIndex,
                                "text",
                                e.target.value
                              )
                            }
                            error={
                              !!errors[
                                `questionText-${sectionIndex}-${questionIndex}`
                              ]
                            }
                            helperText={
                              errors[
                                `questionText-${sectionIndex}-${questionIndex}`
                              ]
                            }
                          />
                          <FormControl
                            sx={{ width: "250px", marginRight: 2, mt: 0.5 }}
                            variant="outlined"
                          >
                            <InputLabel
                              id={`question-type-label-${sectionIndex}-${questionIndex}`}
                            >
                              ປະເພດຄຳຖາມ
                            </InputLabel>
                            <Select
                              labelId={`question-type-label-${sectionIndex}-${questionIndex}`}
                              value={question.type}
                              onChange={(e) =>
                                handleQuestionTypeChange(
                                  sectionIndex,
                                  questionIndex,
                                  e.target.value
                                )
                              }
                              label="ປະເພດຄຳຖາມ" // Add this label for the Select
                              sx={{
                                backgroundColor: "white", // Set background color to white
                              }}
                            >
                              <MenuItem value="multiple-choice">
                                ປາລະໄນ
                              </MenuItem>
                              <MenuItem value="text-based">ອັດຕະໄນ</MenuItem>
                            </Select>
                          </FormControl>

                          {/* Max Score Field */}
                          <TextField
                            label="ຄະເເນນສູງສຸດ"
                            type="number"
                            value={question.maxScore}
                            onChange={(e) =>
                              handleQuestionChange(
                                sectionIndex,
                                questionIndex,
                                "maxScore",
                                e.target.value
                              )
                            }
                            sx={{
                              width: "100px",
                              marginRight: 2,
                              backgroundColor: "white", // Set background color to white
                              mt: 0.5, // Add 5px margin on top
                            }}
                          />

                          {/* Delete Icon on the Far Right */}
                          <IconButton
                            onClick={() =>
                              deleteQuestion(sectionIndex, questionIndex)
                            }
                            sx={{ color: "red", ml: "auto" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {question.type === "multiple-choice" && (
                          <Box>
                            <TextField
                              label="Option A"
                              value={question.options?.A}
                              onChange={(e) =>
                                handleQuestionChange(
                                  sectionIndex,
                                  questionIndex,
                                  "options",
                                  {
                                    ...question.options,
                                    A: e.target.value,
                                  }
                                )
                              }
                              fullWidth
                              sx={{
                                mb: 1,
                                backgroundColor: "white", // Set background color to white
                                mt: 0.5, // Add 5px margin on top
                              }}
                              error={
                                !!errors[
                                  `questionOptions-${sectionIndex}-${questionIndex}`
                                ]
                              }
                              helperText={
                                errors[
                                  `questionOptions-${sectionIndex}-${questionIndex}`
                                ]
                              }
                            />
                            <TextField
                              label="Option B"
                              value={question.options?.B}
                              onChange={(e) =>
                                handleQuestionChange(
                                  sectionIndex,
                                  questionIndex,
                                  "options",
                                  {
                                    ...question.options,
                                    B: e.target.value,
                                  }
                                )
                              }
                              fullWidth
                              sx={{
                                mb: 1,
                                backgroundColor: "white", // Set background color to white
                                mt: 0.5, // Add 5px margin on top
                              }}
                            />
                            <TextField
                              label="Option C"
                              value={question.options?.C}
                              onChange={(e) =>
                                handleQuestionChange(
                                  sectionIndex,
                                  questionIndex,
                                  "options",
                                  {
                                    ...question.options,
                                    C: e.target.value,
                                  }
                                )
                              }
                              fullWidth
                              sx={{
                                mb: 1,
                                backgroundColor: "white", // Set background color to white
                                mt: 0.5, // Add 5px margin on top
                              }}
                            />
                            <TextField
                              label="Option D"
                              value={question.options?.D}
                              onChange={(e) =>
                                handleQuestionChange(
                                  sectionIndex,
                                  questionIndex,
                                  "options",
                                  {
                                    ...question.options,
                                    D: e.target.value,
                                  }
                                )
                              }
                              fullWidth
                              sx={{
                                mb: 1,
                                backgroundColor: "white", // Set background color to white
                                mt: 0.5, // Add 5px margin on top
                              }}
                            />
                            <TextField
                              label="Correct Option"
                              value={question.correctOption}
                              onChange={(e) =>
                                handleQuestionChange(
                                  sectionIndex,
                                  questionIndex,
                                  "correctOption",
                                  e.target.value
                                )
                              }
                              select
                              fullWidth
                              sx={{
                                mb: 1,
                                backgroundColor: "white", // Set background color to white
                                mt: 0.5, // Add 5px margin on top
                              }}
                            >
                              <MenuItem value="A">A</MenuItem>
                              <MenuItem value="B">B</MenuItem>
                              <MenuItem value="C">C</MenuItem>
                              <MenuItem value="D">D</MenuItem>
                            </TextField>
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </DraggableItem>
                ))}

                <Button
                  sx={{
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                  onClick={() => addQuestion(sectionIndex)}
                  startIcon={<AddIcon />}
                  variant="contained"
                >
                  ເພີ່ມຄຳຖາມໃຫມ່
                </Button>
              </AccordionDetails>
            </Accordion>
          </DraggableItem>
        ))}

        <Button
          sx={{
            marginTop: "15px",
            borderRadius: 3,
          }}
          onClick={addSection}
          startIcon={<AddIcon />}
          variant="contained"
        >
          ເພີ່ມຫົວຂໍ້ໃຫມ່
        </Button>
      </Box>
    </DndProvider>
  );
};

export default ExamAdd;

import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import SectionAverage from "./studentExamResult/sectionAverage";
import QuestionTypeAverage from "./studentExamResult/questionTypeAnalyze";
import HardestQuestinList from "./studentExamResult/hardestQuestion";
import AverageScoreTable from "./studentExamResult/averageScore";
import AverageTimeToTakeGraph from "./studentExamResult/averageTime";
import StudentList from "./studentExamResult/simpleReport";
import { fetchGet } from "../../../common/fetchAPI";

const StudentExamReport = ({ classId, examId }) => {
  const [examResult, setExamResult] = useState({
    students: [],
    timeSpentVsPerformance: {},
    analysisResult: {},
    averageTimeToTake: {},
    averageByScore: {},
    sectionWisePerformance: [],
    engagementInsights: {},
    hardestQuestions: {},
  });
  const fetchExamResult = async () => {
    const _examResult = await fetchGet(
      "/texam/result/" + examId + "/" + classId
    );
    if (_examResult) {
      setExamResult(_examResult);
    }
  };

  useEffect(() => {
    fetchExamResult();
  }, [classId, examId]);

  return (
    <Box sx={{ p: 3, backgroundColor: "transparent", m: 0 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <StudentList students={examResult?.students} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionAverage
            sectionWisePerformance={examResult?.sectionWisePerformance}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <QuestionTypeAverage analysisResult={examResult?.analysisResult} />
        </Grid>
        <Grid item xs={12} md={6}>
          <HardestQuestinList
            hardestQuestions={examResult?.hardestQuestions || []}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AverageScoreTable averageByScore={examResult?.averageByScore} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AverageTimeToTakeGraph
            averageTimeToTake={examResult?.averageTimeToTake}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StudentExamReport;

import React, { useEffect, useState } from "react";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const AssessmentYearSetting = () => {
  const [addFailed, setAddFailed] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [currentYear, setCurrentYear] = useState("");
  const [accessmentYear, setAccessmentYear] = useState("");
  const [accessmentYearList, setAccessmentYearList] = useState([]);

  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear();
    // const checkMonth = new Date().getMonth() + 1;
    // if (checkMonth >= 10) {
    //     for (var i = NOW; i >= 2014; i--) {
    //         year.push(i + " - " + (parseInt(i) + 1));
    //     }
    // } else {
    for (var i = NOW; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    // }
    setAccessmentYearList(year);
  }, []);

  useEffect(() => {
    fetchCurrentYear();
  }, [addSuccess]);

  const fetchCurrentYear = async () => {
    try {
      const response = await axios.get(NEW_API_URL + "/assesmentyear", {
        headers: {
          api_key: API_KEY,
        },
      });

      if (response.data.success) {
        setCurrentYear(response.data?.data?.accessmentYear);
      } else {
        setAddFailed(true);
      }
    } catch (err) {
      setAddFailed(true);
    }
  };

  const AddAcessmentYear = async (data) => {
    try {
      const response = await axios.post(NEW_API_URL + "/assesmentyear", data, {
        headers: {
          api_key: API_KEY,
        },
      });

      if (response.data.success) {
        setAddFailed(false);
        setAddSuccess(true);
      } else {
        console.log("hello");
        setAddFailed(true);
        setAddSuccess(false);
      }
    } catch (error) {
      console.error("Error:", error);

      setAddFailed(true);
      setAddSuccess(false);
    }
  };

  const handleSelectChange = (event) => {
    setAccessmentYear(event.target.value);
  };

  const submit = () => {
    if (accessmentYear) AddAcessmentYear({ accessmentYear });
    else alert("ກະລຸນາເລືອກສົກກ່ອນ");
  };

  return (
    <div>
      <div class="container text-center pt-5">
        <div className="row">
          <h2>ສົກປັດຈຸບັນ {currentYear}</h2>
        </div>
        <div className="row w-100">
          <div className="col-4"></div>
          <div className="col-4">
            <select
              class="form-select form-select-md mb-3 custom-select text-center"
              aria-label=".form-select-lg example"
              value={accessmentYear}
              onChange={handleSelectChange}
            >
              <option value="">ເລືຶອກສົກຮຽນ</option>
              {accessmentYearList.map((item) => (
                <option className="text-center" key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="col-4"></div>
        </div>

        <div className="me-4 pe-3">
          <button onClick={submit} className="newbt-sm shadow">
            ຢືນຢັນ
          </button>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", zIndex: 2 }}
          open={addFailed}
          autoHideDuration={4000}
          onClose={() => setAddFailed(false)}
        >
          <Alert onClose={() => setAddFailed(false)} severity="error">
            ຂໍອະໄພ ລະບົບມີບັນຫາ
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", zIndex: 2 }}
          open={addSuccess}
          autoHideDuration={4000}
          onClose={() => setAddSuccess(false)}
        >
          <Alert onClose={() => setAddSuccess(false)} severity="success">
            ສຳເລັດ
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default AssessmentYearSetting;

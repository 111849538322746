import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Button,
  Chip,
  Grid,
  Avatar,
  Modal,
  TextField,
  Stack,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { convertGender } from "./attendanceReport";
import AlertMessage from "../../../../common/AlertMessage";
import { fetchPost } from "../../../../common/fetchAPI";

const ExamTimeReport = ({ examTimeReport }) => {
  const [showModal, setShowModal] = useState(false);
  const [student, setStudent] = useState("");
  const [sugggestText, setSuggestText] = useState("");

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus((preData) => ({
          ...preData,
          open: false,
        }));
      }, 3000);
    }
  }, [actionStatus]);

  if (!examTimeReport) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography>ກຳລັງໂຫລດ...</Typography>
      </Box>
    );
  }

  const { totalExams, averageTotalEfficiency, examDetails } = examTimeReport;

  const Row = ({ exam }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell>{exam.title}</TableCell>
          <TableCell>{exam.duration}</TableCell>
          <TableCell>{exam.averageTimeUsed}</TableCell>
          <TableCell>{exam.efficiency}</TableCell>
          <TableCell>
            <Box display="flex" flexDirection="column">
              <Chip
                label={`<50%: ${exam.timeDistribution.lessThan50Percent}`}
                sx={{
                  mb: 1,
                  background: "linear-gradient(135deg, #e3f2fd, #bbdefb)",
                  color: "#0d47a1",
                  fontWeight: "bold",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                }}
                size="small"
              />
              <Chip
                label={`50-75%: ${exam.timeDistribution.between50And75Percent}`}
                sx={{
                  mb: 1,
                  background: "linear-gradient(135deg, #ede7f6, #d1c4e9)",
                  color: "#4a148c",
                  fontWeight: "bold",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                }}
                size="small"
              />
              <Chip
                label={`>75%: ${exam.timeDistribution.moreThan75Percent}`}
                sx={{
                  background: "linear-gradient(135deg, #e8f5e9, #c8e6c9)",
                  color: "#1b5e20",
                  fontWeight: "bold",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                }}
                size="small"
              />
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2}>
                <Typography variant="h6" gutterBottom component="div">
                  ລາຍຊື່ນັກສຶກສາ
                </Typography>
                {exam.studentDetails.length > 0 ? (
                  <Table
                    size="medium"
                    aria-label="students"
                    sx={{
                      borderRadius: "8px",
                      overflow: "hidden",
                      margin: "20px 0",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#0d47a1",
                            backgroundColor: "#e3f2fd",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          ລະຫັດນັກສຶກສາ
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#0d47a1",
                            backgroundColor: "#e3f2fd",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          ຊື່ ແລະ ນາມສະກຸນ
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#0d47a1",
                            backgroundColor: "#e3f2fd",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          ເວລາໃຊ້ (ນາທີ)
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#0d47a1",
                            backgroundColor: "#e3f2fd",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          ປະສິດທິພາບ
                        </TableCell>

                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: "#0d47a1",
                            backgroundColor: "#e3f2fd",
                          }}
                        >
                          ສົ່ງຄຳເເນນນຳ
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {exam.studentDetails.map((student) => (
                        <TableRow
                          key={student.studentId}
                          hover
                          sx={{
                            "&:hover": {
                              backgroundColor: "#f1f8e9",
                            },
                          }}
                        >
                          <TableCell sx={{ textAlign: "center" }}>
                            <Typography
                              variant="body2"
                              sx={{ color: "#37474f" }}
                            >
                              {student.studentNumber}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                gap: "8px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ color: "#37474f" }}
                              >
                                {convertGender(student?.gender) +
                                  " " +
                                  student.firstName}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: "#37474f" }}
                              >
                                {student.lastName}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#1e88e5",
                                fontWeight: "bold",
                              }}
                            >
                              {student.timeToTake}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "bold",
                                color:
                                  student.efficiency === "100.00%"
                                    ? "#2e7d32"
                                    : "#c62828",
                              }}
                            >
                              {student.efficiency}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={async () => {
                                setShowModal(true);
                                setStudent(student);
                              }}
                              variant="contained"
                              sx={{
                                backgroundColor: "#3498db", // Modern blue color
                                color: "#fff",
                                padding: "10px 20px",
                                fontSize: "16px",
                                fontWeight: "bold",
                                borderRadius: "8px", // Slightly rounded corners
                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", // Subtle modern shadow
                                textTransform: "none", // Avoid all caps
                                transition:
                                  "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out", // Smooth animations
                                "&:hover": {
                                  backgroundColor: "#2980b9", // Darker blue on hover
                                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", // Stronger shadow on hover
                                  transform: "scale(1.05)", // Slight zoom effect
                                },
                              }}
                            >
                              ໃຫ້ຄຳເເນະນຳ
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography color="textSecondary">
                    ບໍ່ມີຂໍ້ມູນນັກສຶກສາ
                  </Typography>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Box sx={{ p: 4, backgroundColor: "#eef2f5" }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", mb: 3, color: "#0d47a1" }}
      >
        ລາຍງານເວລາສອບເສັງ
      </Typography>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={6}>
          <Paper
            sx={{ p: 2, backgroundColor: "#f8f9fa", textAlign: "center" }}
            elevation={1}
          >
            <Typography variant="subtitle1" color="textSecondary">
              ຈຳນວນສອບເສັງທັງຫມົດ
            </Typography>
            <Typography variant="h5" sx={{ color: "#0d47a1" }}>
              {totalExams}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            sx={{ p: 2, backgroundColor: "#f8f9fa", textAlign: "center" }}
            elevation={1}
          >
            <Typography variant="subtitle1" color="textSecondary">
              ຄວາມສະເລ່ຍປະສິດທິພາບເວລາທີໃຊ້
            </Typography>
            <Typography variant="h5" sx={{ color: "#0d47a1" }}>
              {averageTotalEfficiency}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <TableContainer component={Paper} elevation={1} sx={{ borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  color: "#0d47a1",
                  backgroundColor: "#e3f2fd",
                }}
              />
              <TableCell
                sx={{
                  fontWeight: "bold",
                  color: "#0d47a1",
                  backgroundColor: "#e3f2fd",
                }}
              >
                ຊື່ບົດສອບເສັງ
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  color: "#0d47a1",
                  backgroundColor: "#e3f2fd",
                }}
              >
                ໄລຍະເວລາ (ນາທີ)
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  color: "#0d47a1",
                  backgroundColor: "#e3f2fd",
                }}
              >
                ສະເລ່ຍເວລາໃຊ້
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  color: "#0d47a1",
                  backgroundColor: "#e3f2fd",
                }}
              >
                ປະສິດທິພາບ
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  color: "#0d47a1",
                  backgroundColor: "#e3f2fd",
                }}
              >
                ເເຍກຕາມຊ່ວງ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examDetails.map((exam) => (
              <Row key={exam.examId} exam={exam} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modern-modal-title"
        aria-describedby="modern-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "600px", // Max width of the modal
            maxHeight: "500px", // Max height of the modal
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)", // Modern shadow
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            id="modern-modal-title"
            variant="h6"
            component="h2"
            fontWeight="bold"
            sx={{ mb: 2, textAlign: "center" }}
          >
            {"ສົ່ງຄຳເເນນນຳໃຫ້ນັກສຶກສາ " +
              convertGender(student?.gender) +
              " " +
              student?.firstName +
              student?.lastName}
          </Typography>
          <TextField
            label="ຄຳເເນະນຳ"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={sugggestText}
            onChange={(e) => setSuggestText(e.target.value)}
            sx={{
              mb: 3,
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px", // Rounded corners
                "&:hover fieldset": {
                  borderColor: "#e67e22", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e67e22", // Border color when focused
                },
              },
            }}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: "auto" }}
          >
            <Button
              onClick={() => setShowModal(false)}
              variant="outlined"
              sx={{
                color: "#e74c3c",
                borderColor: "#e74c3c",
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#fdecea", // Light red for hover
                  borderColor: "#c0392b",
                },
              }}
            >
              ຍົກເລີກ
            </Button>
            <Button
              onClick={async () => {
                const response = await fetchPost(
                  "notification/send?userId=" + student?.studentNumber,
                  {
                    title: "ຄຳເເນະນຳຈາກອາຈານ",
                    path: "",
                    body: sugggestText,
                  }
                );
                if (response) {
                  setActionStatus({
                    open: true,
                    type: "success",
                    message: "ສົ່ງຄຳເເນະນຳສຳເລັດ",
                  });
                } else {
                  setActionStatus({
                    type: "error",
                    message: "ລະບົບມີບັນຫາ!",
                    open: true,
                  });
                }
                setShowModal(false);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#3498db",
                color: "#fff",
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#2980b9",
                },
              }}
            >
              ສົ່ງ
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default ExamTimeReport;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchGet } from "../../../common/fetchAPI";
import StudentAnswerQuestionList from "./studentAnswerList";
import StudentExamReport from "./studentExamResult";
import InfoIcon from "@mui/icons-material/Info";
import StudentHomeworkReport from "./studentHomeworkResult";

const ELearningReport = () => {
  const [classValue, setClassValue] = useState("");
  const [typeValue, setTypeValue] = useState("exam");
  const [examValue, setExamValue] = useState("");

  const { id } = useParams();

  const [classRoomList, setClassRoomList] = useState([]);
  const [examList, setExamList] = useState([]);

  const fetchExamList = async () => {
    console.log("typeValue => ", typeValue);
    const _examList = await fetchGet(
      `/${
        typeValue === "exam"
          ? "texam"
          : typeValue === "homework"
          ? "thomework"
          : "texam"
      }/course/${id}`
    );
    setExamList(_examList);
  };

  const fetchClassrooms = async () => {
    const _classList = await fetchGet("/tcourse/class/" + id);
    setClassRoomList(_classList);
  };

  useEffect(() => {
    fetchClassrooms();
    fetchExamList();
  }, [typeValue]);

  const handleClassChange = (event) => {
    setClassValue(event.target.value);
  };

  const handleTypeChange = (event) => {
    setTypeValue(event.target.value);
  };

  const handleExamChange = (event) => {
    setExamValue(event.target.value);
  };

  const renderReport = () => {
    if (typeValue === "question" && classValue) {
      return <StudentAnswerQuestionList classRoom={classValue} courseId={id} />;
    } else if (typeValue === "exam" && classValue && examValue) {
      return <StudentExamReport examId={examValue} classId={classValue} />;
    } else if (typeValue === "homework" && classValue && examValue) {
      return <StudentHomeworkReport examId={examValue} classId={classValue} />;
    }

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          mt: 4,
          color: "#1976d2",
          borderRadius: 1, // Softer corners for a flat look
          p: 2,
          boxShadow: "none", // Remove shadow for flat design
        }}
      >
        <InfoIcon sx={{ fontSize: 48, color: "#ff9800", mb: 1 }} />
        <Typography
          variant="h6"
          align="center"
          sx={{ color: "#aaa", fontWeight: "bold" }}
        >
          ກະລຸນາເລືອກປະເພດ, ຫ້ອງຮຽນ ແລະ ຫົວບົດເສັງເພື່ອເບິ່ງລາຍງານ.
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        paddingLeft: 1,
        paddingRight: 2,
      }}
    >
      <Card
        sx={{
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          p: 1,
          backgroundColor: "#f8fafc",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: "#1976d2", mb: 1 }}
          >
            ເລືອກປະເພດລາຍງານ & ຫ້ອງຮຽນ
          </Typography>
          <Grid container spacing={2}>
            {/* Type Selection */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel size="small">ເລືອກປະເພດລາຍງານ</InputLabel>
                <Select
                  size="small"
                  value={typeValue}
                  onChange={handleTypeChange}
                  label="ເລືອກປະເພດລາຍງານ"
                >
                  <MenuItem value="exam">ລາຍງານຜົນການເສັງ</MenuItem>
                  <MenuItem value="homework">ລາຍງານຜົນການສົ່ງວຽກບ້ານ</MenuItem>
                  <MenuItem value="question">ລາຍງານຜົນການຕອບຄຳຖາມ</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Class Selection */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel size="small">ເລືອກຫ້ອງຮຽນ</InputLabel>
                <Select
                  size="small"
                  value={classValue}
                  onChange={handleClassChange}
                  label="ເລືອກຫ້ອງຮຽນ"
                >
                  {classRoomList.map((_class) => (
                    <MenuItem key={_class._id} value={_class.classRoom?._id}>
                      {_class?.classRoom?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {typeValue === "exam" ? (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel size="small">ເລືອກບົດເສັງ</InputLabel>
                  <Select
                    size="small"
                    value={examValue}
                    onChange={handleExamChange}
                    label="ເລືອກບົດເສັງ"
                  >
                    {examList.map((exam) => (
                      <MenuItem key={exam._id} value={exam?._id}>
                        {exam?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : typeValue === "homework" ? (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel size="small">ເລືອກວຽກບ້ານ</InputLabel>
                  <Select
                    size="small"
                    value={examValue}
                    onChange={handleExamChange}
                    label="ເລືອກວຽກບ້ານ"
                  >
                    {examList.map((exam) => (
                      <MenuItem key={exam._id} value={exam?._id}>
                        {exam?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
      <Box marginTop={1}>{renderReport()}</Box>
    </Box>
  );
};

export default ELearningReport;

import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../common/Tabs";
import { Paper } from "@mui/material";
import { USER_KEY } from "../../consts";
import LoginLogsList from "./LoginLogsList";
import ResetPasswordLogsList from "./ResetPasswordLogsList";
import MamageUserLogList from "./ManageUserLogsList";
import MamageStudentLogList from "./ManageStudentLogsList";
import ApprovePrereStudentLogList from "./ApprovePrereStudentLogList";
import SendingScoreLogList from "./SendingScoreLogList";
import ManageTimeLogsList from "./ManageTimeLogsList";
import ManageCourseLogList from "./ManageCourseLogslist";
import PromoteStudentLogList from "./PromoteStudentLogs";
import RegisterStudentLogsList from "./RegisterStudentLogsList";
import ElearningTeacher from "./ElearningTeacher";
import ElearningStudent from "./ElearningStudent";

export default function LogList() {
  const [value, setValue] = useState(0);
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const role = userObject?.data?.role;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{ backgroundColor: "white", maxWidth: "100%", overflowX: "auto" }}
    >
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="ການເຂົ້າລະບົບ" />
          <Tab label="ການປ່ຽນລະຫັດຜ່ານ" />
          <Tab label="ການຈັດການຂໍ້ມຸນຜຸ້ໃຊ້" />
          <Tab label="ການຈັດການຂໍ້ມຸນນັກສຶກສາ" />
          <Tab label="ການຈັດການເວລາຮຽນ" />
          <Tab label="ການຈັດການວິຊາຮຽນ" />
          <Tab label="ການຢືນຢັນນັກສຶກສາ" />
          <Tab label="ການສົ່ງຄະເເນນ" />
          <Tab label="ການເລື່ອນຊັ້ນ" />
          <Tab label="ການລົງທະບຽນຮຽນ" />
          <Tab label="ອີເລີນນິງອາຈານ" />
          <Tab label="ອີເລີນນິງນັກຮຽນ" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <LoginLogsList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ResetPasswordLogsList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MamageUserLogList />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MamageStudentLogList />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ManageTimeLogsList />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ManageCourseLogList />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ApprovePrereStudentLogList />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <SendingScoreLogList />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <PromoteStudentLogList />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <RegisterStudentLogsList />
      </TabPanel>
      <TabPanel value={value} index={10}>
        <ElearningTeacher />
      </TabPanel>
      <TabPanel value={value} index={11}>
        <ElearningStudent />
      </TabPanel>
    </div>
  );
}

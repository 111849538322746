import React, { useState, useEffect } from 'react'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Col, Row, Form, Button, Modal, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import PreregistrationUpdateConfirmEn from './PreregistrationUpdateConfirmEn'
import { LAO_ADDRESS } from '../../consts/Address'
import { COUNTRIES_LIST } from "../../consts/Countries";
import useReactRouter from 'use-react-router'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { formatDateYYMMDD, valiDate } from '../../common/super'
import { CustomButton } from '../../common'
import { Formik } from 'formik'
import * as _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import { FACULTIES, DEPARTMENTS, TRIBES, MAJORS } from './apollo/query'
import { PRE_SIGNED_URL } from './apollo/mutation'
import "./utils/index.css";
import getStudentNumber from '../../helpers/getStudentNumber'

export default function PreregistrationEditEn() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const { history, location } = useReactRouter()
  const [selectFacultyIndex, setSelectFacultyIndex] = useState(-1)
  const [departments, setDepartments] = useState()
  const [dataMajors, setDataMajors] = useState()
  const dataStudent = location.state

  // const [tribeName, setTribeName] = useState()
  const [scholarship, setScholarship] = useState()
  const [preStudentNumber, setPreStudentNumber] = useState("");
  const [facultyId, setFacultyId] = useState();
  const [studentType, setStudentType] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [studentCode, setStudentCode] = useState("");

  // ====== State Address ======
  const [selectProvinceIndex, setSelectProvinceIndex] = useState()
  const [selectDistrict, setSelectDistrict] = useState()
  // ======  State BirthDistrict ======
  const [selectBirthProvinceIndex, setSelectBirthProvinceIndex] = useState()

  const [selectParentProvinceIndex, setSelectParentProvinceIndex] = useState()

  // ===== State StudentType =====
  const [graduatedYear, setGraduatedYear] = useState([])

  // state Model
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false)
  const [formParam, setFormParam] = useState({})
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false)
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true)

  /* ------- State Image ------*/
  const [fileUpload, setFileUpload] = useState(null)
  const [click, setClick] = useState('')
  const [passportUrl, setPassportUrl] = useState(null);
  const [passport, setPassport] = useState(null);
  const [qualificationUrl, setQualificationUrl] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [otherUrl, setOtherUrl] = useState(null);
  const [other, setOther] = useState(null);
  const [stayTogether, setStayTogether] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [approveNote, setApproveNote] = useState(false);

  const currentYear = new Date().getFullYear();

  /* -------Apollo--------- */
  const { data: dataFaculties } = useQuery(FACULTIES, {
    variables: {
      where: {
        assessmentYear: accessmentRedux,
        isDeleted: false,
      },
    }
  })
  const [loadDeparments, { data: dataDepartments }] = useLazyQuery(DEPARTMENTS)
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS)

  /*  ----------  image ---------*/
  const [loadPreSign, { data: dataPreSign }] = useLazyQuery(PRE_SIGNED_URL)

  const { data: dataTribe } = useQuery(TRIBES)
  // select faculty
  const _selectFaculty = e => {
    setDepartments([])
    const facultyIndex = _.findIndex(dataFaculties?.faculties, {
      id: e.target.value
    })
    if (e.target.value) {
      loadDeparments({
        variables: {
          where: {
            faculty: e.target.value,
            assessmentYear: accessmentRedux,
            isDeleted: false,
          },
        }
      })
    }
    // setDepartments(dataFaculties?.faculties[facultyIndex].departments)
    setSelectFacultyIndex(facultyIndex)
  }
  const _selectDepartment = e => {
    if (e.target.value) {
      loadMajors({
        variables: {
          where: {
            department: e.target.value,
            assessmentYear: accessmentRedux,
            isDeleted: false,
          },
        }
      })
    }
  }

  useEffect(() => {
    const facultyIndexDB = _.findIndex(dataFaculties?.faculties, {
      id: dataStudent?.faculty?.id
    })
    loadDeparments({
      variables: {
        where: {
          faculty: dataStudent?.faculty?.id,
          assessmentYear: accessmentRedux,
          isDeleted: false,
        },
      }
    })
    loadMajors({
      variables: {
        where: {
          department: dataStudent?.department?.id,
          assessmentYear: accessmentRedux,
          isDeleted: false,
        },
      }
    })
    // setDepartments(dataFaculties?.faculties[facultyIndexDB].departments)
    setSelectFacultyIndex(facultyIndexDB)
    if (dataStudent) {
      setStayTogether(dataStudent?.stayTogether)
      setFacultyId(dataStudent?.faculty?.id);
      setStudentType(dataStudent?.studentType);
      setEducationLevel(dataStudent?.educationLevel);
      // setTribeName(dataStudent?.tribe?.trib_name);
      const studentCode = dataStudent?.studentNumber
      if (dataStudent?.studentType === "IN_PLAN" || dataStudent?.studentType === "OUT_PLAN") {
        const firstFourDigits = studentCode.substring(4);
        setStudentCode(firstFourDigits)
      } else {
        
        const firstFourDigits = studentCode.substring(5);
        setStudentCode(firstFourDigits)
      }
    }

  }, [dataFaculties, dataStudent])

  useEffect(() => {
    setPreStudentNumber(
      getStudentNumber({
        faculty: facultyId,
        degree: educationLevel,
        studentType: studentType,
      })
    );
  }, [studentType, facultyId, educationLevel]);

  useEffect(() => {
    if (dataDepartments) {
      setDepartments(dataDepartments?.departments)
    }
  }, [dataDepartments])

  useEffect(() => {
    if (majorData) {
      setDataMajors(majorData?.majors?.data)
    }
  }, [majorData])

  const _renderDepartmentName = id => {
    let department = _.find(
      dataDepartments?.departments,
      function (o) {
        return o.id === id
      }
    )
    return department?.name
  }
  const _renderMajorName = id => {
    let major = _.find(
      dataMajors,
      function (o) {
        return o.id === id
      }
    )
    return major?.name
  }

  //  ======= Address ======
  const _selectProvince = e => {
    setSelectDistrict('')
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value
    })
    setSelectProvinceIndex(_provinceIndex)
  }
  useEffect(() => {
    const _provinceIndexDB = _.findIndex(LAO_ADDRESS, {
      province_name: dataStudent?.address?.province
    })
    setSelectProvinceIndex(_provinceIndexDB)
  }, [dataStudent])

  const _selectDistrict = e => {
    const _districts = LAO_ADDRESS[selectProvinceIndex].district_list.filter(
      _district => _district.district === e.target.value
    )
    const _villages = _districts[0]?.village
    setSelectDistrict(e.target.value)
  }

  // ======= BirthAddress ========
  const _selectBirthProvince = e => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value
    })
    setSelectBirthProvinceIndex(_provinceIndex)
  }

  const _selectParentDistrict = e => {
  }

  const _selectParentProvince = e => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value
    })
    setSelectParentProvinceIndex(_provinceIndex)
  }

  useEffect(() => {
    const _provinceIndexDB = _.findIndex(LAO_ADDRESS, {
      province_name: dataStudent?.birthAddress?.province
    })
    setSelectBirthProvinceIndex(_provinceIndexDB)
  }, [dataStudent])

  useEffect(() => {
    const _provinceIndexDB = _.findIndex(LAO_ADDRESS, {
      province_name: dataStudent?.parentAddress?.province
    })
  }, [dataStudent])

  const _selectBirthDistrict = e => {
  }

  const _selectGraduateFromProvince = e => {
  }

  useEffect(() => {
    let year = []
    const NOW = new Date().getFullYear()
    for (var i = NOW - 1; i >= 2014; i--) {
      year.push(i + ' - ' + (parseInt(i) + 1))
    }
    setGraduatedYear(year)
  }, [])

  // const _selectTribe = tribe => {
  //   const tribeIndex = _.findIndex(dataTribe?.tribes, {
  //     id: tribe.target.value
  //   })
  //   setTribeName(dataTribe?.tribes[tribeIndex]?.trib_name)
  // }

  // ============ ເລື່ອກຮູບພາບ ===========

  useEffect(() => {
    if (dataPreSign?.preregistrationPreSignedUrl) {
      fileGetResign(dataPreSign?.preregistrationPreSignedUrl)
    } else {
      setIsLoading(false)
    }
  }, [dataPreSign])

  const fileGetResign = async data => {
    try {
      if (click === '1') {
        setPassportUrl(data?.url.split('?')[0])
      }
      if (click === "3") {
        setQualificationUrl(data?.url.split("?")[0]);
      }
      if (click === "4") {
        setOtherUrl(data?.url.split("?")[0]);
      }

      await axios({
        method: 'put',
        url: data.url,
        data: fileUpload,
        headers: {
          'Content-Type': ' file/*; image/*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
          'Access-Control-Allow-Headers':
            'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
        }
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setPassport(dataStudent?.passportUrl?.url);
    setQualification(dataStudent?.qualificationUrl?.url);

    setPassportUrl(dataStudent?.passportUrl?.url);
    setQualificationUrl(dataStudent?.qualificationUrl?.url);

    setOtherUrl(dataStudent?.otherUrl?.url);
    setOther(dataStudent?.otherUrl?.url);
  }, [dataStudent])

  // ເລືອກໄຟລຈາກ ຄອມ
  const _handleChangeFile = async acceptedFiles => {
    try {
      setIsLoading(true)
      // ໄປຂໍ url ຈາກ s3
      let data = uuidv4() + '.' + acceptedFiles?.target?.files[0].type.split('/')[1]
      if (click === '1') {
        setPassport(data)
      }
      // if (click == '2') {
      // setScorecard(data)
      // }
      if (click === '3') {
        setQualification(data)
      }
      if (click === '4') {
        setOther(data)
      }
      setFileUpload(acceptedFiles?.target?.files[0])
      loadPreSign({
        variables: {
          name: data
        }
      })
    } catch (error) {
      console.log("error: ", error)
      setIsLoading(false)
    }
  }

  const selectScholarship = e => {
    setScholarship(e.target.value)
  }
  const _handleClickConfirm = dataStudent => {
    setFormParam(dataStudent)
  }
  const _clickStayTogether = (e) => {
    // setStayTogether(true);
    setStayTogether(!stayTogether);
  };
  return (
    <div>
      <Col sm={12} style={{ paddingBottom: 20, paddingTop: 20 }}>
        <a
          onClick={() => {
            history.push(`/students-prere`)
            window.location.reload()
          }}
        >
          Registration Student
        </a>
        /{' '}
        <a>
          {dataStudent && dataStudent ? dataStudent?.firstNameE : '-'}{' '}
          {dataStudent?.lastNameE}
        </a>{' '}
        / Registration Edit Form
      </Col>
      <div className='container'>
        <div className='col-sm-12 card' style={{ color: '#707070' }}>
          {/* {studentData && */}
          {
            <Formik
              enableReinitialize
              initialValues={{
                examCode: dataStudent?.examCode ?? '',
                id: dataStudent?.id ?? '',
                studentNumber: studentCode ?? '',
                faculty: dataStudent?.faculty?.id ?? '',
                department: dataStudent?.department?.id ?? '',
                major: dataStudent?.major?.id ?? '',
                code: dataStudent?.code,
                studentInfo: dataStudent?.studentInfo ?? '',
                educationLevel: dataStudent?.educationLevel ?? '',
                firstNameE: dataStudent?.firstNameE ?? '',
                lastNameE: dataStudent?.lastNameE ?? '',
                day: dataStudent?.birthday ? dataStudent.birthday.split('-')[2] : "",
                month: dataStudent?.birthday ? dataStudent.birthday.split('-')[1] : "",
                year: dataStudent?.birthday ? dataStudent.birthday.split('-')[0] : "",
                gender: dataStudent?.gender ?? 'MALE',
                maritualStatus: dataStudent?.maritualStatus ?? 'SINGLE',
                nationality: dataStudent?.nationality ?? '',
                // tribe: dataStudent?.tribe?.id ?? '',
                religion: dataStudent?.religion ?? '',
                phoneHome: dataStudent?.phoneHome ?? '',
                phoneMobile: dataStudent?.phoneMobile ?? '',
                email: dataStudent?.email ?? '',
                country: dataStudent?.address?.country ?? '',
                province: dataStudent?.address?.province ?? '',
                district: dataStudent?.address?.district ?? '',
                village: dataStudent?.address?.village ?? '',
                birthCountry: dataStudent?.birthAddress?.country ?? '',
                birthProvince: dataStudent?.birthAddress?.province ?? '',
                birthDistrict: dataStudent?.birthAddress?.district ?? '',
                birthVillage: dataStudent?.birthAddress?.village ?? '',
                parentCountry: dataStudent?.parentAddress?.country ?? '',
                parentProvince: dataStudent?.parentAddress?.province ?? '',
                parentDistrict: dataStudent?.parentAddress?.district ?? '',
                parentVillage: dataStudent?.parentAddress?.village ?? '',
                scholarship: dataStudent?.scholarship ?? '',
                scholarshipFrom: dataStudent?.scholarshipFrom ?? "",
                outPlanScholarship: dataStudent?.scholarship ?? "",
                otherScholarship: dataStudent?.otherScholarship ?? '',
                graduateFromCountry: dataStudent?.graduateFromCountry ?? '',
                graduateFromProvince: dataStudent?.graduateFromProvince ?? '',
                graduateFromSchool: dataStudent?.graduateFromSchool ?? '',
                semesterYear: dataStudent?.semesterYear ?? '',
                outPlanGraduateFromCountry:
                  dataStudent?.graduateFromCountry ?? '',
                outPlanGraduateFromProvince:
                  dataStudent?.graduateFromProvince ?? '',
                outPlanGraduateFromSchool:
                  dataStudent?.graduateFromSchool ?? '',
                outPlanSemesterYear: dataStudent?.semesterYear ?? '',
                graduatedMajor: dataStudent?.graduatedMajor ?? '',
                contactName: dataStudent?.contactName ?? '',
                emergencyPhone: dataStudent?.emergencyPhone ?? '',
                stayTogether: dataStudent?.stayTogether ?? false,
                emergencyCountry: dataStudent?.emergencyAddress?.country ?? '',
                emergencyProvince: dataStudent?.emergencyAddress?.province ?? '',
                emergencyDistrict: dataStudent?.emergencyAddress?.district ?? '',
                emergencyVillage: dataStudent?.emergencyAddress?.village ?? '',
                fatherNameAndSurname: dataStudent?.fatherNameAndSurname ?? '',
                motherNameAndSurname: dataStudent?.motherNameAndSurname ?? '',
                parentTelephone: dataStudent?.parentTelephone ?? '',
                transferLetterNo: dataStudent?.transferLetterNo ?? '',
                scholarshipAgreementNo:
                  dataStudent?.scholarshipAgreementNo ?? '',
                dateTransferLetterNo:
                  formatDateYYMMDD(dataStudent?.dateTransferLetterNo) ?? '',
                dateScholarshipAgreementNo:
                  formatDateYYMMDD(dataStudent?.dateScholarshipAgreementNo) ??
                  '',
                dormitory: dataStudent?.dormitory ?? ''
              }}
              validate={(values) => {
                const errors = {};
                if (!values.faculty) {
                  errors.faculty = "ກະລຸນາເລືອກຄະນະ"
                }
                if (!values.department) {
                  errors.department = "ກະລຸນາເລືອກພາກວິຊາ"
                }
                if (!values.major) {
                  errors.major = "ກະລຸນາເລືອກສາຂາວິຊາ"
                }
                if (!values.studentInfo) {
                  errors.studentInfo = "ກະລຸນາເລືອກສາຂາວິຊາ"
                }
                if (!values.educationLevel) {
                  errors.educationLevel = "ກະລຸນາເລືອກລະດັບສຶກສາ"
                }
                if (!values.day) {
                  errors.day = "ກະລຸນາເລືອກວັນ"
                }
                if (!values.month) {
                  errors.month = "ກະລຸນາເລືອກເດີືອນ"
                }
                if (!values.year) {
                  errors.year = "ກະລຸນາເລືອກປີ"
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                _handleShowAddConfirmModalShow()
                let address = {
                  country: values.country,
                  province: values.province,
                  district: values.district,
                  village: values.village
                }

                values = {
                  ...values,
                  address: address
                }
                delete values.village
                delete values.district
                // }
                // if (selectBirthProvince) {
                let birthAddress = {
                  country: values.birthCountry,
                  province: values.birthProvince,
                  district: values.birthDistrict,
                  village: values.birthVillage
                }

                let parentAddress = {
                  country: values.parentCountry,
                  province: values.parentProvince,
                  district: values.parentDistrict,
                  village: values.parentVillage
                }
                values = {
                  ...values,
                  parentAddress: parentAddress
                }

                values = {
                  ...values,
                  birthAddress: birthAddress
                }
                if (values.educationLevel === 'BACHELOR_CONTINUING') {
                  values = { ...values }
                } else {
                  delete values.graduatedMajor
                }

                let emergencyAddress = {}
                if (values.stayTogether === false) {
                  emergencyAddress = {
                    country: values.emergencyCountry,
                    province: values.emergencyProvince,
                    district: values.emergencyDistrict,
                    village: values.emergencyVillage
                  }
                  values = {
                    ...values,
                    emergencyAddress: emergencyAddress
                  }

                }
                delete values.emergencyVillage
                delete values.emergencyDistrict
                delete values.emergencyProvince
                delete values.emergencyCountry

                const birthday = `${values.year}-${values.month}-${values.day}`;

                if (approveNote === true) {
                  values = { ...values, approveNote: false }
                }
                let data = {
                  ...values, birthday,
                  faculty: dataFaculties?.faculties[selectFacultyIndex]?.id,
                  // tribeName: tribeName,
                  department: values.department,
                  major: values.major,
                  facultyname:
                    dataFaculties?.faculties[selectFacultyIndex]?.name,
                  departmentname: _renderDepartmentName(values.department),
                  majorname: _renderMajorName(values.major),
                  // tribe: values.tribe,
                  passportUrl: { url: passport },
                  qualificationUrl: { url: qualification },
                  otherUrl: { url: other },
                  studentType: dataStudent?.studentType,
                  scholarship: dataStudent?.studentType === "OUT_PLAN_EN"
                    ? values.outPlanScholarship
                    : values.scholarship,
                  preStudentNumber: preStudentNumber
                }
                delete data.outPlanScholarship;
                delete data.birthDistrict
                delete data.birthVillage
                delete data.district
                delete data.village
                delete data.parentDistrict
                delete data.parentProvince
                delete data.parentVillage
                delete data.outPlanGraduateFromProvince
                delete data.outPlanGraduateFromSchool
                delete data.outPlanSemesterYear
                await setFormParam({ ...data, passport, qualification, other })
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
              }) => (
                <div
                  className='card-body'
                  style={{ marginLeft: 25, marginRight: 20 }}
                >
                  {dataStudent?.approveNote === true ? (
                    <div style={{ border: "1px solid red", borderRadius: 5, padding: 5, marginTop: 15, marginBottom: 20 }}>
                      <p style={{ color: "red", fontSize: 20 }}>Please remove the approval note if all the information is correct.</p>
                      <p style={{ color: "red" }}>{dataStudent?.approveNoteDetails?.incorrectBirthday ?? ""}</p>
                      <p style={{ color: "red" }}>{dataStudent?.approveNoteDetails?.incorrectName ?? ""}</p>
                      <p style={{ color: "red" }}>{dataStudent?.approveNoteDetails?.incorrectFile ?? ""}</p>
                      <p style={{ color: "red" }}>{dataStudent?.approveNoteDetails?.remark ?? ""}</p>
                      <input type="checkbox" checked={approveNote} onChange={(e) => setApproveNote(e.target.checked)} />
                      <label className="pl-2">Remove the approval note</label>
                    </div>
                  ) : null}
                  <div
                    className='col-sm-12 textfont-size16 font-color'
                    style={{
                      backgroundColor: '#D8D8D8',
                      padding: 10,
                      borderRadius: 5,
                      marginBottom: 4,
                      color: "#707070"
                    }}
                  >
                    Student Information
                  </div>
                  <div className='text-muted mgn'>
                    Where there is
                    <span className='text-danger'> * </span>must have information
                  </div>
                  <Row>
                    <Col sm='12'>
                      <Row sm='10'>
                        <Col sm='4'>
                          <Form.Label fontSize='14' className='font-color'>
                            Faculty <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='faculty'
                            isInvalid={errors.faculty}
                            value={values.faculty}
                            onChange={e => {
                              handleChange(e)
                              _selectFaculty(e)
                              values.department = ""
                              values.major = ""
                              setFacultyId(e.target.value)
                            }}
                          >
                            <option disabled={false} value=''>
                              Select
                            </option>
                            {dataFaculties?.faculties?.map((item, index) => {
                              return (
                                <option key={'faculty' + index} value={item.id}>
                                  {item.name}
                                </option>
                              )
                            })}
                          </Form.Control>
                        </Col>
                        <Col sm='4'>
                          <Form.Label fontSize='14' className='font-color'>
                            Department
                            <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='department'
                            isInvalid={errors.department}
                            value={values.department}
                            onChange={e => {
                              handleChange(e)
                              _selectDepartment(e)
                              values.major = ""
                            }}
                          // value={departments}
                          >
                            <option value={""}>Select</option>
                            {departments &&
                              departments?.map((item, index) => {
                                return (
                                  <option
                                    key={'department' + index}
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                )
                              })}
                          </Form.Control>
                        </Col>
                        <Col sm='4'>
                          <Form.Label fontSize='14' className='font-color'>
                            Major
                            <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='major'
                            isInvalid={errors.major}
                            value={values.major}
                            onChange={e => {
                              handleChange(e)
                            }}
                          >
                            <option value={false}>ທັງໝົດ</option>
                            {dataMajors &&
                              dataMajors?.map((item, index) => {
                                return (
                                  <option
                                    key={'major' + index}
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                )
                              })}
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        <Col sm='4'>
                          <Form.Label
                            fontSize='14'
                            className='font-color'
                            name=''
                          >
                            Student's information
                            <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='studentInfo'
                            value={values.studentInfo}
                            isInvalid={errors.studentInfo}
                            onChange={handleChange}
                          >
                            <option value=''>Select</option>
                            <option value='EMPLOYEE'>
                              Employed
                            </option>
                            <option value='ORDINARY_STUDENT'>
                              Normal student
                            </option>
                          </Form.Control>
                        </Col>
                        <Col sm='4'>
                          <Form.Label fontSize='14' className='font-color'>
                            Level <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as='select'
                            value={values.educationLevel}
                            name='educationLevel'
                            isInvalid={errors.educationLevel}
                            onChange={(e) => {
                              handleChange(e)
                              setEducationLevel(e.target.value)
                            }}
                          >
                            <option value=''>Select</option>
                            <option value='DEPLOMA'>Associate Degree</option>
                            <option value='BACHELOR_DEGREE'>Bachelor Degree</option>
                            <option value='BACHELOR_CONTINUING'>
                              Continuing Bachelor Degree
                            </option>
                          </Form.Control>
                        </Col>
                        <Col sm='4'>
                          <Form.Label fontSize='14' className='font-color'>
                            Registration Number
                          </Form.Label>
                          <Form.Control
                            type='hidden'
                            placeholder='Please input registration number'
                            name='examCode'
                            value={values.examCode}
                            onChange={handleChange}
                            isInvalid={errors.examCode}
                          />

                          <InputGroup>
                            {preStudentNumber && (
                              <InputGroup.Text
                                style={{ height: "40px", lineHeight: "40px" }}
                              >
                                {preStudentNumber}
                              </InputGroup.Text>
                            )}
                            <Form.Control
                              type="text"
                              className="form-control"
                              name="studentNumber"
                              value={values.studentNumber}
                              isInvalid={!!errors.studentNumber}
                              placeholder="Please input registration number"
                              style={{ height: "40px" }} // Ensuring height is the same
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Col
                    className='col-sm-12 titlebt'
                    style={{
                      backgroundColor: '#DEDEDEDE',
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 20
                    }}
                  >
                    General Information
                  </Col>
                  <Row style={{ marginTop: 22 }}>
                    <Col sm='6' style={{ marginTop: 20 }}>
                      <Form.Label fontSize='14' className='font-color'>
                        Given name <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={values.firstNameE}
                        placeholder='Please input your name'
                        name='firstNameE'
                        maxLength='20'
                        onChange={handleChange}
                        isInvalid={errors.firstNameE}
                      />
                    </Col>
                    <Col sm='6' style={{ marginTop: 20 }}>
                      <Form.Label fontSize='14' className='font-color'>
                        Family name
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={values.lastNameE}
                        placeholder='Please input family name'
                        name='lastNameE'
                        maxLength='20'
                        onChange={handleChange}
                        isInvalid={errors.lastNameE}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Form.Label>Date of birth {valiDate()}</Form.Label>
                    <Col sm={3}>
                      <Form.Control
                        as="select"
                        onChange={handleChange}
                        name="day"
                        value={values.day}
                        isInvalid={!!errors.day}
                      >
                        <option value="">Day</option>
                        {Array.from({ length: 31 }, (_, i) => {
                          const value = String(i + 1).padStart(2, '0');
                          return (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Control
                        as="select"
                        onChange={handleChange}
                        name="month"
                        value={values.month}
                        isInvalid={!!errors.month}
                      >
                        <option value="">Month</option>
                        {Array.from({ length: 12 }, (_, i) => {
                          const value = String(i + 1).padStart(2, '0');
                          return (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Control
                        as="select"
                        onChange={handleChange}
                        name="year"
                        value={values.year}
                        isInvalid={!!errors.year}
                      >
                        <option value="">Year</option>
                        {Array.from({ length: 100 }, (_, i) => (
                          <option key={currentYear - i} value={currentYear - i}>
                            {currentYear - i}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col sm={3}>
                      <label className='font-color'>
                        Gender <span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label='Male'
                          type='radio'
                          value='MALE'
                          id='gender-radio'
                          name='gender'
                          onChange={handleChange}
                          checked={values.gender === 'MALE' ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label='Female'
                          type='radio'
                          value='FEMALE'
                          id='gender-radio'
                          name='gender'
                          onChange={handleChange}
                          checked={values.gender === 'FEMALE' ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col sm={3}>
                      <label className='font-color'>
                        Marital status <span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label='Single'
                          type='radio'
                          value='SINGLE'
                          id='maritualStatus-radio'
                          name='maritualStatus'
                          onChange={handleChange}
                          checked={
                            values.maritualStatus === 'SINGLE' ? true : false
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label='Married'
                          type='radio'
                          id='maritualStatus-radio'
                          value='MARRIAGE'
                          name='maritualStatus'
                          onChange={handleChange}
                          checked={
                            values.maritualStatus === 'MARRIAGE' ? true : false
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col sm={3}>
                      <label className='font-color'>
                        Dormitory <span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label='In dormitory'
                          type='radio'
                          value='YES'
                          id='dormitory-radio'
                          name='dormitory'
                          onChange={handleChange}
                          checked={values.dormitory === 'YES' ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label='Off dormitory'
                          type='radio'
                          id='dormitory-radio'
                          value='NO'
                          name='dormitory'
                          onChange={handleChange}
                          checked={values.dormitory === 'NO' ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 22 }}>
                    <Col sm='6'>
                      <Form.Label fontSize='14' className='font-color'>
                        Nationality <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        as='select'
                        value={values.nationality}
                        name='nationality'
                        onChange={handleChange}
                        isInvalid={errors.nationality}
                      >
                        <option value="">---Select---</option>
                        {COUNTRIES_LIST?.map((nationalities, index) => (
                          <option
                            key={"nationalities" + index}
                            value={nationalities?.nationality}
                          >
                            {nationalities?.nationality}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col sm='6'>
                      <Form.Label fontSize='14' className='font-color'>
                        Religion <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        as='select'
                        value={values.religion}
                        name='religion'
                        onChange={handleChange}
                        isInvalid={errors.religion}
                      >
                        <option value=''>Select</option>
                        <option value='​ພຸດ'>​ສາ​ສ​ະ​ໜາ​ພຸດ</option>
                        <option value='ຜີ'>ສາ​ສະ​ໜາຜີ</option>
                        <option value='ຄຣິສະ​ຕຽນ'>ສາ​ສະ​ໜາຄຣິສະ​ຕຽນ</option>
                        <option value='ອິດສະລາມ'>ສາ​ສະ​ໜາອິດສະລາມ</option>
                      </Form.Control>
                    </Col>
                    <Col sm='4' style={{ marginTop: 20 }}>
                      <Form.Label fontSize='14' className='font-color'>
                        Home phone
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={values.phoneHome}
                        placeholder='Please input home phone'
                        name='phoneHome'
                        maxLength='11'
                        isInvalid={errors.phoneHome}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm='4' style={{ marginTop: 20 }}>
                      <Form.Label fontSize='14' className='font-color'>
                        Mobile phone <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={values.phoneMobile}
                        placeholder='Please input mobile phone'
                        name='phoneMobile'
                        maxLength='11'
                        isInvalid={errors.phoneMobile}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm='4' style={{ marginTop: 20 }}>
                      <Form.Label fontSize='14' className='font-color'>
                        Email
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={values.email}
                        placeholder='Please input email'
                        name='email'
                        maxLength='30'
                        isInvalid={errors.email}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Col
                    className='col-sm-12 titlebt'
                    style={{
                      backgroundColor: '#D8D8D8',
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16
                    }}
                  >
                    Current address
                  </Col>
                  <Row style={{ marginTop: 12 }}>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        Country <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="country"
                        onChange={handleChange}
                        value={values.country}
                        isInvalid={errors.country}
                      >
                        <option value="">---Select---</option>
                        {COUNTRIES_LIST.map((countries, index) => (
                          <option
                            key={"countries" + index}
                            value={countries?.en_short_name}
                          >
                            {countries?.en_short_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        Province <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='province'
                        value={values.province}
                        isInvalid={errors.province}
                        placeholder='Please input province'
                        onChange={handleChange}
                        maxLength='40'
                      />
                    </Col>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        District <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='district'
                        isInvalid={errors.district}
                        placeholder='Please input district'
                        onChange={handleChange}
                        value={values.district}
                        maxLength='40'
                      />
                    </Col>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        Village <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='village'
                        isInvalid={errors.village}
                        placeholder='Please input village'
                        onChange={handleChange}
                        value={values.village}
                        maxLength='30'
                      />
                    </Col>
                  </Row>
                  <Col
                    className='col-sm-12 titlebt'
                    style={{
                      backgroundColor: '#D8D8D8',
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16
                    }}
                  >
                    Place of Birth
                  </Col>
                  <Row style={{ marginTop: 12 }}>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        Country <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="birthCountry"
                        onChange={handleChange}
                        value={values.birthCountry}
                        isInvalid={errors.birthCountry}
                      >
                        <option value="">---Select---</option>
                        {COUNTRIES_LIST.map((countries, index) => (
                          <option
                            key={"countries" + index}
                            value={countries?.en_short_name}
                          >
                            {countries?.en_short_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        Province <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='birthProvince'
                        value={values.birthProvince}
                        isInvalid={errors.birthProvince}
                        placeholder='Please input province'
                        onChange={handleChange}
                        maxLength='40'
                      />
                    </Col>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        District <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='birthDistrict'
                        isInvalid={errors.birthDistrict}
                        value={values.birthDistrict}
                        placeholder='Please input district'
                        onChange={handleChange}
                        maxLength='40'
                      />
                    </Col>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        Village <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='birthVillage'
                        value={values.birthVillage}
                        isInvalid={errors.birthVillage}
                        placeholder='Please input village'
                        onChange={handleChange}
                        maxLength='30'
                      />
                    </Col>
                  </Row>
                  {dataStudent?.studentType === 'OUT_PLAN_EN' ? (
                    <div>
                      <Col
                        className='col-sm-12 titlebt'
                        style={{
                          backgroundColor: '#D8D8D8',
                          padding: 10,
                          borderRadius: 5,
                          marginTop: 30,
                          fontSize: 16
                        }}
                      >
                        For self-financing student
                      </Col>
                      <Row style={{ marginTop: 12 }}>
                        <div className="col-md-12 mt-3">
                          <Form.Control
                            as="select"
                            id="disabledSelect"
                            name="outPlanScholarship"
                            value={values.outPlanScholarship}
                            isInvalid={errors.outPlanScholarship}
                            onChange={(e) => {
                              selectScholarship(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">Select Scholarship</option>
                            <option value="ORGANIZATION_IN_LAOPDR_MINISTRIES">
                              The organization in the Lao PDR(Ministries)
                            </option>
                            <option value="ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION">
                              The organization in the Lao PDR(Provincial Education Division)
                            </option>
                            <option value="HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD">
                              Higher Education institution form abroad
                            </option>
                            <option value="PERSONAL_FINANCIAL">
                              Personal financial
                            </option>
                          </Form.Control>
                        </div>
                        <div className="col-md-12 mt-3">
                          <Form.Control
                            type={
                              values.outPlanScholarship === "ORGANIZATION_IN_LAOPDR_MINISTRIES"
                                || values.outPlanScholarship === "ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION"
                                || values.outPlanScholarship === "HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD"
                                ? "text" : "hidden"
                            }
                            placeholder="Please Input"
                            value={values.scholarshipFrom}
                            name="scholarshipFrom"
                            isInvalid={errors.scholarshipFrom}
                            onChange={handleChange}
                          />
                        </div>
                        <Col sm='3'>
                          <Form.Label fontSize='14' className='font-color'>
                            Country <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="graduateFromCountry"
                            isInvalid={errors.graduateFromCountry}
                            onChange={handleChange}
                            value={values.graduateFromCountry}
                          >
                            <option value="">---Select---</option>
                            {COUNTRIES_LIST.map((countries, index) => (
                              <option
                                key={"countries" + index}
                                value={countries?.en_short_name}
                              >
                                {countries?.en_short_name}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm='3'>
                          <Form.Label fontSize='14' className='font-color'>
                            Province <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Please input graduate province"
                            value={values.graduateFromProvince}
                            name="graduateFromProvince"
                            isInvalid={errors.graduateFromProvince}
                            onChange={handleChange}
                            maxLength='40'
                          />
                        </Col>
                        <Col sm='3'>
                          <Form.Label fontSize='14' className='font-color'>
                            Graduate from <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Please input graduate from'
                            value={values.graduateFromSchool}
                            name='graduateFromSchool'
                            isInvalid={errors.graduateFromSchool}
                            onChange={handleChange}
                            maxLength='40'
                          />
                        </Col>
                        <Col sm='3'>
                          <Form.Label fontSize='14' className='font-color'>
                            Academic year <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='semesterYear'
                            isInvalid={errors.semesterYear}
                            onChange={handleChange}
                            value={values.semesterYear}
                          >
                            <option>Select</option>
                            {graduatedYear.map((x, index) => {
                              return (
                                <option value={x} key={index}>
                                  {x}
                                </option>
                              )
                            })}
                          </Form.Control>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      <Col
                        className='col-sm-12 titlebt'
                        style={{
                          backgroundColor: '#D8D8D8',
                          padding: 10,
                          borderRadius: 5,
                          marginTop: 20
                        }}
                      >
                        For student/Civil servant who receives scholarship from an organization
                      </Col>
                      <Row style={{ marginTop: 22 }}>
                        <Col sm='12' style={{ marginTop: 20 }}>
                          <Form.Control
                            as="select"
                            id="disabledSelect"
                            name="scholarship"
                            value={values.scholarship}
                            isInvalid={errors.scholarship}
                            onChange={(e) => {
                              selectScholarship(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">---Select---</option>
                            <option value="NUOL_SCHOLARSHIP">
                              NUOL's talent student
                            </option>
                            <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                              Provincial ethnic student
                            </option>
                            <option value="RED_DIPLOMA_SCHOLARSHIP">
                              First-class honours
                            </option>
                            <option value="ANTIONAL_STUDENT_SCHOLARSHIP">
                              Outstanding student in general subject/talent student
                            </option>
                            <option value="MINISTRY_OF_EDUCATION_AND_SPORTS">
                              Ministry of Education and Sports
                            </option>
                            <option value="OTHER_SHOLARSHIP">
                              Other scholarship
                            </option>
                          </Form.Control>
                        </Col>
                        <Col sm='3' style={{ marginTop: 20 }}>
                          <Form.Label fontSize='14' className='font-color'>
                            Country <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="graduateFromCountry"
                            isInvalid={errors.graduateFromCountry}
                            onChange={handleChange}
                            value={values.graduateFromCountry}
                          >
                            <option value="">---Select---</option>
                            {COUNTRIES_LIST.map((countries, index) => (
                              <option
                                key={"countries" + index}
                                value={countries?.en_short_name}
                              >
                                {countries?.en_short_name}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm='3' style={{ marginTop: 20 }}>
                          <Form.Label fontSize='14' className='font-color'>
                            Province <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Please input graduate province"
                            value={values.graduateFromProvince}
                            name="graduateFromProvince"
                            isInvalid={errors.graduateFromProvince}
                            onChange={handleChange}
                            maxLength='40'
                          />
                        </Col>
                        <Col sm='3' style={{ marginTop: 20 }}>
                          <Form.Label fontSize='14' className='font-color'>
                            Graduate from
                            <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Please input graduate from'
                            value={values.graduateFromSchool}
                            name='graduateFromSchool'
                            isInvalid={errors.graduateFromSchool}
                            onChange={handleChange}
                            maxLength='40'
                          />
                        </Col>
                        <Col sm='3' style={{ marginTop: 20 }}>
                          <Form.Label fontSize='14' className='font-color'>
                            Academic year <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='semesterYear'
                            isInvalid={errors.semesterYear}
                            onChange={handleChange}
                            value={values.semesterYear}
                          >
                            <option value=''>Select</option>
                            {graduatedYear.map((x, index) => {
                              return (
                                <option value={x} key={index}>
                                  {x}
                                </option>
                              )
                            })}
                          </Form.Control>
                        </Col>
                        <Col sm="6" style={{ marginTop: 20 }}>
                          <Form.Label>Transfer Letter No </Form.Label>
                          <Form.Control
                            type="text"
                            name="transferLetterNo"
                            placeholder="Please input transfer letter no"
                            isInvalid={errors.transferLetterNo}
                            onChange={handleChange}
                            value={values.transferLetterNo}
                            maxLength='20'
                          />
                        </Col>
                        <Col sm="6" style={{ marginTop: 20 }}>
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={values.dateTransferLetterNo}
                            placeholder="12/02/2000"
                            name="dateTransferLetterNo"
                            onChange={handleChange}
                            isInvalid={errors.birthday}
                          />
                        </Col>
                        <Col sm="6" style={{ marginTop: 20 }}>
                          <Form.Label>Scholarship Agreement No</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Please input Scholarship Agreement No"
                            name="scholarshipAgreementNo"
                            value={values.scholarshipAgreementNo}
                            isInvalid={errors.scholarshipAgreementNo}
                            onChange={handleChange}
                            maxLength='20'
                          />
                        </Col>
                        <Col sm="6" style={{ marginTop: 20 }}>
                          <Form.Label>Date </Form.Label>
                          <Form.Control
                            type="date"
                            name="dateScholarshipAgreementNo"
                            isInvalid={errors.dateScholarshipAgreementNo}
                            onChange={handleChange}
                            value={values.dateScholarshipAgreementNo}
                          />
                        </Col>
                        <Col sm='12' style={{ marginTop: 20 }}>
                          <Form.Label fontSize='14' className='font-color'>
                            Other scholarship
                            <span className='text-danger'>*</span>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Please input other scholarship'
                            name='otherScholarship'
                            isInvalid={errors.otherScholarship}
                            onChange={handleChange}
                            value={values.otherScholarship}
                            maxLength='50'
                            disabled={
                              scholarship === ''
                                ? true
                                : scholarship === 'OTHER_SHOLARSHIP'
                                  ? false
                                  : true
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {values?.educationLevel === "BACHELOR_CONTINUING" ? (
                    <Row>
                      <Col>
                        <Form.Label fontSize='14' className='font-color'>
                          Graduated major
                          <span className='text-danger'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Please input graduated major'
                          name='graduatedMajor'
                          isInvalid={errors.graduatedMajor}
                          onChange={handleChange}
                          value={values.graduatedMajor}
                        />
                      </Col>
                    </Row>
                  ) :
                    (<></>)
                  }
                  <Col
                    className='col-sm-12 titlebt'
                    style={{
                      backgroundColor: '#D8D8D8',
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16
                    }}
                  >
                    Parents's information
                  </Col>
                  <Row>
                    <Col sm='4'>
                      <Form.Label fontSize='14' className='font-color'>
                        Father name
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Please input father name'
                        name='fatherNameAndSurname'
                        isInvalid={errors.fatherNameAndSurname}
                        onChange={handleChange}
                        maxLength='40'
                        value={values.fatherNameAndSurname}
                      />
                    </Col>
                    <Col sm='4'>
                      <Form.Label fontSize='14' className='font-color'>
                        Mother name
                        <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Please input mother name'
                        name='motherNameAndSurname'
                        isInvalid={errors.motherNameAndSurname}
                        onChange={handleChange}
                        maxLength='40'
                        value={values.motherNameAndSurname}
                      />
                    </Col>
                    <Col sm='4'>
                      <Form.Label fontSize='14' className='font-color'>
                        Mobile Phone <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Please input mobile phone'
                        name='parentTelephone'
                        isInvalid={errors.parentTelephone}
                        onChange={handleChange}
                        value={values.parentTelephone}
                        maxLength='11'
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }}>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        Country <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="parentCountry"
                        onChange={handleChange}
                        value={values.parentCountry}
                        isInvalid={errors.parentCountry}
                      >
                        <option value="">---Select---</option>
                        {COUNTRIES_LIST.map((countries, index) => (
                          <option
                            key={"countries" + index}
                            value={countries?.en_short_name}
                          >
                            {countries?.en_short_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        Province <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='parentProvince'
                        value={values.parentProvince}
                        isInvalid={errors.parentProvince}
                        placeholder='Please input province'
                        onChange={handleChange}
                        maxLength='40'
                      />
                    </Col>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        District <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='parentDistrict'
                        isInvalid={errors.parentDistrict}
                        value={values.parentDistrict}
                        placeholder='Please input district'
                        onChange={handleChange}
                        maxLength='40'
                      />
                    </Col>
                    <Col sm='3'>
                      <Form.Label fontSize='14' className='font-color'>
                        Village <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='parentVillage'
                        value={values.parentVillage}
                        isInvalid={errors.parentVillage}
                        placeholder='Please input village'
                        onChange={handleChange}
                        maxLength='30'
                      />
                    </Col>
                  </Row>

                  <Col
                    className='col-sm-12 titlebt'
                    style={{
                      backgroundColor: '#D8D8D8',
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16
                    }}
                  >
                    Emergency contact
                  </Col>
                  <Row style={{ marginTop: 22 }}>
                    <Col sm='6'>
                      <Form.Label fontSize='14' className='font-color'>
                        Name <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={values.contactName}
                        name='contactName'
                        isInvalid={errors.contactName}
                        placeholder='Please input name'
                        onChange={handleChange}
                        maxLength='40'
                      />
                    </Col>
                    <Col sm='6'>
                      <Form.Label fontSize='14' className='font-color'>
                        Mobile Phone <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={values.emergencyPhone}
                        name='emergencyPhone'
                        isInvalid={errors.emergencyPhone}
                        placeholder='Please input mobile phone'
                        onChange={handleChange}
                        maxLength='11'
                      />
                    </Col>
                    <Form.Group
                      controlId='formBasicCheckbox'
                      style={{ marginTop: 25 }}
                    >
                      <Form.Check
                        type='checkbox'
                        label='Same address'
                        name='stayTogether'
                        value={values.stayTogether}
                        checked={values.stayTogether === true ? true : false}
                        onClick={() => _clickStayTogether()}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    {stayTogether === false ? (
                      <>
                        <Form.Label fontSize="14" className="text-bold">
                          <span className="text-danger">*</span> Note: if not staying together. Please input the address below
                        </Form.Label>
                        <div className="col-md-3 mt-3">
                          <Form.Label fontSize="14" className="font-color">
                            Country <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="emergencyCountry"
                            onChange={handleChange}
                            isInvalid={errors.emergencyCountry}
                            value={values.emergencyCountry}
                          >
                            <option value="">---Select---</option>
                            {COUNTRIES_LIST.map((countries, index) => (
                              <option
                                key={"countries" + index}
                                value={countries?.en_short_name}
                              >
                                {countries?.en_short_name}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                        <div className="col-md-3 mt-3">
                          <Form.Label fontSize="14" className="font-color">
                            Province/State <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="emergencyProvince"
                            value={values.emergencyProvince}
                            isInvalid={errors.emergencyProvince}
                            placeholder="Please input province"
                            onChange={handleChange}
                            maxLength="50"
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <Form.Label fontSize="14" className="font-color">
                            District <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="emergencyDistrict"
                            value={values.emergencyDistrict}
                            isInvalid={errors.emergencyDistrict}
                            placeholder="Please input district"
                            onChange={handleChange}
                            maxLength="50"
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <Form.Label fontSize="14" className="font-color">
                            Village <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="emergencyVillage"
                            value={values.emergencyVillage}
                            isInvalid={errors.emergencyVillage}
                            placeholder="Please input village"
                            onChange={handleChange}
                            maxLength="50"
                          />
                        </div>
                      </>
                    )
                      :
                      (<></>)
                    }
                  </Row>

                  <Col
                    className='col-sm-12 titlebt'
                    style={{
                      backgroundColor: '#D8D8D8',
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16
                    }}
                  >
                    Upload document
                  </Col>
                  <Row style={{ marginBottom: 90 }}>
                    <Col sm='4' style={{ marginTop: 30 }}>
                      Passport <span className="text-danger">*</span>
                    </Col>
                    <Col sm='6' style={{ marginTop: 35 }}>
                      <div
                        className='col-sm-12 center'
                        style={{
                          border: '1px solid #ddd',
                          outline: 'none',
                          cursor: 'pointer',
                          textAlign: 'center'
                        }}
                      >
                        <input
                          type='file'
                          id='file-upload'
                          accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                          onChange={_handleChangeFile}
                          hidden
                          onClick={() => setClick('1')}
                        />
                        <label
                          htmlFor='file-upload'
                          style={{ width: '100%', cursor: 'pointer' }}
                        >
                          <div
                            style={{
                              backgroundColor: '#f3f3f3',
                              marginLeft: 20,
                              marginTop: 5,
                              marginRight: 20,
                              textAlign: 'center'
                            }}
                          >
                            {click === "1" && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size='2x' />}
                          </div>
                        </label>
                        <span>ເລືອກໄຟລ</span>
                        {passport && !isLoading && (
                          <aside>
                            <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                            {/* <ul>{_fileUploaded}</ul> */}
                            {passport}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setPassport(null)
                                  setPassportUrl(null)
                                }}
                                variant={'danger'}
                              >
                                Delete file
                              </Button>
                            </div>
                          </aside>
                        )}
                      </div>
                    </Col>
                    <Col sm='4' style={{ marginTop: 35 }}>
                      Qualification achieved abroad
                      <span className="text-danger">*</span>
                    </Col>
                    <Col sm='6' style={{ marginTop: 35 }}>
                      <div
                        className='col-sm-12 center'
                        style={{
                          border: '1px solid #ddd',
                          outline: 'none',
                          cursor: 'pointer',
                          textAlign: 'center'
                        }}
                      >
                        <input
                          type='file'
                          id='file-upload3'
                          accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                          onChange={_handleChangeFile}
                          onClick={() => setClick('3')}
                          hidden
                        />
                        <label
                          htmlFor='file-upload3'
                          style={{ width: '100%', cursor: 'pointer' }}
                        >
                          <div
                            style={{
                              backgroundColor: '#f3f3f3',
                              marginLeft: 20,
                              marginTop: 5,
                              marginRight: 20,
                              textAlign: 'center'
                            }}
                          >
                            {click === "3" && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size='2x' />}
                          </div>
                        </label>
                        <span>ເລືອກໄຟລ</span>
                        {qualification && !isLoading && (
                          <aside>
                            <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                            {/* <ul>{_fileUploaded}</ul> */}
                            {qualification}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setQualification(null)
                                  // setCertificateUrl(null)
                                }}
                                variant={'danger'}
                              >
                                Delete file
                              </Button>
                            </div>
                          </aside>
                        )}
                      </div>
                    </Col>
                    <Col sm='4' style={{ marginTop: 35 }}>
                      Other
                    </Col>
                    <Col sm='6' style={{ marginTop: 35 }}>
                      <div
                        className='col-sm-12 center'
                        style={{
                          border: '1px solid #ddd',
                          outline: 'none',
                          cursor: 'pointer',
                          textAlign: 'center'
                        }}
                      >
                        <input
                          type='file'
                          id='file-upload4'
                          accept="image/png, image/heif, image/jpeg, image/jpg, application/pdf"
                          onChange={_handleChangeFile}
                          onClick={() => setClick('4')}
                          hidden
                        />
                        <label
                          htmlFor='file-upload4'
                          style={{ width: '100%', cursor: 'pointer' }}
                        >
                          <div
                            style={{
                              backgroundColor: '#f3f3f3',
                              marginLeft: 20,
                              marginTop: 5,
                              marginRight: 20,
                              textAlign: 'center'
                            }}
                          >
                            {click === "4" && isLoading ? <Spinner animation="border" style={{ color: "#057CAE" }} /> : <FontAwesomeIcon icon={faUpload} size='2x' />}
                          </div>
                        </label>
                        <span>ເລືອກໄຟລ</span>
                        {other && !isLoading && (
                          <aside>
                            <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                            {/* <ul>{_fileUploaded}</ul> */}
                            {other}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <Button
                                variant={'danger'}
                                onClick={() => {
                                  setOther(null)
                                  // setTestCardUrl(null)
                                }}
                              >
                                Delete file
                              </Button>
                            </div>
                          </aside>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div
                    className='d-flex justify-content-center fixed-bottom'
                    style={{
                      marginTop: 50,
                      backgroundColor: '#fff',
                      padding: 10
                    }}
                  >
                    <Col className='d-flex justify-content-center'>
                      <div style={{ marginRight: 70 }}>
                        <CustomButton
                          type='submit'
                          title='Cancel'
                          onClick={() => {
                            history.goBack()
                          }}
                        />
                      </div>
                      <CustomButton
                        type='submit'
                        confirm
                        title='Confirm'
                        disabled={isLoading}
                        onClick={() => {
                          handleSubmit()
                        }}
                      />
                    </Col>
                  </div>
                </div>
              )}
            </Formik>
          }
        </div>
        <Modal
          //   show={show}
          //   onHide={() => setShow(false)}
          centered
        >
          <Modal.Body>
            <Col
              className='text-center'
              style={{ paddingTop: 40, paddingBottom: 50 }}
            >
              <div
                className='textfont-size72 font-color'
                style={{ color: '#00A1DE' }}
              >
                Success
              </div>
              <FontAwesomeIcon
                icon={faCheckCircle}
                size='9x'
                style={{ color: '#00A1DE' }}
              />
              <div
                className='textfont-size19 font-color'
                style={{ marginTop: 40 }}
              >
                Data have saved
              </div>
            </Col>
          </Modal.Body>
        </Modal>
        <PreregistrationUpdateConfirmEn
          showAddConfirmModal={showAddConfirmModal}
          data={formParam}
          _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
        />
      </div>
    </div>
  )
}

import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { Box, Typography, Modal, Chip } from "@mui/material";
import AttendanceReport from "./attendanceReport";
import ViewCountReport from "./viewCountReport";
import ExamScoreReport from "./examReport";
import HommeworkScoreReport from "./homeworkReport";
import ExamTimeReport from "./examTimeReport";
import ClassroomQuestionReport from "./courseQuestionReport";
import UnAnswerReport from "./unanswerQuestionReport";

// Modern color mapping for fields
const fieldColorMap = {
  attendance: "#2ecc71", // Emerald
  videoEngagement: "#3498db", // Peter River
  examPerformance: "#e74c3c", // Alizarin
  homeworkPerformance: "#e67e22", // Carrot
  examTimeManagement: "#9b59b6", // Amethyst
  classroomQuestions: "#f1c40f", // Sunflower
  unansweredQuestions: "#34495e", // Wet Asphalt
};

// Lao translations for the types
const fieldLabels = {
  attendance: "ຂໍ້ມູນການເຂົ້າຫ້ອງຮຽນ",
  videoEngagement: "ຄວາມສົນໃຈໃນການເບີ່ງວີດີໂອບົດສອນ",
  examPerformance: "ຜົນສອບເສັງ",
  homeworkPerformance: "ຜົນການເຮັດວຽກບ້ານ",
  examTimeManagement: "ການຈັດການເວລາສອບເສັງ",
  classroomQuestions: "ຄຳຖາມທ້າຍບົດ",
  unansweredQuestions: "ຄຳຖາມທີ່ບໍ່ຖືກຕອບ",
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, status, suggestion, averagePercentage } = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          border: "1px solid #ddd",
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          {name}
        </Typography>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          ສະເລ່ຍ:{" "}
          <span
            style={{
              color:
                averagePercentage < 20
                  ? "red"
                  : averagePercentage < 50
                  ? "range"
                  : averagePercentage < 80
                  ? "green"
                  : "blue",
            }}
          >
            {averagePercentage}%
          </span>
        </Typography>
        <Typography variant="body2">
          <strong>ສະຖານະ:</strong> {status}
        </Typography>
        <Typography variant="body2">
          <strong>ຄຳແນະນຳ:</strong> {suggestion}
        </Typography>
      </div>
    );
  }
  return null;
};

const RiskAnalysisReport = ({ reportData, classObj }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [type, setType] = useState("");

  // Extract "overall" data
  const overallData = reportData.overall;

  // Remove "overall" from chartData
  const chartData = Object.keys(reportData)
    .filter((key) => key !== "overall" && key !== "details")
    .map((key) => ({
      name: fieldLabels[key] || key, // Use Lao label if available
      type: key, // Add type for identification
      averagePercentage: parseFloat(
        reportData[key].averagePercentage ||
          reportData[key].averageScorePercentage ||
          reportData[key].averageEfficiencyPercentage ||
          reportData[key].averageAnswersPercentage
      ),
      status: reportData[key].status,
      suggestion: reportData[key].suggestion,
    }));

  const handleDetailsToggle = () => {
    setIsDetailsOpen(!isDetailsOpen);
  };

  const handleBarClick = (data) => {
    setType(data?.type);
    setIsDetailsOpen(true);
  };

  return (
    <div
      style={{
        position: "relative",
        padding: "20px",
      }}
    >
      {overallData && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            borderRadius: "12px",
            marginBottom: "20px",
          }}
        >
          {/* Class Name */}
          <Typography
            variant="h6"
            fontWeight="bold"
            gutterBottom
            sx={{ color: "#2c3e50" }}
          >
            ຫ້ອງຮຽນ:
          </Typography>
          <Chip
            label={classObj.classItem?.name}
            sx={{
              backgroundColor: classObj?.color || "#2c3e50",
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              padding: "15px",
              marginRight: 10,
              marginLeft: 2,
              marginBottom: 1,
            }}
          />

          {/* Average Percentage */}
          <Typography
            variant="h6"
            fontWeight="bold"
            gutterBottom
            sx={{ color: "#2c3e50" }}
          >
            ສະເລ່ຍປະສິດທິພາບ:
          </Typography>
          <Chip
            label={`${overallData.averagePercentage}%`}
            sx={{
              backgroundColor:
                overallData.averagePercentage < 20
                  ? "red"
                  : overallData.averagePercentage < 50
                  ? "orange"
                  : overallData.averagePercentage < 80
                  ? "green"
                  : "blue",
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              padding: "15px",
              marginLeft: 2,
              marginBottom: 1,
            }}
          />
        </Box>
      )}

      <ResponsiveContainer
        width="100%"
        height={600}
        minWidth={800} // Ensures horizontal scrolling for many bars
      >
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 100 }}
          barGap={10}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
          <XAxis
            dataKey="name"
            tick={{
              fontSize: 14,
              fill: "#2c3e50",
              angle: -45, // Rotate labels at an angle
              textAnchor: "end", // Align the text
            }}
            interval={0} // Show all labels
          />
          <YAxis
            tick={{
              fontSize: 14,
              fill: "#2c3e50",
            }}
            domain={[0, 100]} // Set Y-axis range from 0 to 100
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} // Explicitly define the ticks
            tickFormatter={(value) => `${value}%`} // Add a percentage sign to the ticks
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="averagePercentage" name="ສະເລ່ຍ (%)">
            {chartData.map((entry, index) => {
              const color = fieldColorMap[entry.type] || "#000"; // Default to black if type not found
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={color}
                  onClick={() => handleBarClick(entry)} // Handle bar clicks
                  style={{ cursor: "pointer" }}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <Modal
        open={isDetailsOpen}
        onClose={handleDetailsToggle}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            maxHeight: "95%",
            overflowY: "auto",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          {type === "attendance" && (
            <AttendanceReport data={reportData?.details?.attendanceReport} />
          )}
          {type === "videoEngagement" && (
            <ViewCountReport
              viewCountReport={reportData?.details?.viewCountReport}
            />
          )}
          {type === "examPerformance" && (
            <ExamScoreReport
              examScoreReport={reportData?.details?.examScoreReport}
            />
          )}
          {type === "homeworkPerformance" && (
            <HommeworkScoreReport
              homeworkScoreReport={reportData?.details?.homeworkReport}
            />
          )}
          {type === "examTimeManagement" && (
            <ExamTimeReport
              examTimeReport={reportData?.details?.examTimeReport}
            />
          )}
          {type === "classroomQuestions" && (
            <ClassroomQuestionReport
              classroomQuestionReport={
                reportData?.details?.classroomQuestionReport
              }
            />
          )}
          {type === "unansweredQuestions" && (
            <UnAnswerReport
              unAnswerReport={reportData?.details?.unAnswerReport}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default RiskAnalysisReport;

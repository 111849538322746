import React, { useEffect, useState } from "react";
import DataFilter from "../../common/Filter";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton, Typography, Button } from "@mui/material";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useReactRouter from "use-react-router";
import Routers from "../../consts/router";
import Const from "../../consts";

import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../consts";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from "@mui/icons-material/GetApp";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Loading from "../../common/Loading";
import { fetchGet } from "../../common/fetchAPI";

const NewStudentList = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const [facId, setFacId] = useState(userObject.data.faculty.id);
  const [depId, setDepId] = useState("");
  const [disableDep, setDisableDep] = useState(false);
  const [disableFact, setDisableFact] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);

    if (userObject) {
      if (userObject.data.role === "DEP_ADMIN") {
        setDisableDep(true);
        setDepId(userObject.data.department.id);
        setFacId(userObject.data.faculty.id);
        setDisableFact(true);
      }
      if (userObject.data.role === "FAC_ADMIN") {
        setDisableDep(false);
        setDepId("");
        setFacId(userObject.data.faculty.id);
        setDisableFact(true);
      }
      if (userObject.data.role === "ADMIN") {
        setDisableDep(false);
        setDisableFact(false);
        setDepId("");
        setFacId("");
      }
    }
  }, [user]);

  const onExport = async () => {
    let queue = 0;
    setExportLoading(true);

    try {
      const _studentList = await fetchStudentExport(studentCount || 0);

      const nreStudentExport = _studentList.map((student) => {
        queue++;
        let gender = "ຊາຍ";

        if (student.gender === "FEMALE") gender = "ຍິງ";

        return {
          ລຳດັບ: queue,
          ລະຫັດ: student.userId,
          "ຊື່ ແລະ ນາມສະກຸນ": `${student.firstNameL} ${student.lastNameL}`,
          ເພດ: gender,
          ຄະນະ: student.faculty.name || "",
          ພາກວິຊາ: student.department.name,
          ສາຂາ: student.major.name,
          ປິຮຽນ: student.yearLevel,
          ປະເພດນັກສຶກສາ:
            student.studentType === "IN_PLAN" ? "ໃນເເຜນ" : "ນອກເເຜນ",
          ລະດັບການສຶກສາ: student?.educaeducationLevel?.name || "",
          ເບີໂທລະສັບ: student?.phoneMobile || "",
          ບ້ານປັດຈຸບັນ: student?.address?.village || "",
          ເມຶອງປັດຈຸບັນ: student?.address?.district || "",
          ແຂວງປັດຈຸບັນ: student?.address?.province || "",
          ບ້ານເກີດ: student?.address?.village || "",
          ເມຶອງເກີດ: student?.address?.district || "",
          ແຂວງເກີດ: student?.address?.province || "",
          ວັນເດືອນປີເກີດ: new Date(student?.birthday).toLocaleString(),
          ສັນຊາດ: student?.nationality || "",
          ຊົນເຜົ່າ: student?.tribe?.name || "",
        };
      });

      const ws = XLSX.utils.json_to_sheet(nreStudentExport);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Students");

      const xlsxBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
        mimeType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const blob = new Blob([new Uint8Array(xlsxBuffer)], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "students.xlsx");
    } catch (error) {
      console.error("Error during export:", error);
    } finally {
      setExportLoading(false);
    }
  };

  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history, location } = useReactRouter();
  const _studentDetail = (id) => {
    history.push(Routers.STUDENT_DETAIL + "/id/" + id);
  };
  const _studentEdit = (id) => {
    history.push(Routers.STUDENT_EDIT + "/id/" + id);
  };

  const _studentAdd = () => {
    history.push(Routers.STUDENT_ADD);
  };

  const onSelected = (data) => {
    setFilter(data);
  };

  const [filter, setFilter] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [studentCount, setStudentCount] = useState(0);

  // use to check that if query from then it should
  // map a different field because the student id
  // from history and student is not the samme
  const [fromHistory, setFromHistory] = useState(false);

  const searchStudent = async () => {
    try {
      setStudentList([]);
      const data = await fetchGet(
        `/student/search?faculty=${filter.faculty}&department=${filter.department}&major=${filter.major}&year=${filter.year}&classrRoom=${filter.class}&keyword=${keyword}&assesmentyear=${accessmentRedux}`
      );
      if (data.success) {
        setStudentList(data.data);
        setFromHistory(data.fromHistory);
      }
    } catch (err) {}
  };

  // useEffect(() => {
  //   searchStudent();
  // }, [keyword]);

  const fetchStudentExport = async (limit) => {
    const body = {
      history: true,
      assessmentYear: accessmentRedux,
      faculty: filter?.faculty || facId,
      department: filter?.department,
      major: filter?.major,
      classroom: filter?.class,
      year: filter?.year,
      limit: limit,
      page: currentPage,
    };
    try {
      const response = await axios.post(NEW_API_URL + "/getstudent", body, {
        headers: {
          api_key: API_KEY,
        },
      });
      if (response.data.success) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  };

  const fetchStudent = async (limit) => {
    const body = {
      history: true,
      assessmentYear: accessmentRedux,
      faculty: filter?.faculty || facId,
      department: filter?.department,
      major: filter?.major,
      classroom: filter?.class,
      year: filter?.year,
      limit: limit,
      page: currentPage,
      keyword: keyword,
    };
    try {
      const response = await axios.post(NEW_API_URL + "/getstudent", body, {
        headers: {
          api_key: API_KEY,
        },
      });
      if (response.data.success) {
        setStudentList(response.data.data);
        setFromHistory(response.data.fromHistory);
        setTotalPage(response.data.totalPageCount);
        setStudentCount(response.data.count);
        return response.data?.data;
      } else {
        setStudentList([]);
        return [];
      }
    } catch (err) {
      setStudentList([]);
      return [];
    }
  };

  useEffect(() => {
    fetchStudent(20);
  }, [filter, accessmentRedux, facId, currentPage, keyword]);

  // Define the RowData component
  const RowData = ({
    id,
    queue,
    code,
    name,
    lastname,
    faculty,
    department,
    major,
    classroom,
    year,
    gender,
  }) => {
    return (
      <TableRow align="center">
        <TableCell colSpan={1} align="center" sx={{ whiteSpace: "wrap" }}>
          {queue + 20 * (currentPage - 1)}
        </TableCell>
        <TableCell colSpan={2} align="center" sx={{ whiteSpace: "wrap" }}>
          {code}
        </TableCell>
        <TableCell colSpan={1} align="center" sx={{ whiteSpace: "wrap" }}>
          {gender === "FEMALE" ? "ຍິງ" : "ຊາຍ"}
        </TableCell>
        <TableCell colSpan={3} align="start" sx={{ whiteSpace: "wrap" }}>
          {(gender === "MALE"
            ? "ທ້າວ "
            : gender === "FEMALE"
            ? "ນາງ "
            : gender === "MONK"
            ? "ພຣະ "
            : gender === "NOVICE"
            ? "ຄູບາ "
            : "") +
            name +
            " " +
            lastname}
        </TableCell>
        <TableCell colSpan={3} align="center" sx={{ whiteSpace: "wrap" }}>
          {faculty}
        </TableCell>
        <TableCell colSpan={3} align="center" sx={{ whiteSpace: "wrap" }}>
          {department}
        </TableCell>
        <TableCell colSpan={3} align="center" sx={{ whiteSpace: "wrap" }}>
          {major}
        </TableCell>
        <TableCell colSpan={2} align="center" sx={{ whiteSpace: "wrap" }}>
          {classroom}
        </TableCell>
        <TableCell colSpan={1} align="center" sx={{ whiteSpace: "wrap" }}>
          {year}
        </TableCell>
        <TableCell colSpan={2} align="center" sx={{ whiteSpace: "wrap" }}>
          <IconButton
            onClick={() => {
              _studentEdit(id);
            }}
            color="warning"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              _studentDetail(id);
            }}
            color="primary"
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className="pt-2">
      <div className="ps-4">
        <h5>ຂໍ້ມູນນັກສຶກສາ</h5>
      </div>
      {/* <div className="ftbt" onClick={() => _studentAdd()}>
        <img className="smimage" src="/addicon.png" />
      </div> */}
      <div className="row m-0 p-0">
        <div className="col-9">
          <DataFilter
            depId={depId}
            disableDep={disableDep}
            factId={facId}
            disableFact={disableFact}
            onSelected={onSelected}
          />
        </div>
        <div className="col-3 mt-5">
          <input
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="ຄົ້ນຫາ"
            className="newinput2 text-center"
          />
        </div>
      </div>
      <div className="row m-0 p-0 pl-5 mb-3">
        <div className="col-9 text-end pt-2">
          <p className="pe-3">ຈຳນວນນັກສຶກສາ : {studentCount}</p>
        </div>
        <div className="col-3">
          <Button
            sx={{
              marginRight: 3,
            }}
            onClick={() => {
              onExport();
            }}
            variant="contained"
            color="success"
            startIcon={<GetAppIcon />}
          >
            Export
          </Button>
          <Button
            onClick={() => {
              _studentAdd();
            }}
            variant="contained"
            sx={{ backgroundColor: Const.PRIMARY_COLO }}
            startIcon={<AddIcon />}
          >
            ເພິ່ມນັກສຶກສາ
          </Button>
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={1}
                style={{ color: "white", fontWeight: "bold" }}
              >
                ລຳດັບ
              </TableCell>
              <TableCell
                colSpan={2}
                align="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                ລະຫັດ
              </TableCell>
              <TableCell
                colSpan={1}
                align="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                ເພດ
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                ຊື່ ແລະ ນາມສະກຸນ
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                ຄະນະວິຊາ
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                ພາກວິຊາ
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                ສາຂາ
              </TableCell>
              <TableCell
                align="center"
                colSpan={2}
                style={{ color: "white", fontWeight: "bold" }}
              >
                ຫ້ອງຮຽນ
              </TableCell>
              <TableCell
                colSpan={1}
                align="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                ປີຮຽນ
              </TableCell>
              <TableCell
                colSpan={2}
                align="center"
                style={{ color: "white", fontWeight: "bold" }}
              >
                ຈັດການ
              </TableCell>
            </TableRow>
          </TableHead>
          {studentList.length > 0 ? (
            studentList.map((student, index) => (
              <TableBody>
                <RowData
                  id={student._id}
                  queue={index + 1}
                  code={student?.userId}
                  name={
                    !student?.firstNameL || student?.firstNameL === ""
                      ? student?.firstNameE
                      : student?.firstNameL
                  }
                  lastname={
                    !student?.firstNameL || student?.firstNameL === ""
                      ? student?.lastNameE
                      : student?.lastNameL
                  }
                  faculty={student?.faculty?.name}
                  department={student?.department?.name}
                  major={student?.major?.name}
                  classroom={student?.classRoom?.name}
                  year={student.yearLevel}
                  gender={student?.gender}
                />
              </TableBody>
            ))
          ) : (
            <div className="row p-0 m-0 px-4">
              <Loading />
            </div>
          )}
        </Table>
      </TableContainer>
      <div className="row w-100">
        <div className="col-5 text-end">
          <IconButton
            onClick={() => {
              if (currentPage > 1) setCurrentPage((page) => page - 1);
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>
        <div className="col-2 text-center pt-2">
          <Typography>{currentPage + " ໃນ " + totalPage}</Typography>
        </div>
        <div className="col-5">
          <IconButton
            onClick={() => {
              if (currentPage < totalPage) setCurrentPage((page) => page + 1);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default NewStudentList;

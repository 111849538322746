import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
  IconButton,
  Modal,
  Paper,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { EmojiPeople } from "@mui/icons-material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import EmptyComponent from "../../../../common/EmptyScreen";

// Transforming Data for BarChart
const transformData = (data) => {
  if (!Array.isArray(data)) return [];
  return data.map((student) => {
    const sectionsData = student.sectionsPerformance.reduce(
      (acc, section) => ({
        ...acc,
        [section.sectionTitle]: section.totalScore,
      }),
      { studentName: student.studentName }
    );
    return sectionsData;
  });
};

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { payload: data } = payload[0];
    return (
      <Box
        p={2}
        sx={{ backgroundColor: "white", borderRadius: 2, boxShadow: 2 }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          {label}
        </Typography>
        {Object.keys(data).map((key) =>
          key !== "studentName" ? (
            <Typography key={key} variant="body2">
              {key}: {data[key]} points
            </Typography>
          ) : null
        )}
      </Box>
    );
  }
  return null;
};

const SectionAverage = ({ sectionWisePerformance }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (sectionWisePerformance) {
      setData(transformData(sectionWisePerformance));
    }
  }, [sectionWisePerformance]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const renderChart = () => (
    <Box sx={{ overflowX: "auto" }}>
      <Box sx={{ minWidth: data.length * 120 }}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="studentName" tick={{ fontSize: 12 }} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            {data.length > 0 &&
              Object.keys(data[0])
                .filter((key) => key !== "studentName")
                .map((key, index) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    fill={["#0077b6", "#00b4d8", "#90e0ef"][index % 3]}
                    name={key}
                  />
                ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
      }}
      p={3}
    >
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", mb: 2 }}
            >
              Section Wise Performance
            </Typography>
            <IconButton onClick={handleOpenModal}>
              <FullscreenIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2 }} />
          {data.length > 0 ? renderChart() : <EmptyComponent height={300} />}
        </CardContent>
      </Card>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper
          sx={{
            width: "80%",
            height: "80%",
            overflowY: "auto",
            backgroundColor: "white",
            padding: 2,
            borderRadius: 2,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          {data.length > 0 ? renderChart() : <EmptyComponent height={300} />}
        </Paper>
      </Modal>
    </Box>
  );
};

export default SectionAverage;

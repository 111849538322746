import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  IconButton,
  Box,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ReplayIcon from "@mui/icons-material/Replay";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { fetchGet, fetchPost, fetchPut } from "../../common/fetchAPI";
import { useLocation } from "react-router-dom";
import useReactRouter from "use-react-router";
import EvaluationPreview from "./evaluationPreview";
import { USER_KEY } from "../../consts";

const AddEvaluationTemplate = () => {
  const [sections, setSections] = useState([]);
  const [description, setDescription] = useState("");
  const location = useLocation();
  const { data } = location.state || {}; // Access the sent data
  const { history } = useReactRouter();

  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (data) {
      fetchTemplate();
    }
  }, [data]);

  const sectionTypes = [
    {
      label: "ຄຳຖາມເປີດ",
      value: "open",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "5",
      value: "5",
    },
  ];

  const handleInputChange = (
    e,
    sectionIndex,
    subsectionIndex,
    itemIndex,
    type
  ) => {
    const updatedSections = [...sections];
    if (type === "section") {
      updatedSections[sectionIndex][e.target.name] = e.target.value;
    } else if (type === "subsection") {
      updatedSections[sectionIndex].subsections[subsectionIndex][
        e.target.name
      ] = e.target.value;
    } else if (type === "item") {
      updatedSections[sectionIndex].subsections[subsectionIndex].items[
        itemIndex
      ][e.target.name] = e.target.value;
    }
    setSections(updatedSections);
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        title: "",
        sectionNumber: sections.length + 1,
        type: "3",
        subsections: [
          {
            title: "",
            subSectionNumber: 1,
            items: [
              {
                title: "",
                itemNumber: 1,
              },
            ],
          },
        ],
      },
    ]);
  };

  const addSubsection = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].subsections.push({
      title: "",
      subSectionNumber: updatedSections[sectionIndex].subsections.length + 1,
      items: [
        {
          title: "",
          itemNumber: 1,
        },
      ],
    });
    setSections(updatedSections);
  };

  const addItem = (sectionIndex, subsectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].subsections[subsectionIndex].items.push({
      title: "",
      itemNumber:
        updatedSections[sectionIndex].subsections[subsectionIndex].items
          .length + 1,
    });
    setSections(updatedSections);
  };

  const removeSection = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections.splice(sectionIndex, 1);
    setSections(updatedSections);
    updateNumbers(updatedSections);
  };

  const removeSubsection = (sectionIndex, subsectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].subsections.splice(subsectionIndex, 1);
    setSections(updatedSections);
    updateNumbers(updatedSections);
  };

  const removeItem = (sectionIndex, subsectionIndex, itemIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].subsections[subsectionIndex].items.splice(
      itemIndex,
      1
    );
    setSections(updatedSections);
    updateNumbers(updatedSections);
  };

  const updateNumbers = (sections) => {
    const updatedSections = sections.map((section, sectionIndex) => {
      section.sectionNumber = sectionIndex + 1;
      section.subsections = section.subsections.map(
        (subsection, subsectionIndex) => {
          subsection.subSectionNumber = subsectionIndex + 1;
          subsection.items = subsection.items.map((item, itemIndex) => {
            item.itemNumber = itemIndex + 1;
            return item;
          });
          return subsection;
        }
      );
      return section;
    });
    setSections(updatedSections);
  };

  const onDragEnd = (
    result,
    type,
    sectionIndex = null,
    subsectionIndex = null
  ) => {
    if (!result.destination) return;
    let updatedSections = [...sections];
    if (type === "section") {
      const [reorderedSection] = updatedSections.splice(result.source.index, 1);
      updatedSections.splice(result.destination.index, 0, reorderedSection);
    } else if (type === "subsection") {
      const reorderedSubsections = [
        ...updatedSections[sectionIndex].subsections,
      ];
      const [reorderedSubsection] = reorderedSubsections.splice(
        result.source.index,
        1
      );
      reorderedSubsections.splice(
        result.destination.index,
        0,
        reorderedSubsection
      );
      updatedSections[sectionIndex].subsections = reorderedSubsections;
    } else if (type === "item") {
      const reorderedItems = [
        ...updatedSections[sectionIndex].subsections[subsectionIndex].items,
      ];
      const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, reorderedItem);
      updatedSections[sectionIndex].subsections[subsectionIndex].items =
        reorderedItems;
    }
    updateNumbers(updatedSections);
  };

  const sectionBackgroundColor = (index) => {
    const colors = ["#fff", "#fff", "#fff"];
    return colors[index % colors.length];
  };

  const subsectionBackgroundColor = (index) => {
    const colors = ["#f8f9fa", "#f8f9fa", "#f8f9fa"];
    return colors[index % colors.length];
  };

  const fetchTemplate = async () => {
    try {
      const response = await fetchGet("/evaluation/" + data.id);
      setSections(response?.sections);
      setDescription(response?.description);
    } catch (err) {}
  };

  const [isLoading, setIsLoading] = useState(false);
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);

  const onSubmit = async () => {
    setIsLoading(true);
    let update = null;
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);
    if (data) {
      update = await fetchPut("/evaluation/" + data.id, {
        faculty: userObject?.data?.faculty?._id, // the ID of the faculty this template belongs to
        description: description,
        sections: sections,
      });
      if (update) {
        setIsLoading(false);
        toast.info("ບັນທຶກສຳເລັດ", {
          autoClose: 1500, // Customize hide time to 5 seconds (5000ms)
        });
        await fetchTemplate();
      } else {
        toast.error("ຂໍອະໄພ ລະບົບມີບັນຫາ", {
          autoClose: 1500, // Customize hide time to 5 seconds (5000ms)
        });
      }
    } else {
      update = await fetchPost("/evaluation", {
        faculty: userObject?.data?.faculty?._id, // the ID of the faculty this template belongs to
        description: description,
        sections: sections,
      });
      if (update) {
        setIsLoading(false);
        toast.info("ເພີ່ມສຳເລັດ", {
          autoClose: 1500, // Customize hide time to 5 seconds (5000ms)
        });
        history.push("/evaluation");
      } else {
        toast.error("ຂໍອະໄພ ລະບົບມີບັນຫາ", {
          autoClose: 1500, // Customize hide time to 5 seconds (5000ms)
        });
      }
    }
  };

  return (
    <Grid sx={{ padding: "20px" }} container spacing={2}>
      <EvaluationPreview
        close={() => setShowPreview(false)}
        data={{
          description: description,
          faculty: userObject?.data?.faculty?._id, // the ID of the faculty this template belongs to
          sections: sections,
        }}
        open={showPreview}
      />
      <Paper
        sx={{
          display: "flex",
          justifyContent: "end",
          flexDirection: "row",
          width: "100%",
          gap: "20px",
          backgroundColor: "#fff",
          marginBottom: "10px",
          padding: "20px",
        }}
      >
        <Button
          onClick={() => history.push("/evaluation")}
          color="error"
          variant="contained"
          startIcon={<CancelIcon />}
        >
          ຍົກເລີກ
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={() => setShowPreview(true)}
          startIcon={<RemoveRedEyeIcon />}
        >
          ເບີ່ງຕົວຢ່າງ
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          startIcon={
            isLoading ? (
              <CircularProgress sx={{ color: "white" }} size={24} />
            ) : (
              <SaveIcon />
            )
          }
        >
          {isLoading ? "" : "ບັນທຶກ"}
        </Button>
        <IconButton color="primary" onClick={fetchTemplate}>
          <ReplayIcon />
        </IconButton>
      </Paper>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "20px",
          backgroundColor: "#fff",
          marginBottom: "10px",
          padding: "20px",
        }}
      >
        <TextField
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          multiline
          rows={3}
          value={description}
          fullWidth
          label="ລາຍລະອຽດ"
        />
      </Paper>
      <DragDropContext onDragEnd={(result) => onDragEnd(result, "section")}>
        <Droppable droppableId="sections">
          {(provided) => (
            <Grid
              container
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {sections &&
                sections.length > 0 &&
                sections.map((section, sectionIndex) => (
                  <Draggable
                    key={sectionIndex}
                    draggableId={`section-${sectionIndex}`}
                    index={sectionIndex}
                  >
                    {(provided) => (
                      <Grid
                        item
                        sx={{
                          marginBottom: "10px",
                        }}
                        xs={12}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>
                              ຫົວຂໍ້ {section.sectionNumber}:{" "}
                              {section.title || ""}
                            </Typography>
                            <IconButton
                              color="error"
                              style={{ marginLeft: "auto" }}
                              onClick={() => removeSection(sectionIndex)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={8}>
                                <TextField
                                  fullWidth
                                  label="ຊື່ຫົວຂໍ້"
                                  name="title"
                                  value={section.title}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      sectionIndex,
                                      null,
                                      null,
                                      "section"
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth>
                                  <InputLabel>ປະເພດຄຳຖາມ</InputLabel>
                                  <Select
                                    name="type"
                                    label="ປະເພດຄຳຖາມ"
                                    value={section.type || "3"}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        sectionIndex,
                                        null,
                                        null,
                                        "section"
                                      )
                                    }
                                  >
                                    {sectionTypes.length > 0 &&
                                      sectionTypes.map((type, idx) => (
                                        <MenuItem key={idx} value={type.value}>
                                          {type.label}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              {/* Subsections */}
                              <DragDropContext
                                onDragEnd={(result) =>
                                  onDragEnd(result, "subsection", sectionIndex)
                                }
                              >
                                <Droppable
                                  droppableId={`subsections-${sectionIndex}`}
                                >
                                  {(provided) => (
                                    <Grid
                                      container
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        marginLeft: "40px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      {section.subsections?.length > 0 &&
                                        section.subsections.map(
                                          (subsection, subsectionIndex) => (
                                            <Draggable
                                              key={subsectionIndex}
                                              draggableId={`subsection-${subsectionIndex}`}
                                              index={subsectionIndex}
                                            >
                                              {(provided) => (
                                                <Grid
                                                  style={{
                                                    backgroundColor:
                                                      sectionBackgroundColor(
                                                        subsectionIndex
                                                      ),
                                                  }}
                                                  item
                                                  sx={{
                                                    marginTop: "20px",
                                                  }}
                                                  xs={12}
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  <Accordion
                                                    style={{
                                                      backgroundColor:
                                                        subsectionBackgroundColor(
                                                          subsectionIndex
                                                        ),
                                                    }}
                                                  >
                                                    <AccordionSummary
                                                      expandIcon={
                                                        <ExpandMoreIcon />
                                                      }
                                                    >
                                                      <Typography>
                                                        ຫົວຂໍ້ຫຍ່ອຍ{" "}
                                                        {
                                                          subsection.subSectionNumber
                                                        }
                                                        :{" "}
                                                        {subsection.title || ""}
                                                      </Typography>
                                                      <IconButton
                                                        color="error"
                                                        style={{
                                                          marginLeft: "auto",
                                                        }}
                                                        onClick={() =>
                                                          removeSubsection(
                                                            sectionIndex,
                                                            subsectionIndex
                                                          )
                                                        }
                                                      >
                                                        <DeleteIcon />
                                                      </IconButton>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                      <TextField
                                                        sx={{
                                                          backgroundColor:
                                                            "#fff",
                                                        }}
                                                        fullWidth
                                                        label="ຊື່ຫົວຂໍ້ຫຍ່ອຍ"
                                                        name="title"
                                                        value={subsection.title}
                                                        onChange={(e) =>
                                                          handleInputChange(
                                                            e,
                                                            sectionIndex,
                                                            subsectionIndex,
                                                            null,
                                                            "subsection"
                                                          )
                                                        }
                                                      />

                                                      {/* Items */}
                                                      <DragDropContext
                                                        onDragEnd={(result) =>
                                                          onDragEnd(
                                                            result,
                                                            "item",
                                                            sectionIndex,
                                                            subsectionIndex
                                                          )
                                                        }
                                                      >
                                                        <Droppable
                                                          droppableId={`items-${sectionIndex}-${subsectionIndex}`}
                                                        >
                                                          {(provided) => (
                                                            <Grid
                                                              container
                                                              {...provided.droppableProps}
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                            >
                                                              {subsection.items.map(
                                                                (
                                                                  item,
                                                                  itemIndex
                                                                ) => (
                                                                  <Draggable
                                                                    key={
                                                                      itemIndex
                                                                    }
                                                                    draggableId={`item-${sectionIndex}-${subsectionIndex}-${itemIndex}`}
                                                                    index={
                                                                      itemIndex
                                                                    }
                                                                  >
                                                                    {(
                                                                      provided
                                                                    ) => (
                                                                      <Box
                                                                        sx={{
                                                                          paddingLeft:
                                                                            "40px",
                                                                          marginTop: 2,
                                                                          width:
                                                                            "100%",
                                                                          display:
                                                                            "flex",
                                                                          flexDirection:
                                                                            "row",
                                                                        }}
                                                                        ref={
                                                                          provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                      >
                                                                        <TextField
                                                                          sx={{
                                                                            backgroundColor:
                                                                              "#fff",
                                                                          }}
                                                                          fullWidth
                                                                          label="ຄຳຖາມ"
                                                                          name="title"
                                                                          value={
                                                                            item.title
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleInputChange(
                                                                              e,
                                                                              sectionIndex,
                                                                              subsectionIndex,
                                                                              itemIndex,
                                                                              "item"
                                                                            )
                                                                          }
                                                                        />
                                                                        <IconButton
                                                                          color="error"
                                                                          onClick={() =>
                                                                            removeItem(
                                                                              sectionIndex,
                                                                              subsectionIndex,
                                                                              itemIndex
                                                                            )
                                                                          }
                                                                        >
                                                                          <DeleteIcon />
                                                                        </IconButton>
                                                                      </Box>
                                                                    )}
                                                                  </Draggable>
                                                                )
                                                              )}
                                                              {
                                                                provided.placeholder
                                                              }
                                                            </Grid>
                                                          )}
                                                        </Droppable>
                                                      </DragDropContext>
                                                      <Button
                                                        sx={{
                                                          marginLeft: "40px",
                                                          marginTop: "15px",
                                                        }}
                                                        startIcon={<AddIcon />}
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() =>
                                                          addItem(
                                                            sectionIndex,
                                                            subsectionIndex
                                                          )
                                                        }
                                                      >
                                                        ເພີ່ມຄຳຖາມ
                                                      </Button>
                                                    </AccordionDetails>
                                                  </Accordion>
                                                </Grid>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                      {provided.placeholder}
                                    </Grid>
                                  )}
                                </Droppable>
                              </DragDropContext>
                              <Button
                                color="primary"
                                variant="contained"
                                sx={{
                                  marginLeft: "40px",
                                  marginTop: "15px",
                                }}
                                onClick={() => addSubsection(sectionIndex)}
                              >
                                ເພີ່ມຫົວຂໍ້ຍ່ອຍ
                              </Button>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <Grid item xs={12}>
        <Button color="primary" variant="contained" onClick={addSection}>
          ເພີ່ມຫົວຂໍ້
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddEvaluationTemplate;

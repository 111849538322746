import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
  Fab,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";

const CourseDetailTop = ({ title, description, onAddLesson, onRefresh }) => {
  return (
    <Card
      sx={{
        width: "100%",
        position: "relative",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Soft shadow
        borderRadius: "9px", // Slightly rounded corners
        overflow: "hidden", // Ensure no overflow
      }}
    >
      <CardContent
        sx={{
          padding: "24px", // Padding to give it more spacing
          backgroundColor: "#fff", // Subtle background contrast for modern look
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "16px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)", // Subtle bottom border for separation
          }}
        >
          <Typography variant="h5" component="div" sx={{ fontWeight: "600" }}>
            {title.length > 40 ? `${title.substring(0, 40)}...` : title}
          </Typography>
          <IconButton onClick={onRefresh} color="primary">
            <RefreshIcon />
          </IconButton>
        </Box>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ marginTop: "16px", marginBottom: "24px" }}
        >
          {description}
        </Typography>
        <Fab
          color="primary"
          aria-label="add"
          onClick={onAddLesson}
          sx={{
            position: "absolute",
            bottom: 24,
            right: 24,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Subtle shadow for the button
          }}
        >
          <AddIcon />
        </Fab>
      </CardContent>
    </Card>
  );
};

export default CourseDetailTop;

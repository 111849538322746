import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { fetchGet } from "../../common/fetchAPI";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../consts";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const AssignEvaluateFormToTeacher = ({ open, onCancel, onSubmit }) => {
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState("");
  const [teacherList, setTeacherList] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [year, setYear] = useState(1);
  const [semester, setSemester] = useState(1);

  const [formErrors, setFormErrors] = useState({
    teacher: "",
    course: "",
    form: "",
    year: "",
    semester: "",
  });
  const [courseList, setCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [evaluationFormList, setEvaluationFormList] = useState([]);
  const [selectedForm, setSelectedForm] = useState("");

  const fetchEvaluationList = async (isMounted) => {
    try {
      const user = localStorage.getItem(USER_KEY);
      const userObject = JSON.parse(user);
      const response = await fetchGet(
        "/evaluation?faculty=" + userObject?.data?.faculty?._id
      );
      if (isMounted) setEvaluationFormList(response || []);
    } catch (err) {
      console.error("Error fetching evaluation list:", err);
    }
  };

  const fetchDepartmentList = async (isMounted) => {
    try {
      const user = localStorage.getItem(USER_KEY);
      const userObject = JSON.parse(user);
      const response = await fetchGet(
        "/department/" + userObject?.data?.faculty?._id
      );
      if (isMounted) setDepartmentList(response?.data || []);
    } catch (err) {
      console.error("Error fetching department list:", err);
    }
  };

  const fetchTeacher = async (isMounted) => {
    try {
      if (department) {
        const response = await fetchGet("userteacher/" + department);
        if (isMounted) setTeacherList(response || []);
      }
    } catch (err) {
      console.error("Error fetching teacher list:", err);
    }
  };

  const fetchTeachedCourses = async (isMounted) => {
    try {
      if (selectedTeacher && semester && year) {
        const response = await fetchGet(
          "teacher/course?teacherId=" +
            selectedTeacher +
            "&assessmentYear=" +
            accessmentRedux +
            "&semester=" +
            semester +
            "&year=" +
            year
        );
        if (isMounted) setCourseList(response || []);
      }
    } catch (err) {
      console.error("Error fetching courses:", err);
    }
  };

  useEffect(() => {
    let isMounted = true;
    fetchTeacher(isMounted);
    setSelectedTeacher("");
    return () => {
      isMounted = false;
    };
  }, [department]);

  useEffect(() => {
    let isMounted = true;
    fetchTeachedCourses(isMounted);
    setSelectedCourse("");
    return () => {
      isMounted = false;
    };
  }, [selectedTeacher]);

  useEffect(() => {
    let isMounted = true;
    fetchDepartmentList(isMounted);
    fetchEvaluationList(isMounted);

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = () => {
    if (selectedTeacher === "") {
      setFormErrors((prev) => ({ ...prev, teacher: "this field required" }));
      return;
    }
    if (year === "") {
      setFormErrors((prev) => ({ ...prev, year: "this field required" }));
      return;
    }
    if (semester === "") {
      setFormErrors((prev) => ({ ...prev, semester: "this field required" }));
      return;
    }
    if (selectedCourse === "") {
      setFormErrors((prev) => ({ ...prev, course: "this field required" }));
      return;
    }
    if (selectedForm === "") {
      setFormErrors((prev) => ({ ...prev, form: "this field required" }));
      return;
    }

    onSubmit({
      selectedTeacher,
      selectedCourse,
      selectedForm,
      year,
      semester,
      assessmentYear: accessmentRedux,
    });
  };

  return (
    <Modal open={open} onClose={onCancel}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" mb={2}>
          ຈັດແບບສອບຖາມໃຫ້ອາຈານ
        </Typography>
        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small" id="department-select-label">
            ເລືອກພາກວິຊາ
          </InputLabel>
          <Select
            label="ເລືອກພາກວິຊາ"
            size="small"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          >
            {departmentList &&
              departmentList.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item?.name}
                </MenuItem>
              ))}
          </Select>
          {formErrors.department && (
            <Typography color="error" variant="caption">
              {formErrors.department}
            </Typography>
          )}
        </FormControl>

        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small" id="teacher-select-label">
            ອາຈານ
          </InputLabel>
          <Select
            label="ອາຈານ"
            size="small"
            value={selectedTeacher}
            onChange={(e) => setSelectedTeacher(e.target.value)}
          >
            {teacherList &&
              teacherList.map((teacher) => (
                <MenuItem key={teacher._id} value={teacher._id}>
                  {teacher.firstname + " " + teacher.lastname}
                </MenuItem>
              ))}
          </Select>
          {formErrors.teacher && (
            <Typography color="error" variant="caption">
              {formErrors.teacher}
            </Typography>
          )}
        </FormControl>

        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small" id="department-select-label">
            ເລືອກປີຮຽນ
          </InputLabel>
          <Select
            label="ເລືອກປີຮຽນ"
            size="small"
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
              setSelectedCourse("");
            }}
          >
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {formErrors.year && (
            <Typography color="error" variant="caption">
              {formErrors.year}
            </Typography>
          )}
        </FormControl>

        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small" id="department-select-label">
            ເລືອກພາກຮຽນ
          </InputLabel>
          <Select
            label="ເລືອກພາກຮຽນ"
            size="small"
            value={semester}
            onChange={(e) => {
              setSemester(e.target.value);
              setSelectedCourse("");
            }}
          >
            {[1, 2].map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {formErrors.semester && (
            <Typography color="error" variant="caption">
              {formErrors.semester}
            </Typography>
          )}
        </FormControl>

        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small" id="course-select-label">
            ວິຊາ
          </InputLabel>
          <Select
            label="ວິຊາ"
            size="small"
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
          >
            {courseList &&
              courseList.map((course) => (
                <MenuItem key={course._id} value={course._id}>
                  {course?.course?.subject?.title}
                </MenuItem>
              ))}
          </Select>
          {formErrors.course && (
            <Typography color="error" variant="caption">
              {formErrors.course}
            </Typography>
          )}
        </FormControl>

        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small" id="form-select-label">
            ເລຶອກຟອມປະເມີນ
          </InputLabel>
          <Select
            label="ເລຶອກຟອມປະເມີນ"
            size="small"
            value={selectedForm}
            onChange={(e) => setSelectedForm(e.target.value)}
          >
            {evaluationFormList &&
              evaluationFormList.map((form) => (
                <MenuItem key={form.id} value={form}>
                  {form?.description}
                </MenuItem>
              ))}
          </Select>
          {formErrors.form && (
            <Typography color="error" variant="caption">
              {formErrors.form}
            </Typography>
          )}
        </FormControl>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={onCancel} sx={{ mr: 2 }} color="primary">
            ຍົກເລີກ
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            ຢືນຢັນ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AssignEvaluateFormToTeacher;

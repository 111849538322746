import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
} from "../../common/fetchAPI";
import ConfirmModal from "../../common/ConfirmDialog";
import AlertMessage from "../../common/AlertMessage";

const EntranceClassRoom = () => {
  const [placeList, setPlaceList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [classRoomList, setClassRoomList] = useState([]);
  const [selectedPlaceId, setSelectedPlaceId] = useState("");
  const [selectedBuildingId, setSelectedBuildingId] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [formRoom, setFormRoom] = useState({
    group: "",
    name: "",
    maxStudent: "",
    roomInfo: "",
    building: "",
  });
  const [formRoomValidate, setFormRoomValidate] = useState({});

  const isValid = () => {
    const error = {};
    if (!formRoom.name) error.name = "ກະລຸນາປ້ອນຂໍ້ມຸນ";
    if (!formRoom.maxStudent || isNaN(formRoom.maxStudent))
      error.maxStudent = "ກະລຸນາປ້ອນຂໍ້ມຸນ";
    if (!formRoom.building) error.building = "ກະລຸນາປ້ອນຂໍ້ມຸນ";
    if (!formRoom.group) error.group = "ກະລຸນາປ້ອນຂໍ້ມຸນ";

    setFormRoomValidate(error);
    return Object.keys(error).length === 0;
  };

  useEffect(() => {
    fetchPlaceList();
  }, []);

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  useEffect(() => {
    if (selectedPlaceId) {
      fetchBuildingList();
    }
  }, [selectedPlaceId]);

  useEffect(() => {
    if (selectedBuildingId) {
      fetchClassRoomList();
    }
  }, [selectedBuildingId]);

  const fetchPlaceList = async () => {
    const response = await fetchGet("entrance/place");
    if (response) setPlaceList(response);
  };

  const fetchBuildingList = async () => {
    const response = await fetchGet(
      `entrance/building?place=${selectedPlaceId}`
    );
    if (response) setBuildingList(response);
  };

  const fetchClassRoomList = async () => {
    const response = await fetchGet(
      `entrance/room?building=${selectedBuildingId}`
    );
    if (response) setClassRoomList(response);
  };

  const handleAddOrUpdateRoom = async () => {
    if (isValid()) {
      const apiCall = selectedRoomId ? fetchPut : fetchPost;
      const apiUrl = selectedRoomId
        ? `entrance/room/${selectedRoomId}`
        : "entrance/room";

      const response = await apiCall(apiUrl, formRoom);
      if (response) {
        setActionStatus({
          type: "success",
          message: selectedRoomId ? "ເເກ້ໄຂສຳເລັດ" : "ເພີ່ມສຳເລັດ.",
          open: true,
        });
        fetchClassRoomList();
        setShowAddModal(false);
      } else {
        setActionStatus({
          type: "error",
          message: "ຂໍອະໄພ ລະບົບມີບັນຫາ",
          open: true,
        });
      }
    }
  };

  const handleDeleteRoom = (id) => {
    setSelectedRoomId(id);
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    const response = await fetchDelete(`entrance/room/${selectedRoomId}`);
    if (response) {
      setActionStatus({
        type: "success",
        message: "ລົບຂໍ້ມຸນສຳເລັດ",
        open: true,
      });
      fetchClassRoomList();
    } else {
      setActionStatus({
        type: "error",
        message: "ຂໍອະໄພ ລະບົບມີບັນຫາ",
        open: true,
      });
    }
    setShowConfirmModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormRoom({ ...formRoom, [name]: value });
    setFormRoomValidate({ ...formRoomValidate, [name]: "" });
  };

  useEffect(() => {
    setClassRoomList([]);
    setFormRoom({ name: "", maxStudent: "", roomInfo: "", building: "" });
  }, [selectedBuildingId]);

  useEffect(() => {
    setClassRoomList([]);
    setBuildingList([]);
    setSelectedBuildingId("");
    setFormRoom({ name: "", maxStudent: "", roomInfo: "", building: "" });
  }, [selectedPlaceId]);

  return (
    <Box
      sx={{
        padding: 2,
        px: 30,
        minHeight: "80vh",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
        borderRadius: 2,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 3,
          mb: 2,
        }}
      >
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>ເລືອກສະຖານທີ</InputLabel>
          <Select
            label="ເລືອກສະຖານທີ"
            value={selectedPlaceId}
            onChange={(e) => setSelectedPlaceId(e.target.value)}
          >
            {placeList.map((place) => (
              <MenuItem key={place._id} value={place._id}>
                {place.place}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedPlaceId}>
          <InputLabel>ເລືອກຕຶກ</InputLabel>
          <Select
            label="ເລືອກຕຶກ"
            value={selectedBuildingId}
            onChange={(e) => setSelectedBuildingId(e.target.value)}
          >
            {buildingList.map((building) => (
              <MenuItem key={building._id} value={building._id}>
                {building.building}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => {
          setFormRoom({
            name: "",
            maxStudent: "",
            roomInfo: "",
            building: selectedBuildingId,
          });
          setSelectedRoomId(null);
          setShowAddModal(true);
        }}
        sx={{ mb: 2 }}
        disabled={!selectedBuildingId}
      >
        ເພີ່ມຫ້ອງເສັງ
      </Button>

      <Typography variant="h5" sx={{ mb: 2 }}>
        ລາຍການຫ້ອງເສັງ
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#edf2f4",
                  textAlign: "center",
                }}
              >
                ຊື່ຫ້ອງ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#edf2f4",
                  textAlign: "center",
                }}
              >
                ຮອງຮັບນັກສຶກສາສູງສຸດ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#edf2f4",
                  textAlign: "center",
                }}
              >
                ລາຍລະອຽດຫ້ອງ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#edf2f4",
                  textAlign: "center",
                }}
              >
                ຈັດການ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classRoomList.map((room) => (
              <TableRow key={room._id}>
                <TableCell>{room.name}</TableCell>
                <TableCell>{room.maxStudent}</TableCell>
                <TableCell>{room.roomInfo}</TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setFormRoom({
                        name: room.name,
                        maxStudent: room.maxStudent,
                        roomInfo: room.roomInfo,
                        building: room.building,
                      });
                      setSelectedRoomId(room._id);
                      setShowAddModal(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteRoom(room._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={showAddModal} onClose={() => setShowAddModal(false)}>
        <DialogTitle>{selectedRoomId ? "ແກ້ໄຂຫ້ອງ" : "ເພີ່ມຫ້ອງ"}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="ຕຶກ"
            disabled
            value={
              buildingList.find(
                (building) => building._id === selectedBuildingId
              )?.building || ""
            }
          />
          <TextField
            margin="dense"
            label="ຊື່ຫ້ອງ"
            name="name"
            value={formRoom.name}
            onChange={handleInputChange}
            fullWidth
            error={!!formRoomValidate.name}
            helperText={formRoomValidate.name}
          />
          <TextField
            margin="dense"
            label="ຮອງຮັບນັກສຶກສາສູງສຸດ"
            name="maxStudent"
            value={formRoom.maxStudent}
            onChange={handleInputChange}
            fullWidth
            error={!!formRoomValidate.maxStudent}
            helperText={formRoomValidate.maxStudent}
          />
          <TextField
            margin="dense"
            label="ລາຍລະອຽດຫ້ອງ"
            name="roomInfo"
            value={formRoom.roomInfo}
            onChange={handleInputChange}
            fullWidth
          />
          <FormControl fullWidth sx={{ mt: 2 }} disabled={!selectedPlaceId}>
            <InputLabel>ເລືອກຫມວດສາຍຮຽນ</InputLabel>
            <Select
              label="ເລືອກຫມວດສາຍຮຽນ"
              error={!!formRoomValidate?.group}
              name="group"
              value={formRoom.group}
              onChange={handleInputChange}
            >
              <MenuItem value={"A"}>A</MenuItem>
              <MenuItem value={"B"}>B</MenuItem>
              <MenuItem value={"C"}>C</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddModal(false)}>ຍົກເລີກ</Button>
          <Button onClick={handleAddOrUpdateRoom} variant="contained">
            ບັນທຶກ
          </Button>
        </DialogActions>
      </Dialog>
      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />
      <ConfirmModal
        open={showConfirmModal}
        message="ທ່ານຕ້ອງການລົບຫ້ອງເສັງແທ້ບໍ່?"
        title="ລົບຫ້ອງເສັງ"
        onSubmit={confirmDelete}
        onCancel={() => setShowConfirmModal(false)}
      />
    </Box>
  );
};

export default EntranceClassRoom;

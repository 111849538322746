import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  IconButton,
  Grid,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Chip,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { red } from "@mui/material/colors";
import ReactPlayer from "react-player/lazy";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import {
  fetchGet,
  fetchDelete,
  fetchPostFormData,
} from "../../../common/fetchAPI";
import ConfirmModal from "../../../common/ConfirmDialog";
import AlertMessage from "../../../common/AlertMessage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AddPostDialog from "./addPostDialog";
import LoadingDialog from "../../../common/LoadingDialog";
import CommentListDialog from "./commensDialog";
import LikeListDialog from "./likeListDialog";

const TeacherFeed = () => {
  const [likes, setLikes] = useState([]);
  const [data, setData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [postId, setPostId] = useState("");
  const [showAddPost, setShowAddPost] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [showLikeList, setShowLikeList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "ລົບສຳເລັດ",
    type: "success",
  });
  const [openContentView, setOpenContentView] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [showMoreDialog, setShowMoreDialog] = useState(false);
  const [selectedPostContents, setSelectedPostContents] = useState([]);

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus((predata) => ({
          ...predata,
          open: false,
        }));
      }, 2000);
    }
  }, [actionStatus.open]);

  const fetchPost = async () => {
    const user = localStorage.getItem("@userKey");
    const userObject = JSON.parse(user);
    const response = await fetchGet("/tpost/teacher/" + userObject.data?.id);
    if (response.success) {
      setData(
        response.posts.map((post) => ({
          ...post,
          tags: post.tags || [],
        }))
      );
    }
  };

  const addPost = async (data) => {
    setIsLoading(true);
    const response = await fetchPostFormData("/tpost", data);
    setIsLoading(false);
    if (response) {
      await fetchPost();
      setActionStatus({
        open: true,
        message: "ເພີ່ມສຳເລັດ",
        type: "success",
      });
    } else {
      setActionStatus({
        open: true,
        message: "ການເພີ່ມໂພສມີບັນຫາ",
        type: "error",
      });
    }
  };

  const deletePost = async () => {
    const response = await fetchDelete("/tpost/" + postId);
    if (response) {
      setShowConfirm(false);
      await fetchPost();
      setActionStatus({
        open: true,
        message: "ລົບສຳເລັດ",
        type: "success",
      });
    } else {
      setActionStatus({
        open: true,
        message: "ການລົບມີບັນຫາ",
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  const handleContentClick = (contentUrl, contentType) => {
    setSelectedContent(contentUrl);
    setIsVideo(contentType.startsWith("video/"));
    setOpenContentView(true);
  };

  const handleCloseContentView = () => {
    setOpenContentView(false);
  };

  const handleShowMoreClick = (contents) => {
    setSelectedPostContents(contents);
    setShowMoreDialog(true);
  };

  const handleCloseShowMoreDialog = () => {
    setShowMoreDialog(false);
  };

  const renderContent = (content) => {
    const isImage = content.contentType.startsWith("image/");
    const isVideo = content.contentType.startsWith("video/");

    if (isImage) {
      const reducedImageUrl = `${content.fileUrl}?w=300&h=300&fit=crop`;
      return (
        <Box
          key={content._id}
          onClick={() =>
            handleContentClick(content.fileUrl, content.contentType)
          }
          sx={{
            cursor: "pointer",
            width: "100%",
            maxHeight: "150px",
            overflow: "hidden",
          }}
        >
          <LazyLoadImage
            effect="blur"
            src={reducedImageUrl}
            alt="content"
            style={{ width: "100%", borderRadius: 8 }}
          />
        </Box>
      );
    } else if (isVideo) {
      return (
        <Box
          key={content._id}
          sx={{
            position: "relative",
            cursor: "pointer",
          }}
          onClick={() =>
            handleContentClick(content.fileUrl, content.contentType)
          }
        >
          <ReactPlayer
            url={content.fileUrl}
            controls
            width="100%"
            height="100%"
            style={{ borderRadius: 8 }}
          />
        </Box>
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        paddingTop: "30px",
        backgroundColor: "#f8f9fa",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 800,
      }}
    >
      <LikeListDialog
        likes={likes}
        open={showLikeList}
        onClose={() => setShowLikeList(false)}
      />
      <LoadingDialog open={isLoading} message={"ກຳລັງດຳເນີນການ..."} />
      <CommentListDialog
        postId={postId}
        onClose={() => setShowComment(false)}
        open={showComment}
      />
      <AddPostDialog
        onSubmit={async (data) => {
          await addPost(data);
        }}
        open={showAddPost}
        onClose={() => setShowAddPost(false)}
      />
      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />
      <ConfirmModal
        open={showConfirm}
        message={"ທ່ານຕ້ອງການລົບແທ້ບໍ່?"}
        onCancel={() => setShowConfirm(false)}
        onSubmit={deletePost}
        title={"ຢືນຢັນການລົບ"}
      />
      <Card
        sx={{
          marginBottom: 2,
          width: "100%",
          height: "60px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          paddingLeft: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowAddPost(true)}
          startIcon={<AddCircleIcon />}
        >
          ເພີ່ມໃຫມ່
        </Button>
      </Card>

      {data.length === 0 && (
        <Box
          sx={{
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <InfoIcon color="action" sx={{ fontSize: 48, marginBottom: 2 }} />
          <Typography variant="h6" color="textSecondary">
            ຍັງບໍ່ມີຂໍ້ມູນ
          </Typography>
        </Box>
      )}

      {data.length > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {data.map((item, index) => (
            <Box key={item._id} sx={{ width: "100%", marginBottom: "20px" }}>
              <Card
                sx={{
                  width: "100%",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      sx={{ bgcolor: red[500] }}
                      src={item.teacher?.profilePicture}
                      alt={`${item.teacher?.teacher?.firstname} ${item.teacher?.teacher?.lastname}`}
                    />
                  }
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setShowConfirm(true);
                        setPostId(item._id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  title={`${item.teacher?.teacher?.firstname} ${item.teacher?.teacher?.lastname}`}
                  subheader={new Date(item.createdAt).toLocaleString()}
                />
                <Divider sx={{ backgroundColor: "#d3d3d3" }} />
                <CardContent>
                  {item.tags && item.tags.length > 0 && (
                    <Box sx={{ marginBottom: 2 }}>
                      {item.tags.map((tag, idx) => (
                        <Chip
                          key={idx}
                          label={tag}
                          variant="outlined"
                          sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                        />
                      ))}
                    </Box>
                  )}
                  <Typography variant="body1">{item.description}</Typography>
                  <Box mt={2}>
                    {item.contents.length > 0 && (
                      <Grid container spacing={2}>
                        {item.contents.slice(0, 3).map((content) => (
                          <Grid item xs={12} sm={6} md={4} key={content._id}>
                            {renderContent(content)}
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Box>
                  {item.contents.length > 3 && (
                    <Button
                      variant="text"
                      onClick={() => handleShowMoreClick(item.contents)}
                      startIcon={<InfoIcon />}
                    >
                      ເບີ່ງທັງຫມົດ
                    </Button>
                  )}
                </CardContent>
                <Divider sx={{ backgroundColor: "#d3d3d3" }} />
                <CardActions>
                  <IconButton
                    onClick={() => {
                      setShowLikeList(true);
                      setLikes(item?.likes);
                    }}
                    aria-label="like"
                  >
                    <FavoriteIcon />
                  </IconButton>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    {item.likes.length}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setPostId(item._id);
                      setShowComment(true);
                    }}
                    aria-label="comment"
                  >
                    <CommentIcon />
                  </IconButton>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    {item.commentCount}
                  </Typography>
                </CardActions>
              </Card>
            </Box>
          ))}
        </Box>
      )}

      <Dialog
        open={openContentView}
        onClose={handleCloseContentView}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {isVideo ? (
            <ReactPlayer
              url={selectedContent}
              controls
              playing
              width="100%"
              height="100%"
            />
          ) : (
            <img
              src={selectedContent}
              alt="Full view"
              style={{ width: "100%" }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={showMoreDialog}
        onClose={handleCloseShowMoreDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {selectedPostContents.map((content, idx) => {
            const isImage = content.contentType.startsWith("image/");
            const isVideo = content.contentType.startsWith("video/");
            const isFile = !isImage && !isVideo;

            return (
              <Box key={idx} mb={2}>
                {isImage || isVideo ? (
                  <Box
                    onClick={() =>
                      handleContentClick(content.fileUrl, content.contentType)
                    }
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      borderRadius: 4,
                      overflow: "hidden",
                    }}
                  >
                    {isImage ? (
                      <LazyLoadImage
                        effect="blur"
                        src={content.fileUrl}
                        alt="content"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <ReactPlayer
                        url={content.fileUrl}
                        controls
                        width="100%"
                        height="100%"
                      />
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="body2">
                      {content.fileName || "File"}
                    </Typography>
                    <Button
                      variant="outlined"
                      href={content.fileUrl}
                      download
                      startIcon={<DownloadIcon />}
                      sx={{ marginTop: 1 }}
                    >
                      ດາວໂຫລດ
                    </Button>
                  </Box>
                )}
              </Box>
            );
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TeacherFeed;

import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { Breadcrumb } from "react-bootstrap";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Grid,
} from "@mui/material";
import { CustomContainer, Title } from "../../common";
import Routers from "../../consts/router";
import Const, { USER_KEY } from "../../consts";
import CourseDetailFilter from "./common/CourseDetailFilter";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import axios from "axios";
import { translateSubjectGroup } from "../../common/super";
import { useSelector } from "react-redux";

const CourseDetail = () => {
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const { history, location } = useReactRouter();
  const state = location.state;
  const [subjects, setSubjects] = useState([]);
  const [formData, setFormData] = useState({
    subjectGroup: "",
    year: "",
    semester: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const apiUrl = `${NEW_API_URL}subject/course?limit=${rowsPerPage}
    &faculty=${state.faculty}&department=${state.department}
    &major=${state._id}&page=${
      page + 1
    }&assessmentYear=${accessmentRedux}&subjectGroup=${
      formData.subjectGroup
    }&year=${formData.year}&semester=${formData.semester}`;

    axios
      .get(apiUrl, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSubjects(response.data || []);
      })
      .catch((error) => console.error(error));
  }, [formData, state, rowsPerPage, page, accessmentRedux]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href={Routers.COURSE_LIST + Routers.PAGE_GINATION}
          onClick={() =>
            history.push(Routers.COURSE_LIST + Routers.PAGE_GINATION)
          }
        >
          ລາຍການຫລັກສູດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ໜ້າສະແດງຫລັກສູດ</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: "flex", marginLeft: 10 }}>
        <div
          className="menuItemTap active-menuTap"
          // onClick={() =>
          //   history.push(
          //     Routers.PRERE_STUDENT_LIST_ALL + Routers.PAGE_GINATION
          //   )
          // }
        >
          ໜ້າສະແດງຫລັກສູດ
        </div>

        <div
          className="menuItemTap"
          onClick={() => history.push(Routers.COURSE_ADD, state)}
        >
          ໜ້າສ້າງຫລັກສູດ
        </div>
      </div>
      <CustomContainer style={{ marginTop: 0 }}>
        <Title text={`ຫຼັກສູດ ${state.name}`} />
        <CourseDetailFilter
          formData={formData}
          setFormData={setFormData}
          courseData={subjects?.data}
        />
        <div className="d-flex align-items-center">
          <Title text={"ຫຼັກສູດທັງໝົດ"} />
          <span style={{ marginTop: 8, marginBottom: 20, marginLeft: 10 }}>
            {" "}
            ({subjects?.count} ລາຍການ)
          </span>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow color="info">
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold", width: 70 }}
                >
                  ລຳດັບ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ລະຫັດ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຊື່ວິຊາຮຽນ {/* (ພາສາລາວ)*/}
                </TableCell>
                {/* <TableCell align="center" style={{ color: "white", fontWeight: "bold", whiteSpace: "wrap" }}>ຊື່ສາຂາວິຊາ (ພາສາອັງກິດ)</TableCell> */}
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ໜ່ວຍກິດ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ໝວດວິຊາ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ປີຮຽນ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ພາກຮຽນ
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {subjects?.data?.map((subject, index) => (
                <TableRow key={subject._id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{subject?.subject?.code}</TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {subject?.subject?.title}
                  </TableCell>
                  {/* <TableCell align="center">{subject?.faculty?.title}</TableCell> */}
                  <TableCell align="center">
                    {subject?.subject?.credit}
                  </TableCell>
                  <TableCell align="center">
                    {translateSubjectGroup(subject?.subjectGroup)}
                  </TableCell>
                  <TableCell align="center">{subject?.year}</TableCell>
                  <TableCell align="center">{subject?.semester}</TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TablePagination
              rowsPerPageOptions={[
                10,
                20,
                50,
                { label: "All", value: subjects?.count },
              ]}
              colSpan={7} // Adjusted colSpan to include the Actions column
              count={subjects?.count}
              rowsPerPage={rowsPerPage}
              page={page}
              elevation={0}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </CustomContainer>
    </div>
  );
};

export default CourseDetail;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  CircularProgress,
  Fade,
  Tabs,
  Tab,
  Modal,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { convertGender } from "./attendanceReport";

import { CheckCircle, Cancel } from "@mui/icons-material";
import AlertMessage from "../../../../common/AlertMessage";
import { fetchPost } from "../../../../common/fetchAPI";

const UnAnswerReport = ({ unAnswerReport }) => {
  const [showModal, setShowModal] = useState(false);
  const [student, setStudent] = useState("");
  const [sugggestText, setSuggestText] = useState("");

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus((preData) => ({
          ...preData,
          open: false,
        }));
      }, 3000);
    }
  }, [actionStatus]);

  // State for managing the selected question
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  // State for managing the modal visibility
  const [openModal, setOpenModal] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  // Display a loading spinner if the data is not available yet
  if (!unAnswerReport) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Destructure report data for easy access
  const { totalStudents, totalQuestions, averageAnswers, questionDetails } =
    unAnswerReport;

  // Open the modal and set the selected question when a row is clicked
  const handleRowClick = (questionDetail) => {
    setSelectedQuestion(questionDetail);
    setOpenModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box sx={{ p: 4, backgroundColor: "#eef2f5" }}>
      {/* Report title */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", mb: 3, color: "#0d47a1" }}
      >
        ລາຍງານຄຳຖາມທີ່ຍັງບໍ່ຖືກຕອບ
      </Typography>

      {/* Summary section */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              textAlign: "center",
              borderRadius: 2,
              backgroundColor: "#f8f9fa",
            }}
          >
            <Typography variant="h7" color="textSecondary">
              ຈຳນວນນັກສຶກສາທັງຫມົດ
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#0d47a1" }}
            >
              {totalStudents}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              textAlign: "center",
              borderRadius: 2,
              backgroundColor: "#f8f9fa",
            }}
          >
            <Typography variant="h7" color="textSecondary">
              ຄຳຖາມທັງຫມົດ
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#0d47a1" }}
            >
              {totalQuestions}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={1}
            sx={{
              p: 3,
              textAlign: "center",
              borderRadius: 2,
              backgroundColor: "#f8f9fa",
            }}
          >
            <Typography variant="h7" color="textSecondary">
              ສະເລ່ຍຄຳຕອບ
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#0d47a1" }}
            >
              {averageAnswers.toFixed(2) + "%"}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Table of question details */}
      <Box mt={4}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#0d47a1" }}
        >
          ລາຍລະອຽດຄຳຖາມ
        </Typography>
        <TableContainer
          component={Paper}
          elevation={1}
          sx={{ borderRadius: 2, maxHeight: 400, overflow: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#0d47a1",
                    backgroundColor: "#e3f2fd",
                  }}
                >
                  ຄຳຖາມ
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#0d47a1",
                    backgroundColor: "#e3f2fd",
                  }}
                >
                  ຈຳນວນທີ່ຕອບ
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#0d47a1",
                    backgroundColor: "#e3f2fd",
                  }}
                >
                  ຈຳນວນທີ່ບໍ່ຕອບ
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionDetails.map((questionDetail) => (
                <TableRow
                  key={questionDetail.question}
                  hover
                  onClick={() => handleRowClick(questionDetail)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell>{questionDetail.question}</TableCell>
                  <TableCell>{questionDetail.answeredCount}</TableCell>
                  <TableCell>{questionDetail.unansweredCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Modal for question details */}
      <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              maxHeight: "80vh",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflowY: "auto",
            }}
          >
            {selectedQuestion && (
              <Box>
                <Tabs
                  value={tabValue}
                  onChange={(e, newValue) => setTabValue(newValue)}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  sx={{ mb: 2 }}
                >
                  <Tab
                    label={`ນັກສຶກສາທີຕອບແລ້ວ (${selectedQuestion.answeredStudents.length})`}
                  />
                  <Tab
                    label={`ນັກສຶກສາທີຍັງບໍ່ຕອບ (${selectedQuestion.unansweredStudents.length})`}
                  />
                </Tabs>

                {/* Tab content */}
                {tabValue === 0 && (
                  <Box
                    sx={{
                      minHeight: "60vh",
                      overflow: "auto",
                    }}
                  >
                    {selectedQuestion.answeredStudents.map((student) => (
                      <Box
                        key={student.studentId}
                        sx={{
                          p: 2,
                          display: "flex",
                          alignItems: "center", // Align button and text vertically
                          backgroundColor: "#fff",
                          borderBottom: "1px solid #ccc",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography>
                            <strong>ຊື່:</strong>{" "}
                            {convertGender(student?.gender)} {student.firstName}{" "}
                            {student.lastName}
                          </Typography>
                          <Typography>
                            <strong>ລະຫັດນັກສຶກສາ:</strong>{" "}
                            {student.studentNumber}
                          </Typography>
                        </Box>
                        <Button
                          onClick={async () => {
                            setShowModal(true);
                            setStudent(student);
                          }}
                          variant="contained"
                          sx={{
                            backgroundColor: "#3498db", // Modern blue color
                            color: "#fff",
                            padding: "8px 16px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            borderRadius: "8px", // Slightly rounded corners
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", // Subtle modern shadow
                            textTransform: "none", // Avoid all caps
                            transition:
                              "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out", // Smooth animations
                            "&:hover": {
                              backgroundColor: "#2980b9", // Darker blue on hover
                              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", // Stronger shadow on hover
                              transform: "scale(1.05)", // Slight zoom effect
                            },
                            mr: 2, // Adds space between button and text
                          }}
                        >
                          ໃຫ້ຄຳເເນະນຳ
                        </Button>
                      </Box>
                    ))}
                  </Box>
                )}
                {tabValue === 1 && (
                  <Box
                    sx={{
                      minHeight: "60vh",
                      overflow: "auto",
                    }}
                  >
                    {selectedQuestion.unansweredStudents.map((student) => (
                      <Box
                        key={student.studentId}
                        sx={{
                          p: 2,
                          display: "flex",
                          alignItems: "center", // Align button and text vertically
                          borderRadius: 2,
                          backgroundColor: "#ffebee",
                          justifyContent: "space-between",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          mb: 1,
                        }}
                      >
                        <Box>
                          <Typography>
                            <strong>ຊື່:</strong>{" "}
                            {convertGender(student?.gender)} {student.firstName}{" "}
                            {student.lastName}
                          </Typography>
                          <Typography>
                            <strong>ລະຫັດນັກສຶກສາ:</strong>{" "}
                            {student.studentNumber}
                          </Typography>
                        </Box>
                        <Button
                          onClick={async () => {
                            setShowModal(true);
                            setStudent(student);
                          }}
                          variant="contained"
                          sx={{
                            backgroundColor: "#3498db", // Modern blue color
                            color: "#fff",
                            padding: "8px 16px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            borderRadius: "8px", // Slightly rounded corners
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", // Subtle modern shadow
                            textTransform: "none", // Avoid all caps
                            transition:
                              "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out", // Smooth animations
                            "&:hover": {
                              backgroundColor: "#2980b9", // Darker blue on hover
                              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", // Stronger shadow on hover
                              transform: "scale(1.05)", // Slight zoom effect
                            },
                            mr: 2, // Adds space between button and text
                          }}
                        >
                          ໃຫ້ຄຳເເນະນຳ
                        </Button>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>

      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modern-modal-title"
        aria-describedby="modern-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "600px", // Max width of the modal
            maxHeight: "500px", // Max height of the modal
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)", // Modern shadow
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            id="modern-modal-title"
            variant="h6"
            component="h2"
            fontWeight="bold"
            sx={{ mb: 2, textAlign: "center" }}
          >
            {"ສົ່ງຄຳເເນນນຳໃຫ້ນັກສຶກສາ " +
              convertGender(student?.gender) +
              " " +
              student?.firstName +
              " " +
              student?.lastName}
          </Typography>
          <TextField
            label="ຄຳເເນະນຳ"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={sugggestText}
            onChange={(e) => setSuggestText(e.target.value)}
            sx={{
              mb: 3,
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px", // Rounded corners
                "&:hover fieldset": {
                  borderColor: "#e67e22", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e67e22", // Border color when focused
                },
              },
            }}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: "auto" }}
          >
            <Button
              onClick={() => setShowModal(false)}
              variant="outlined"
              sx={{
                color: "#e74c3c",
                borderColor: "#e74c3c",
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#fdecea", // Light red for hover
                  borderColor: "#c0392b",
                },
              }}
            >
              ຍົກເລີກ
            </Button>
            <Button
              onClick={async () => {
                const response = await fetchPost(
                  "notification/send?userId=" + student?.studentNumber,
                  {
                    title: "ຄຳເເນະນຳຈາກອາຈານ",
                    path: "",
                    body: sugggestText,
                  }
                );
                if (response) {
                  setActionStatus({
                    open: true,
                    type: "success",
                    message: "ສົ່ງຄຳເເນະນຳສຳເລັດ",
                  });
                } else {
                  setActionStatus({
                    type: "error",
                    message: "ລະບົບມີບັນຫາ!",
                    open: true,
                  });
                }
                setShowModal(false);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#3498db",
                color: "#fff",
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#2980b9",
                },
              }}
            >
              ສົ່ງ
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default UnAnswerReport;

import { createSlice } from "@reduxjs/toolkit";

export const lessonSlice = createSlice({
  name: "lesson",
  initialState: {
    value: "",
  },
  reducers: {
    setLesson: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLesson } = lessonSlice.actions;

export default lessonSlice.reducer;

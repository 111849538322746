import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import Evaluationlist from "./evaluationList";
import EvaluationTeacherlist from "./evalutionTeacherList";
import EvaluationDisplay from "./evaluationReportList";

const tabPanelStyle = {
  padding: 3,
  backgroundColor: "#fff", // Set the background color of the panel to white
  borderRadius: "8px",
  width: "100%", // Ensure the panel takes the full width of the screen
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={tabPanelStyle}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const EvaluateTab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable" // Allow scrolling if the tabs exceed the screen width
        scrollButtons="auto" // Show scroll buttons if needed
        textColor="primary"
        indicatorColor="primary"
        sx={{
          display: "flex",
          justifyContent: "center",
          ".MuiTab-root": {
            textTransform: "none",
            fontWeight: "bold",
            fontSize: "1rem",
            minWidth: "200px", // Set each tab item width to 200px
            backgroundColor: "#fff", // Set the tab background color to white
            margin: "0", // Remove the margin between tabs
          },
          ".MuiTabs-indicator": {
            height: "4px",
            borderRadius: "4px",
          },
        }}
      >
        <Tab label="ຟອມປະເມີນ" {...a11yProps(0)} />
        <Tab label="ຈັດການປະເມີນອາຈານ" {...a11yProps(1)} />
        <Tab label="ລາຍງານ" {...a11yProps(2)} />
      </Tabs>

      <Box sx={{ width: "100%", mt: 1 }}>
        <TabPanel value={value} index={0}>
          <Evaluationlist />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EvaluationTeacherlist />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EvaluationDisplay />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default EvaluateTab;

import React from "react";
import { Box, Typography, Icon } from "@mui/material";
import { HourglassEmpty } from "@mui/icons-material";

const EmptyCmomponent = ({ height }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: height ? height : "80vh", // Full-screen height
        textAlign: "center",
        color: "#9e9e9e", // Professional light gray text color
      }}
    >
      {/* Empty Icon */}
      <Icon sx={{ fontSize: 100, mb: 2, color: "#bdbdbd" }}>
        <HourglassEmpty />
      </Icon>

      {/* Empty Text */}
      <Typography variant="h6" sx={{ fontWeight: 500 }}>
        ບໍ່ມີຂໍ້ມຸນ
      </Typography>
    </Box>
  );
};

export default EmptyCmomponent;

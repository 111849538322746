import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Box,
  Grid,
  Paper,
} from "@mui/material";
import YouTube from "react-youtube";
import { useSelector } from "react-redux";
import { fetchPostFormData, fetchPutFormData } from "../../../common/fetchAPI";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const AddChapterModal = ({ open, onClose, onRefresh, chapterData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoId, setVideoId] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const lessonId = useSelector((state) => state.lesson);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("chapter", e.target.chapter.value);
    formData.append("title", e.target.title.value);
    formData.append("description", e.target.description.value);
    formData.append("videoUrl", videoUrl);
    if (file) {
      formData.append("file", file);
    }
    formData.append("tcourseLesson", lessonId.value);

    try {
      if (chapterData) {
        await fetchPutFormData("tchapter/" + chapterData._id, formData);
      } else {
        await fetchPostFormData("tchapter", formData);
      }
      onRefresh();
      onClose();
    } catch (error) {
      console.error("Error adding chapter:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (chapterData) {
      setVideoUrl(chapterData.video || "");
      setVideoId(
        chapterData.video ? extractYouTubeID(chapterData.video) : null
      );
    }
  }, [chapterData]);

  const handleVideoUrlChange = (event) => {
    const url = event.target.value;
    setVideoUrl(url);
    const id = extractYouTubeID(url);
    setVideoId(id);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const extractYouTubeID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
        ເພິ້ມຫົວຂໍ້ບົດຮຽນໃຫມ່
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ລຳດັບຫົວຂໍ້"
                name="chapter"
                type="number"
                defaultValue={chapterData ? chapterData.chapter : ""}
                variant="outlined"
                inputProps={{ min: 1 }} // Set minimum value to 1
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ຊື່ຫົວຂໍ້"
                name="title"
                defaultValue={chapterData ? chapterData.title : ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="ລາຍລະອຽດ"
                name="description"
                defaultValue={chapterData ? chapterData.description : ""}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            {videoId && (
              <Box mt={2}>
                <Typography variant="subtitle1" gutterBottom>
                  Preview Video
                </Typography>
                <YouTube videoId={videoId} opts={{ width: "100%" }} />
              </Box>
            )}
            <TextField
              fullWidth
              margin="normal"
              label="YouTube Video URL"
              value={videoUrl}
              onChange={handleVideoUrlChange}
              helperText={
                videoId
                  ? "Valid YouTube link"
                  : "Please enter a valid YouTube link"
              }
              variant="outlined"
            />
          </Box>

          <Box mt={2} component={Paper} elevation={1} p={2} textAlign="center">
            <input
              type="file"
              onChange={handleFileChange}
              accept="*/*"
              style={{ display: "none" }}
              id="file-input"
            />
            <label htmlFor="file-input">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
                sx={{ borderRadius: "20px" }}
              >
                Upload File
              </Button>
            </label>
            {fileName && (
              <Typography variant="subtitle1" mt={2}>
                Selected file: {fileName}
              </Typography>
            )}
          </Box>

          <DialogActions sx={{ justifyContent: "center", mt: 3 }}>
            <Button
              onClick={onClose}
              color="secondary"
              disabled={isSubmitting}
              sx={{ borderRadius: "20px" }}
            >
              ຍົກເລີກ
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              sx={{ borderRadius: "20px" }}
            >
              {isSubmitting ? <CircularProgress size={24} /> : "ບັນທຶກ"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddChapterModal;

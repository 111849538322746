import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";

const ErrorDialog = ({ open, onClose, message, onRefresh }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="error-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        id="error-dialog-title"
        sx={{ textAlign: "center", position: "relative" }}
      >
        <Typography
          variant="h6"
          color="error"
          component="span"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ErrorIcon sx={{ verticalAlign: "middle", mr: 1 }} />
          ເກີດຂໍ້ຜິດພາດ
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
          {message}
        </Typography>
        <Button variant="contained" color="primary" onClick={onRefresh}>
          ລອງໃຫມ່
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
